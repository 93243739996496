import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import { SimpleFormItem } from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
import { ContainerBox, PageHeader } from 'components/Page';
import { ExistsOnlyForAdmin } from 'pages/NotFound';
import UserCreateS from 'pages/Organization/Users/UserCreateS';

const UserCreate = inject('org')(
  observer((props) => {
    const [form] = useState(UserCreateS.create({}));

    const navigate = useNavigate();

    useEffect(() => {
      form.linkUsersStore(props.org.Users);
      form.linkNavigator(navigate);
    }, []);

    return (
      <ContainerBox>
        <PageHeader to="/organization/users" title="Users" subTitle="Create" documentTitle="Create user" />
        <SidedPanel>
          <LeftPanel>
            <form
              className="form-box"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input key="firstName" store={form.firstName} />
              <Input key="lastName" store={form.lastName} />
              <Input key="email" store={form.email} />
              <Input key="password" store={form.password} />
              <Input key="role" store={form.role} />
              <div className="message-placeholder">{form.errors}</div>
              <div className="form-box-line">
                <SimpleFormItem key="invite">
                  <button
                    type="button"
                    className="btn-primary btn-block"
                    onClick={form.createNewUser}
                    disabled={!form.filled || form.creating}
                  >
                    Create
                  </button>
                </SimpleFormItem>
                <SimpleFormItem key="cancel">
                  <Link className="btn btn-default btn-block" to="/organization/users">
                    Cancel
                  </Link>
                </SimpleFormItem>
              </div>
            </form>
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);

export default () => (
  <ExistsOnlyForAdmin>
    <UserCreate />
  </ExistsOnlyForAdmin>
);
