import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { HOST_APP_TYPE } from 'Constants';
import { InstanceLink } from 'components/Nav';
import { Table } from 'components/table/Table';
import { ColumnCustom, ColumnInventoryRecordStatus, ColumnLabels, ColumnModel, ColumnRecordLink, ColumnTimestamp, loadRecodsWithStatusStats, VerTableStore, } from 'components/table/TableS';
var HostsManagersHostsTab = inject('store', 'instance')(observer(function (props) {
    var query = "inherits('std::host/Host:1') AND std::types/Root:1.app == '".concat(props.record.id, "'");
    var store = useState(VerTableStore.create({
        paginationDefaultRowsPerPage: 15,
        paginationRowsPerPageOptions: [15, 25, 50, 100],
        selectable: false,
        query: query,
        columns: [
            ColumnModel.create({}),
            ColumnInventoryRecordStatus.create({ name: 'Status', opts: { width: '100px' } }),
            ColumnRecordLink.create({
                name: 'Name',
                sortKey: 'std::types/Inventory:1.displayName',
            }),
            ColumnCustom.create({ name: 'Access' }).setRender(function (row) {
                var host = row.data.get('std::host/Host:1');
                return "".concat(host.get('accessUser'), "@").concat(host.get('accessIP'));
            }),
            ColumnLabels.create({ name: 'Labels', key: 'std::types/Root:1.labels' }),
            ColumnTimestamp.create({ name: 'Updated', key: 'std::types/Versionable:1.updatedAt' }),
        ],
    }))[0];
    useEffect(function () {
        loadRecodsWithStatusStats(store);
        store.setUp({ instance: props.instance, transport: props.store.TransportLayer });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "title-container" },
            React.createElement("div", { className: "title" },
                React.createElement("span", null)),
            React.createElement(InstanceLink, { to: "/hosts/register?applicationID=".concat(props.record.id), className: "btn btn-primary" }, "Register new host")),
        React.createElement(Table, { store: store })));
}));
// @ts-ignore: eslint does not like assigning additional items to elements
HostsManagersHostsTab.suites = function (record) { return record.app_1 && record.app_1.applicationType === HOST_APP_TYPE; };
export default HostsManagersHostsTab;
