import { getParentOfType, types } from 'mobx-state-tree';
import moment from 'moment';
import Store from 'stores/Store';
export var SCHEMAS = Object.fromEntries(['definition', 'spec', 'jsonschema', 'jsonschema:create', 'jsonschema:update', 'jsonschema:delete'].map(function (k) { return [k, k]; }));
var ModelSchema = types
    .model('ModelSchema', {
    name: types.string,
    obj: types.maybeNull(types.frozen()),
    loaded: false,
    loading: false,
})
    .views(function (self) { return ({
    get urlSchemaPart() {
        return self.name.replace(':', '?action=');
    },
    get modelFullName() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getParentOfType(self, Model).fullName;
    },
}); })
    .actions(function (self) { return ({
    finishLoading: function () {
        self.loading = false;
    },
    setSchema: function (schema) {
        self.loaded = true;
        self.obj = schema;
    },
    fetch: function () {
        var _this = this;
        self.loading = true;
        Store.TransportLayer.get({
            url: "/i/api/v1/models/".concat(self.modelFullName, "/").concat(self.urlSchemaPart),
            onSuccess: function (response, response_data) {
                _this.setSchema(response_data.data);
            },
            onFinish: this.finishLoading,
        });
    },
}); });
var Model = types
    .model('Model', {
    organization: types.string,
    group: types.string,
    name: types.string,
    version: types.integer,
    // a container for definition, spec and different jsonschemas
    _schemas: types.optional(types.map(ModelSchema), {}),
})
    .actions(function (self) { return ({
    getSchema: function (name) {
        var schema = self._schemas.get(name);
        if (schema === undefined) {
            schema = ModelSchema.create({ name: name });
            self._schemas.set(name, schema);
        }
        return schema;
    },
}); })
    .views(function (self) { return ({
    get fullName() {
        return "".concat(self.organization, "::").concat(self.group, "/").concat(self.name, ":").concat(self.version);
    },
    get pictureUrl() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getParentOfType(self, Models).getPicture(this.fullName);
    },
    get definition() {
        return self.getSchema(SCHEMAS.definition);
    },
    get inherits() {
        return this.definition.obj.inherits;
    },
    get parts() {
        return this.definition.obj.parts;
    },
    get mCreatedAt() {
        return moment(this.definition.obj.createdAt);
    },
}); });
var Models = types
    .model('Models', {
    items: types.array(Model),
    loaded: false,
    loading: false,
})
    .views(function (self) { return ({
    getByIdentifier: function (identifier) {
        return self.items.find(function (m) { return m.fullName === identifier; });
    },
    getPicture: function (fullName) {
        var token = Store.instance.token;
        return "/i/api/v1/models/".concat(fullName, "/picture.svg?token=").concat(token);
    },
}); })
    .actions(function (self) { return ({
    pushItems: function (data) {
        self.loading = false;
        self.items = data.map(function (definition) {
            var model = Model.create({
                organization: definition.organization,
                group: definition.group,
                name: definition.name,
                version: definition.version,
            });
            model.definition.setSchema(definition);
            return model;
        });
        self.loaded = true;
    },
    fetch: function () {
        var _this = this;
        self.loading = true;
        Store.TransportLayer.get({
            url: '/i/api/v1/models/',
            onSuccess: function (response, response_data) {
                _this.pushItems(response_data.data);
            },
        });
    },
}); });
export default Models;
