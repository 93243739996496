import { types } from 'mobx-state-tree';
// @ts-ignore: untyped lib
import pathValidatorFromLib from 'valid-path';
import InputStore, { ErrorStore, NoDisplayError } from 'components/forms/Input/InputS';
var pathValidator = function (value) {
    if (!value.startsWith('/')) {
        return 'Path should start from /';
    }
    var res = pathValidatorFromLib(value, {
        // we do not want to accept Drive letters
        allowDriveLetter: false,
        // glob patterns look like 'a/*/*.js'. we do not need this
        allowGlobPatterns: false,
        // we do not need such restriction
        allowForbiddenWindowsNames: true,
        // forbidden chars are: /, \, <, >, :, ", *, ?, |
        // note: yes, typo is done intentionally in correlation to docs :)
        allowFobiddenWindowsChars: false,
        // forbidden chars are: \0 (NULL byte), /
        allowForbiddenUnixChars: false,
    });
    return res.valid ? undefined : res.error;
};
var fileNameValidator = function (value) { return (value.endsWith('/') ? 'File name should not be empty' : undefined); };
export default InputStore.named('FilePathInputStore')
    .props({
    // We have a format validator, so let's display error by default
    error: types.optional(types.union(ErrorStore, NoDisplayError), function () { return ErrorStore.create({}); }),
})
    .actions(function (self) { return ({
    afterCreate: function () {
        self.addInputValidator(pathValidator);
        self.addInputValidator(fileNameValidator);
        if (self.value) {
            self.validate();
        }
    },
}); });
