import { types } from 'mobx-state-tree';
import isUUID from 'validator/lib/isUUID';
import { InputStore, NoDisplayError } from 'components/forms/Input';
export default types
    .model('GlobalSearch', {
    defaultQuery: "inherits('std::types/Versionable:1')",
    input: types.optional(InputStore, function () {
        return InputStore.create({
            formItemClassName: 'search-button header-search',
            placeholder: 'Search all entries',
            releaseFocusOnEnterPress: true,
            error: NoDisplayError.create({}),
        });
    }),
    hideDeleted: true,
    showTooltip: false,
})
    .actions(function (self) { return ({
    onFocus: function () {
        self.showTooltip = true;
    },
    onFocusOut: function () {
        self.showTooltip = false;
    },
    toggleHideDeleted: function () {
        self.hideDeleted = !self.hideDeleted;
    },
    setValue: function (value) {
        self.input.setValue(value);
    },
    afterCreate: function () {
        self.input.registerOnFocusHandler(this.onFocus);
        self.input.registerOnFocusOutHandler(this.onFocusOut);
    },
}); })
    .views(function (self) { return ({
    get value() {
        return self.input.value || self.defaultQuery;
    },
    get enteredValueIsJustAValue() {
        return (self.input.value !== null &&
            !["'", 'AND', 'and', 'IN', 'CONTAINS', 'IsSet', 'IsNotSet', '<', '>', '='].some(function (v) {
                return self.input.value.includes(v);
            }));
    },
    get query() {
        var queryParts = [];
        if (self.hideDeleted) {
            queryParts.push("isNotSet('std::types/Versionable:1.deletedAt')");
        }
        if (this.enteredValueIsJustAValue) {
            if (isUUID(this.value)) {
                queryParts.push("std::types/Root:1.id == '".concat(this.value, "'"));
            }
            else {
                queryParts.push("search('".concat(this.value, "')"));
            }
        }
        else {
            queryParts.push(this.value);
        }
        return queryParts.join(' AND ');
    },
}); });
