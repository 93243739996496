import React from 'react';
import { cast, types } from 'mobx-state-tree';
import { UUID4 } from 'lib/uuid-utils';
var Option = types.model('DropDownOption', {
    value: types.identifier,
    title: types.maybeNull(types.union(types.string, types.number)),
});
var DropdownStore = types
    .model('DropdownStore', {
    label: types.maybe(types.string),
    name: types.maybe(types.string),
    disabled: false,
    placeholder: '',
    noDefaultPlaceholder: false,
    description: '',
    selected: types.maybeNull(types.string),
    options: types.array(Option),
    // special ID to associate <label /> with <select />
    formID: types.optional(types.string, UUID4),
})
    .volatile(function () { return ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelect: function (value) { },
    selectRef: undefined,
}); })
    .views(function (self) { return ({
    get placeholderToDisplay() {
        if (self.placeholder) {
            return self.placeholder;
        }
        if (!self.noDefaultPlaceholder && self.options.length > 1) {
            return '-- select an option --';
        }
        return null;
    },
    get defaultValue() {
        if (self.selected) {
            return self.selected;
        }
        return self.formID;
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.selectRef = React.createRef();
    },
    componentDidMount: function () {
        if (self.selectRef && self.selectRef.current) {
            self.selectRef.current.value = self.defaultValue;
        }
    },
    setOptions: function (options) {
        self.options = cast(options);
    },
    registerOnSelectHandler: function (func) {
        self.onSelect = func;
    },
    setDisabled: function (value) {
        self.disabled = value;
    },
    handleSelect: function (value, triggerParentOnSelect) {
        if (triggerParentOnSelect === void 0) { triggerParentOnSelect = true; }
        if (value === self.formID) {
            value = undefined;
        }
        if (value) {
            self.selected = value;
            if (self.selectRef && self.selectRef.current) {
                self.selectRef.current.value = self.selected;
            }
        }
        else {
            self.selected = null;
            if (self.selectRef && self.selectRef.current) {
                self.selectRef.current.value = self.formID;
            }
        }
        if (triggerParentOnSelect) {
            self.onSelect(value);
        }
    },
}); });
export default DropdownStore;
