var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var SimpleFormItem = function (props) { return (React.createElement("div", { onClick: props.onClick, className: props.className ? "form-item ".concat(props.className) : 'form-item' }, props.children)); };
var Wrapper = function (props) {
    if (props.thin) {
        return props.children || null;
    }
    return (React.createElement(SimpleFormItem, { onClick: props.onClick, className: props.className }, props.children));
};
var HelpText = function (_a) {
    var message = _a.message, className = _a.className;
    if (message === undefined) {
        return null;
    }
    return React.createElement("span", { className: className ? "help-text ".concat(className) : 'help-text' }, message);
};
var DescriptionOrError = function (_a) {
    var description = _a.description, error = _a.error;
    var message;
    var className;
    if (error) {
        message = error;
        className = 'text-danger';
    }
    else {
        message = description !== undefined ? description : error;
    }
    return React.createElement(HelpText, { message: message, className: className });
};
export default (function (props) { return (React.createElement(Wrapper, __assign({}, props),
    props.label && (React.createElement("label", { htmlFor: props.itemID },
        props.label,
        props.required && React.createElement("b", null),
        React.createElement(HelpText, { message: props.labelDescription }))),
    props.children,
    React.createElement(DescriptionOrError, { description: props.description, error: props.error }))); });
