var _a;
import { types } from 'mobx-state-tree';
// @ts-ignore: prismjs does not have type declaration
import { highlight as highlightWithPrismJS, languages as PrismJSLanguages } from 'prismjs/components/prism-core';
export var Syntaxes = {
    plain: 'plain',
    bash: 'bash',
    python: 'python',
    yaml: 'yaml',
    json: 'json',
    ini: 'ini',
};
export var SyntaxOptions = (_a = {},
    _a[Syntaxes.plain] = { title: 'Text', fileTypes: ['txt'], mimeType: 'text/plain', skipImport: true },
    _a[Syntaxes.bash] = { title: 'Bash', fileTypes: ['sh'], mimeType: 'text/x-sh' },
    _a[Syntaxes.python] = { title: 'Python', fileTypes: ['py'], mimeType: 'text/x-python' },
    _a[Syntaxes.yaml] = { title: 'YAML', fileTypes: ['yaml', 'yml'], mimeType: 'application/json' },
    _a[Syntaxes.json] = { title: 'JSON', fileTypes: ['json'], mimeType: 'text/yaml' },
    _a[Syntaxes.ini] = { title: 'INI file', fileTypes: ['ini'], mimeType: 'text/plain' },
    _a);
Object.keys(Syntaxes).map(function (name) { return !SyntaxOptions[name].skipImport && import("prismjs/components/prism-".concat(name)); });
var dummyProcessor = function (value) { return value; };
var numerator = function (value) {
    var codeLines = value.split('\n');
    var codeLinesWithNumbers = codeLines.map(function (line, i) { return "<span class='editorLineNumber'>".concat(i + 1, "</span>").concat(line); });
    return codeLinesWithNumbers.join('\n');
};
var TextInputStore = types
    .model('TextInput', {
    label: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    value: '',
    filename: '',
    syntax: types.maybeNull(types.enumeration('Syntax', Object.keys(Syntaxes))),
    displayLineNumbers: false,
    readOnly: false,
    excludeActionsButtons: false,
    // min-height is applied by-default for editable forms
    withoutMinHeight: false,
})
    .volatile(function () { return ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: function (value) { },
}); })
    .actions(function (self) { return ({
    registerOnChangeHandler: function (func) {
        self.onChange = func;
    },
    setValue: function (value) {
        self.value = value;
        self.onChange(self.value);
    },
    setLabel: function (label) {
        self.label = label;
    },
    setSyntax: function (syntax) {
        self.syntax = syntax === undefined ? Syntaxes.plain : syntax;
    },
    setReadOnly: function (value) {
        self.readOnly = value;
    },
    setFileName: function (value) {
        self.filename = value;
    },
}); })
    .views(function (self) { return ({
    get mimeType() {
        return SyntaxOptions[self.syntax || Syntaxes.plain].mimeType;
    },
    get fileBlob() {
        var byteArray = new Uint8Array(self.value.length);
        for (var i = 0; i < self.value.length; i++) {
            byteArray[i] = self.value.codePointAt(i);
        }
        return new Blob([byteArray], { type: this.mimeType });
    },
    get fileURL() {
        return window.URL.createObjectURL(this.fileBlob);
    },
    get containerClassName() {
        if (self.displayLineNumbers) {
            return 'text-editor text-editor-with-line-numbers';
        }
        return 'text-editor';
    },
    get preClassName() {
        if (!self.readOnly && !self.withoutMinHeight) {
            return 'text-editor-with-min-height';
        }
        return undefined;
    },
    get colorizer() {
        if (self.syntax && self.syntax !== Syntaxes.plain && PrismJSLanguages.hasOwnProperty(self.syntax)) {
            return function (value) { return highlightWithPrismJS(value, PrismJSLanguages[self.syntax]); };
        }
        return dummyProcessor;
    },
    get numerator() {
        if (self.displayLineNumbers) {
            return numerator;
        }
        return dummyProcessor;
    },
    get processor() {
        var _this = this;
        return function (value) { return _this.numerator(_this.colorizer(value)); };
    },
}); });
export default TextInputStore;
