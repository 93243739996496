import { types } from 'mobx-state-tree';
import { ApplicationStates } from 'Constants';
import { InputStore, NoDisplayError } from 'components/forms/Input';
var HostsManagersListS = types
    .model('HostsManagersPage', {
    loaded: types.optional(types.boolean, false),
    stateFilter: types.optional(types.map(types.boolean), Object.fromEntries(Object.values(ApplicationStates).map(function (s) { return [s, true]; }))),
    nameFilter: types.optional(InputStore, function () {
        return InputStore.create({
            inputClassName: 'search',
            inputType: 'search',
            placeholder: 'Filter',
            label: 'Filter',
            autoFocus: true,
            error: NoDisplayError.create({}),
        });
    }),
    sortByField: 'name',
    reverseOrder: false,
    confirmDeleteForID: types.maybeNull(types.string),
})
    .views(function (self) { return ({
    get queryString() {
        var params = [];
        if (Array.from(self.stateFilter.values()).indexOf(false) !== -1) {
            Array.from(self.stateFilter.entries()).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                if (value) {
                    params.push("state=".concat(key));
                }
            });
        }
        if (self.nameFilter.value !== '') {
            params.push("name=".concat(encodeURIComponent(self.nameFilter.value)));
        }
        return params.join('&');
    },
}); })
    .actions(function (self) { return ({
    applyStateFilter: function (state, value) {
        self.stateFilter.set(state, value);
    },
    applyNameFilter: function (value) {
        self.nameFilter.setValue(value);
        // temporary hack needed to not rerender table twice
        self.loaded = true;
    },
    sortBy: function (sortField, reverseOrder) {
        self.sortByField = sortField;
        self.reverseOrder = reverseOrder;
    },
    setConfirmDeleteForID: function (applicationID) {
        if (applicationID === void 0) { applicationID = null; }
        self.confirmDeleteForID = applicationID;
    },
}); });
export default HostsManagersListS;
