import React from 'react';
import { Link } from 'react-router-dom';

import copy from 'copy-to-clipboard';

import Icon from 'components/Icon';

export const AdditiveTabSelector = (props) => (
  <div className={`addition ${props.active ? 'active' : ''}`}>
    <Link to={props.link}>{props.title}</Link>
    {props.copyValue && (
      <button type="button" className="btn btn-link btn-copy" onClick={() => copy(props.copyValue)}>
        <Icon className="copy" />
      </button>
    )}
  </div>
);

export const TabSelector = (props) => (
  <li className={props.active ? 'active' : ''}>
    <Link to={props.link} onClick={props.onClick}>
      {props.title}
    </Link>
  </li>
);

export class TabSelectorWithDropdown extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.globalMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.globalMouseClick);
  }

  handleDropDown = (e) => {
    e.stopPropagation();
    const expanded = this.state.expanded;
    this.setState({ expanded: !expanded });
  };

  globalMouseClick = () => {
    if (this.state.expanded) {
      this.setState({ expanded: false });
    }
  };

  render() {
    return (
      <li className={this.props.active ? 'active' : ''}>
        <a onClick={this.handleDropDown} className="dropdown">
          {this.props.items[this.props.selected || 0].title}
        </a>
        <div className={this.state.expanded ? 'dropdown-menu show' : 'dropdown-menu'}>
          {this.props.items.map((item) => (
            <span key={item.title}>
              <Link to={item.link}>{item.title}</Link>
            </span>
          ))}
        </div>
      </li>
    );
  }
}

export const TabsBar = (props) => (
  <div className="tabs-container">
    <ul>{props.children}</ul>
    {props.addition && (
      <AdditiveTabSelector
        key={props.addition.title}
        title={props.addition.title}
        link={props.addition.url}
        active={props.addition.isActive}
        copyValue={props.addition.component.props.copyValue}
      />
    )}
  </div>
);
