import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Input from 'components/forms/Input';
import Icon from 'components/Icon';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import Table, { TableCell, TableRow } from 'components/Table';
import UsersPageStore from 'pages/Organization/Users/UsersListS';
var UserStateFilter = observer(function (_a) {
    var pageState = _a.pageState;
    var navigate = useNavigate();
    var onFilterChange = function (e) {
        pageState.toggleFilter(e.target.id);
        navigate(pageState.usersListURL);
    };
    return (React.createElement("form", { className: "form-box" },
        React.createElement("div", { className: "btn-group" },
            React.createElement("div", { className: "btn-group-item" },
                React.createElement("input", { type: "checkbox", className: "check", name: "active", id: "active", defaultChecked: pageState.showActive, onChange: onFilterChange }),
                React.createElement("label", { className: "btn", htmlFor: "active" }, "Show Active")),
            React.createElement("div", { className: "btn-group-item" },
                React.createElement("input", { type: "checkbox", className: "check", name: "suspended", id: "suspended", defaultChecked: pageState.showSuspended, onChange: onFilterChange }),
                React.createElement("label", { className: "btn", htmlFor: "suspended" }, "Show Suspended")),
            React.createElement("div", { className: "btn-group-item" },
                React.createElement("input", { type: "checkbox", className: "check", name: "invited", id: "invited", defaultChecked: pageState.showInvited, onChange: onFilterChange }),
                React.createElement("label", { className: "btn", htmlFor: "invited" }, "Show Invited")))));
});
var AdminButtons = observer(function (props) {
    if (!props.pageState.userIsAdmin) {
        return null;
    }
    var inviteLink = !process.env.DISABLE_NOT_WORKING && (React.createElement("div", { className: "form-item" },
        React.createElement("div", { className: "item-container" },
            React.createElement(Link, { to: "/organization/users/invite", className: "btn btn-primary" }, "Invite"))));
    return (React.createElement("form", { className: "form-box-line" },
        React.createElement("div", { className: "form-item" },
            React.createElement("div", { className: "item-container" },
                React.createElement(Link, { to: "/organization/users/create", className: "btn btn-primary" }, "Create"))),
        inviteLink));
});
var HeaderControls = function (_a) {
    var pageState = _a.pageState;
    return (React.createElement("div", { className: "filters-box" },
        React.createElement("form", { className: "form-box" },
            React.createElement(Input, { store: pageState.nameFilter })),
        React.createElement(UserStateFilter, { pageState: pageState }),
        React.createElement(AdminButtons, { pageState: pageState })));
};
var UserDeleteCell = observer(function (_a) {
    var email = _a.email, pageState = _a.pageState;
    if (!pageState.userIsAdmin) {
        return null;
    }
    return (React.createElement(TableCell, null,
        React.createElement(Icon, { className: "delete", onClick: function () {
                pageState.setConfirmDeleteForUser(email);
            } })));
});
var UserRow = observer(function (_a) {
    var user = _a.user, pageState = _a.pageState;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null, user.fullName),
        React.createElement(TableCell, null, user.email),
        React.createElement(TableCell, null, user.role),
        React.createElement(TableCell, null, user.state),
        React.createElement(UserDeleteCell, { email: user.email, pageState: pageState })));
});
var UsersTable = observer(function (_a) {
    var pageState = _a.pageState;
    if (pageState.users.length === 0) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(Table, { headers: pageState.tableHeaders, onSortChange: pageState.onSortChange }, pageState.users.map(function (u) { return (React.createElement(UserRow, { user: u, key: u.email, pageState: pageState })); })));
});
var DeleteIsInProgress = observer(function (props) {
    if (!props.pageState.userDeleteIsInProgress) {
        return null;
    }
    return React.createElement(Spinner, null);
});
export default inject('org', 'profile')(observer(function (_a) {
    var org = _a.org, profile = _a.profile;
    var pageState = useState(UsersPageStore.create({}))[0];
    var navigate = useNavigate();
    var location = useLocation();
    pageState.registerNavigator(navigate);
    pageState.registerUsersStore(org.Users);
    useEffect(function () {
        pageState.loadFromQuery(location.search);
    }, []);
    useEffect(function () {
        if (profile.isLogged) {
            pageState.setIsAdmin(profile.user.isAdmin);
        }
    }, [profile.isLogged]);
    if (!profile.isLogged) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderControls, { pageState: pageState }),
        React.createElement(DeleteIsInProgress, { pageState: pageState }),
        React.createElement(UsersTable, { pageState: pageState }),
        pageState.confirmDeleteForUser && (React.createElement(ConfirmationAboutDeleteWindow, { onClose: function () { return pageState.setConfirmDeleteForUser(null); }, onCancel: function () { return pageState.setConfirmDeleteForUser(null); }, onDelete: function () { return pageState.deleteSelectedUser(); } }))));
}));
