import { types } from 'mobx-state-tree';
import PasswordValidator from 'password-validator';
import InputStore, { ErrorStore, NoDisplayError } from 'components/forms/Input/InputS';
export var makePasswordEqualityValidator = function (primaryPasswordInputStore) {
    return function (value) {
        if (value !== primaryPasswordInputStore.value) {
            return 'Passwords are not equal.';
        }
        return false;
    };
};
var ValidationSchema = types
    .model('ValidationSchema', {
    minLength: 8,
    restrictFirstSpaceChar: true,
    restrictLastSpaceChar: true,
    minDigits: 1,
})
    .volatile(function () { return ({
    validator: null,
}); })
    .actions(function (self) { return ({
    validate: function (value) {
        if (self.validator === null) {
            return false;
        }
        var result = self.validator.validate(value, { details: true });
        if (result.length > 0) {
            return result[0].message.replace('The string should', 'Password should');
        }
        return false;
    },
    afterCreate: function () {
        self.validator = new PasswordValidator();
        if (self.minLength) {
            self.validator.is().min(self.minLength);
        }
        if (self.minDigits) {
            self.validator.has().digits(self.minDigits);
        }
        if (self.restrictFirstSpaceChar) {
            self.validator.usingPlugin(function (value) { return !value.startsWith(' '); }, 'Password should not start with a space char');
        }
        if (self.restrictLastSpaceChar) {
            self.validator.usingPlugin(function (value) { return !value.endsWith(' '); }, 'Password should not end with a space char');
        }
    },
}); });
var PasswordInputStore = InputStore.named('PasswordInputStore')
    .props({
    showPassword: false,
    // override parent's default values
    inputType: 'password',
    inputClassName: 'password',
    label: 'Password',
    onChangeHandlingDelay: 0,
    // We have a format validator, so let's display error by default
    error: types.optional(types.union(ErrorStore, NoDisplayError), function () { return ErrorStore.create(); }),
    schema: types.maybeNull(ValidationSchema),
})
    .actions(function (self) { return ({
    toggleShowPassword: function (e) {
        e.stopPropagation();
        e.preventDefault();
        self.showPassword = !self.showPassword;
        if (self.showPassword) {
            self.inputType = 'text';
        }
        else {
            self.inputType = 'password';
        }
    },
    afterCreate: function () {
        if (self.schema) {
            self.addInputValidator(self.schema.validate);
        }
    },
}); });
export default PasswordInputStore;
