const ROOT_TOKEN_KEY = '_root';
const LAST_SELECTED_INSTANCE_KEY = 'last-selected-instance';

export const cleanData = () => localStorage.clear();

export const getTokens = () => {
  const serialized = localStorage.getItem('tokens');
  return serialized ? JSON.parse(serialized) : {};
};

const setToken = (key, token) => {
  const tokens = getTokens();
  tokens[key] = token;
  localStorage.setItem('tokens', JSON.stringify(tokens));
};

const getToken = (key) => getTokens()[key];

export const setRootToken = (token, email) => {
  if (localStorage.getItem('email') !== email) {
    // user changed.
    cleanData();
  }
  setToken(ROOT_TOKEN_KEY, token);
  localStorage.setItem('email', email);
};

export const getRootToken = () => getToken(ROOT_TOKEN_KEY);

export const deleteItLookToken = () => localStorage.removeItem(ROOT_TOKEN_KEY);

export const getLastSelectedInstance = () => localStorage.getItem(LAST_SELECTED_INSTANCE_KEY);

export const setLastSelectedInstance = (instanceName) => localStorage.setItem(LAST_SELECTED_INSTANCE_KEY, instanceName);

export const getInstanceToken = (instance) => {
  const tokens = getTokens();

  return instance in tokens ? tokens[instance] : '';
};

export const setInstanceToken = (instance, token) => {
  setToken(instance, token);
};
