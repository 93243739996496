import { types } from 'mobx-state-tree';
import InputStore, { ErrorStore, NoDisplayError } from 'components/forms/Input/InputS';
export var validateEmail = function (value) {
    // prettier-ignore
    // eslint-disable-next-line no-useless-escape
    var re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!value.match(re)) {
        return 'Email has invalid format.';
    }
    return false;
};
export default InputStore.named('EmailInputStore')
    .props({
    // override parent's default values
    inputType: 'email',
    name: 'email',
    placeholder: 'Email',
    label: 'Email',
    // We have a format validator, so let's display error by default
    error: types.optional(types.union(ErrorStore, NoDisplayError), function () { return ErrorStore.create(); }),
})
    .actions(function (self) { return ({
    afterCreate: function () {
        self.addInputValidator(validateEmail);
    },
}); });
