import { types } from 'mobx-state-tree';
import requests from 'lib/requests';
import { EmailInputStore, ErrorStore, InputStore, NoDisplayError, PasswordInputStore } from 'components/forms/Input';
export default types
    .model('SignUpForm', {
    organizationName: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Organization',
            description: 'Short name of organization',
            error: ErrorStore.create({}),
        });
    }),
    organizationDisplayName: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Organization display name',
            description: 'Optional display name of Organization',
            error: NoDisplayError.create({}),
        });
    }),
    firstName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'First name', error: NoDisplayError.create({}) });
    }),
    lastName: types.optional(InputStore, function () {
        return InputStore.create({ label: 'Last name', error: NoDisplayError.create({}) });
    }),
    email: types.optional(EmailInputStore, function () { return EmailInputStore.create({ error: ErrorStore.create({}) }); }),
    password: types.optional(PasswordInputStore, function () { return PasswordInputStore.create({ error: ErrorStore.create({}) }); }),
    // the process of creating an organization
    creating: false,
})
    .actions(function (self) { return ({
    validateOrganizationNameExistenceInTheSystem: function (value) {
        if (!value) {
            return undefined;
        }
        var newPromise = new Promise(function (resolve) {
            var apiUrl = "/p/api/v1/organizations/check/".concat(value);
            requests.GET({
                url: apiUrl,
                onFailure: function (response, errors) { return resolve(errors[0]); },
                onSuccess: function () { return resolve(undefined); },
            });
        });
        return newPromise;
    },
    afterCreate: function () {
        self.organizationName.addInputValidator(this.validateOrganizationNameExistenceInTheSystem);
    },
    setCreatingFlag: function (value) {
        self.creating = value;
        self.organizationName.setDisabled(value);
        self.organizationDisplayName.setDisabled(value);
        self.firstName.setDisabled(value);
        self.lastName.setDisabled(value);
        self.email.setDisabled(value);
        self.password.setDisabled(value);
    },
    signup: function (onSuccess) {
        var _this = this;
        this.setCreatingFlag(true);
        var body = {
            password: self.password.value,
            email: self.email.value,
            first_name: self.firstName.value,
            last_name: self.lastName.value,
            name: self.organizationName.value,
        };
        if (self.organizationDisplayName.value) {
            body.display_name = self.organizationDisplayName.value;
        }
        requests.POST({
            url: '/p/api/v1/organizations/',
            body: body,
            onSuccess: onSuccess,
            onFailure: function (response, errors) {
                if (errors.length > 1) {
                    self.organizationDisplayName.setError(errors[1]);
                }
                else {
                    self.organizationDisplayName.setError(errors[0]);
                }
            },
            onFinish: function () { return _this.setCreatingFlag(false); },
        });
    },
}); })
    .views(function (self) { return ({
    get error() {
        return self.organizationDisplayName.error.value || self.email.error.value;
    },
    get filled() {
        return (self.email.isDone() &&
            self.password.isDone() &&
            self.firstName.isDone() &&
            self.lastName.isDone() &&
            self.organizationName.isDone());
    },
}); });
