import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as ContentHeader from 'components/ContentHeader';
import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { Spinner } from 'components/Loader';
import { PageHeader } from 'components/Page';
import { TabsGroup, TabView, TabViewsHeader, TabViewsSwitcher } from 'components/TabsPageView';
import NotFound from 'pages/NotFound';
var ModelPartLink = function (_a) {
    var model = _a.model;
    return React.createElement(Link, { to: "/catalog/models/".concat(model) }, model);
};
var Parts = function (props) {
    if (props.parts.length === 0) {
        return null;
    }
    var types = props.parts.filter(function (p) { return p.startsWith('std::types'); });
    var stdModels = props.parts.filter(function (p) { return p.startsWith('std::') && !p.startsWith('std::types'); });
    var other = props.parts.filter(function (p) { return !p.startsWith('std'); });
    return (React.createElement(ContentHeader.Item, null,
        React.createElement("span", { key: "title" }, "Parts:"),
        types && React.createElement("span", { key: "types" }, types && types.map(function (m) { return React.createElement(ModelPartLink, { model: m, key: m }); })),
        stdModels && React.createElement("span", { key: "std" }, stdModels && stdModels.map(function (m) { return React.createElement(ModelPartLink, { model: m, key: m }); })),
        other && React.createElement("span", { key: "other" }, other && other.map(function (m) { return React.createElement(ModelPartLink, { model: m, key: m }); }))));
};
var StatusClassMap = {
    development: 'text-warning',
    stable: 'text-success',
    deprecated: 'text-danger',
};
var ModelHeader = observer(function (props) {
    useEffect(function () {
        if (!props.model.definition.loaded && !props.model.definition.loading) {
            props.model.definition.fetch();
        }
    }, [props.model.definition.loaded]);
    if (!props.model.definition.loaded) {
        return null;
    }
    var definition = props.model.definition.obj;
    var modelsOrgLink = "/i/main/api/models?org=".concat(definition.organization);
    var modelsGroupLink = "/i/main/api/models?group=".concat(definition.group);
    var inheritsLinks = definition.inherits.map(function (model) { return React.createElement(ModelPartLink, { model: model, key: "i-".concat(model) }); });
    return (React.createElement(ContentHeader.Container, null,
        React.createElement(ContentHeader.ColumnWithIconItem, { url: props.model.pictureUrl }),
        React.createElement(ContentHeader.Column, null,
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Status:"),
                React.createElement("b", { className: StatusClassMap[definition.status] }, definition.status)),
            definition.inherits.length > 0 && (React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Inherits:"),
                inheritsLinks))),
        React.createElement(ContentHeader.Column, { large: true },
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Description:"),
                definition.description),
            React.createElement(Parts, { parts: definition.parts })),
        React.createElement(ContentHeader.Column, { large: true },
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Search:"),
                React.createElement("span", null,
                    React.createElement(Link, { to: modelsOrgLink },
                        "View all models of ",
                        definition.organization,
                        " organization")),
                React.createElement("span", null,
                    React.createElement(Link, { to: modelsGroupLink },
                        "View all models of ",
                        definition.group,
                        " group")))),
        React.createElement(ContentHeader.Column, null,
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Created at:"),
                moment(definition.created_at).format('MMM D YYYY, HH:mm')),
            React.createElement(ContentHeader.Item, null,
                React.createElement("span", null, "Updated at:"),
                moment(definition.updated_at).format('MMM D YYYY, HH:mm')))));
});
var ModelDoc = observer(function (props) {
    var schema = useState(props.model.getSchema(props.schema))[0];
    useEffect(function () {
        if (!schema.loaded && !schema.loading) {
            schema.fetch();
        }
    }, [schema.loading, schema.loaded]);
    if (!schema.loaded) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(SimpleTextViewer, { syntax: Syntaxes.json, value: JSON.stringify(schema.obj, null, 4) });
});
export default inject('store')(observer(function (props) {
    var Models = useState(props.store.Models)[0];
    var params = useParams();
    useEffect(function () {
        if (!Models.loaded && !Models.loading) {
            Models.fetch();
        }
    }, [Models.loaded, Models.loading]);
    var getModel = function () {
        if (params.modelFirstPart === undefined) {
            return undefined;
        }
        var modelParts = params.modelFirstPart.split('::');
        if (modelParts.length !== 2) {
            return undefined;
        }
        var modelOrg = modelParts[0], modelGroup = modelParts[1];
        var modelIdentifier = params.modelIdentifier;
        var fullName = "".concat(modelOrg, "::").concat(modelGroup, "/").concat(modelIdentifier);
        return Models.getByIdentifier(fullName);
    };
    if (!Models.loaded) {
        return React.createElement(Spinner, null);
    }
    var model = getModel();
    if (model === undefined) {
        return React.createElement(NotFound, null);
    }
    var subTitle = [
        // FIXME(andreykurilin): the filtering should be done by fullOrgName
        React.createElement(Link, { to: "/i/main/api/models?group=".concat(model.group) },
            model.organization,
            "::",
            model.group),
        params.modelIdentifier,
    ];
    return (React.createElement(TabViewsSwitcher, null,
        React.createElement(PageHeader, { to: "/i/main/api/models", title: "Models", subTitle: subTitle, documentTitle: model.fullName }),
        React.createElement(TabViewsHeader, null,
            React.createElement(ModelHeader, { model: model })),
        React.createElement(TabView, { title: "Definition", url: "definition", key: "def" },
            React.createElement(ModelDoc, { key: "def", model: model, schema: "definition" })),
        React.createElement(TabView, { title: "Spec", url: "spec", key: "spec" },
            React.createElement(ModelDoc, { key: "spec", model: model, schema: "spec" })),
        React.createElement(TabsGroup, null,
            React.createElement(TabView, { title: "JSONSchema", url: "jsonschema", key: "jsonschema" },
                React.createElement(ModelDoc, { key: "jsonschema", model: model, schema: "jsonschema" })),
            React.createElement(TabView, { title: "JSONSchema:Create", url: "jsonschema-create", key: "create" },
                React.createElement(ModelDoc, { key: "create", model: model, schema: "jsonschema:create" })),
            React.createElement(TabView, { title: "JSONSchema:Update", url: "jsonschema-update", key: "update" },
                React.createElement(ModelDoc, { key: "update", model: model, schema: "jsonschema:update" })),
            React.createElement(TabView, { title: "JSONSchema:Delete", url: "jsonschema-delete", key: "delete" },
                React.createElement(ModelDoc, { key: "delete", model: model, schema: "jsonschema:delete" })))));
}));
