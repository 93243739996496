var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { types } from 'mobx-state-tree';
import { sortObjects } from 'lib/sort-utils';
import { DropdownSelectorStore, InputStore, NoDisplayError } from 'components/forms/Input';
var AllGroupsOption = { value: '-', title: 'All groups' };
var ModelsPageFilters = types
    .model('ModelsPageFilters', {
    filter: types.optional(types.map(types.string), { name: '', group: '' }),
    nameFilter: types.optional(InputStore, function () {
        return InputStore.create({
            formItemClassName: 'search',
            inputType: 'search',
            placeholder: '',
            label: 'Filter by name',
            error: NoDisplayError.create(),
        });
    }),
    groupFilter: types.optional(DropdownSelectorStore, function () {
        return DropdownSelectorStore.create({
            label: 'Group',
            noDefaultPlaceholder: true,
            options: [AllGroupsOption],
        });
    }),
    sortByField: 'fullName',
    reverseOrder: false,
})
    .volatile(function () { return ({
    modelsStore: null,
}); })
    .views(function (self) { return ({
    get queryParams() {
        var params = {};
        if (self.nameFilter.value) {
            params.name = self.nameFilter.value;
        }
        if (self.groupFilter.selected && self.groupFilter.selected !== AllGroupsOption.value) {
            params.group = self.groupFilter.selected;
        }
        return params;
    },
    get items() {
        if (self.modelsStore === null) {
            return [];
        }
        return self.modelsStore.items.filter(function (item) {
            var groupMatch = !self.groupFilter.selected ||
                self.groupFilter.selected === AllGroupsOption.value ||
                item.group === self.groupFilter.selected;
            var nameMatch = !self.nameFilter.value || item.fullName.toLowerCase().includes(self.nameFilter.value.toLowerCase());
            return groupMatch && nameMatch;
        });
    },
    get sortedItems() {
        var sortedModels = __spreadArray([], this.items, true);
        sortedModels.sort(function (modelA, modelB) {
            return sortObjects(modelA, modelB, self.sortByField, 'fullName', self.reverseOrder);
        });
        return sortedModels;
    },
}); })
    .actions(function (self) { return ({
    linkModelsStore: function (store) {
        self.modelsStore = store;
        var items = [AllGroupsOption];
        var groups = new Set(store.items.map(function (m) { return m.group; }));
        groups.forEach(function (g) { return items.push({ value: g, title: g }); });
        self.groupFilter.setOptions(items);
    },
    sortBy: function (sortField, reverseOrder) {
        self.sortByField = sortField;
        self.reverseOrder = reverseOrder;
    },
    setNameFilter: function (value) {
        self.nameFilter.setValue(value ? value.toLowerCase() : '');
    },
    setGroupFilter: function (value) {
        self.groupFilter.handleSelect(value || AllGroupsOption.value);
    },
}); });
export default ModelsPageFilters;
