import React, { useEffect, useRef, useState } from 'react';

import * as AsciinemaPlayerLibrary from 'asciinema-player';
import PropTypes from 'prop-types';

import { useWindowDimensions } from 'lib/windowSize';

// an adopted version of https://github.com/asciinema/asciinema-player/issues/72#issuecomment-1024929693
const AsciinemaPlayer = (props) => {
  const ref = useRef(null);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const [height, setHeight] = useState(windowHeight);

  useEffect(() => {
    if (ref.current) {
      // reducing height is creating padding on bottom, that is needed
      // to dispaly everything nicely
      setHeight(windowHeight - ref.current.getBoundingClientRect().top - 60);
    }
  }, [ref.current, windowHeight, windowWidth]);

  useEffect(() => {
    // NOTE(andreykurilin): The following function is described at asciinema-player.dist.index.js
    //    and it has a weird input argument spec: function create(src, elem)
    //    The weird thing is a first line of the function which is hack to
    //    allow the third argument to be passed:
    //      let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    AsciinemaPlayerLibrary.create({ data: props.data }, ref.current, props);
  }, []);

  return <div ref={ref} style={{ height: height }} />;
};

AsciinemaPlayer.propTypes = {
  data: PropTypes.array,
  // START asciinemaOptions
  /* eslint-disable react/no-unused-prop-types */
  cols: PropTypes.number,
  rows: PropTypes.number,
  autoPlay: PropTypes.bool,
  preload: PropTypes.bool,
  loop: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  startAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
  idleTimeLimit: PropTypes.number,
  theme: PropTypes.string,
  poster: PropTypes.string,
  fit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fontSize: PropTypes.string,
  controls: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /* eslint-enable react/no-unused-prop-types */
  // END asciinemaOptions
};

AsciinemaPlayer.defaultProps = {
  // NOTE(andreykurilin): All values `!= false` result in taking the whole
  //  page by player. I tried to wrap it
  fit: 'both',
  controls: true,
  loop: false,
};

export default AsciinemaPlayer;
