import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { AllowedStatuses, JustADot } from 'components/charts/StatusChart';
import { RecordLink } from 'components/InventoryLinks';
import JournalLogs from 'components/JournalLogs';
import JournalLogsStore from 'components/JournalLogsS';
var ProgressDots = function () {
    var _a = useState(0), counter = _a[0], setCounter = _a[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setCounter(function (current) { return (current + 1) % 4; });
        }, 300);
        return function () { return clearInterval(interval); };
    }, []);
    return (React.createElement("span", { style: { whiteSpace: 'break-spaces' } },
        '.'.repeat(counter),
        ' '.repeat(3 - counter)));
};
var StateItem = observer(function (_a) {
    var record = _a.record;
    if (!record.script_run_1.completedAt) {
        return (React.createElement(React.Fragment, null,
            "Still running",
            React.createElement(ProgressDots, null)));
    }
    if (record.script_run_1.exitCode !== 0) {
        return (React.createElement(React.Fragment, null,
            "Failed",
            React.createElement(JustADot, { status: AllowedStatuses.Critical })));
    }
    return (React.createElement(React.Fragment, null,
        "Finished",
        React.createElement(JustADot, { status: AllowedStatuses.Ok })));
});
var QuickScriptThreshold = 10 * 1000;
var isQuickScript = function (record) {
    return record.script_run_1.completedAt - record.script_run_1.startedAt < QuickScriptThreshold;
};
var ScriptRunTab = observer(function (_a) {
    var record = _a.record;
    var _b = useState(Boolean(record.script_run_1.completedAt)), completed = _b[0], setCompleted = _b[1];
    var store = useState(JournalLogsStore.create({
        inherits: 'std::host/ScriptRunLog:1',
        owner: record.root_1.id,
        watch: !record.script_run_1.completedAt ||
            // watch for logs if the script ends quickly
            isQuickScript(record),
    }))[0];
    useEffect(function () {
        if (completed) {
            return;
        }
        var interval = setInterval(function () {
            if (!record.loading) {
                record.update();
            }
            if (record.script_run_1.completedAt) {
                setCompleted(true);
            }
        }, 1000);
        return function () { return clearInterval(interval); };
    }, []);
    useEffect(function () {
        store.linkRecord(record);
        store.onComponentDidMount();
        return function () { return store.onComponentWillUnmount(); };
    }, []);
    var stats = {
        State: React.createElement(StateItem, { record: record }),
        PID: record.script_run_1.pid || 'n/a',
        'Started at': moment(record.script_run_1.startedAt).format(),
    };
    if (store.completed) {
        var completedAt = moment(record.script_run_1.completedAt).format();
        stats['Execution time'] = (React.createElement("span", { title: "Completed at: ".concat(completedAt), key: "exec-time-value" },
            (record.script_run_1.completedAt - record.script_run_1.startedAt) / 1000,
            "s"));
        stats['Exit code'] = record.script_run_1.exitCode;
    }
    var targetKey = 'Target';
    if (record.script_run_1.targets.length > 1) {
        targetKey += 's';
    }
    stats[targetKey] = record.script_run_1.targets.map(function (r) { return React.createElement(RecordLink, { key: r, recordId: r, className: "" }); });
    stats['Initiated by'] = React.createElement(RecordLink, { recordId: record.script_run_1.user, className: "" });
    return React.createElement(JournalLogs, { key: "script-log", store: store, stats: stats });
});
// @ts-ignore: eslint does not like assigning additional items to elements
ScriptRunTab.suites = function (record) { return record.script_run_1; };
export default ScriptRunTab;
