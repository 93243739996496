import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Popover from 'components/Popover';

const defaultPopoverFormatter = (date, value) =>
  `${value === 0 ? 'No' : value} changes on ${date.format('D MMMM YYYY')}`;

const Cell = (props) => (
  <Popover>
    <Popover.Trigger>
      <div className={`day ${props.level}`} onClick={() => props.onClick(props.date, props.value)} />
    </Popover.Trigger>
    <Popover.Window>
      {props.formatPopover && props.formatPopover(props.date, props.value)}
      {!props.formatPopover && defaultPopoverFormatter(props.date, props.value)}
    </Popover.Window>
  </Popover>
);

const CellPlaceholder = () => <div className="day spacer" />;

const WeekPlaceholder = () => (
  <div className="week">
    {Array.from(Array(7)).map((v, index) => (
      <CellPlaceholder key={index} />
    ))}
  </div>
);

const WeekDaysPanel = () => (
  <div className="month">
    <div className="week">
      <div className="day name">M</div>
      <div className="day name" />
      <div className="day name">W</div>
      <div className="day name" />
      <div className="day name">F</div>
      <div className="day name" />
      <div className="day name">S</div>
    </div>
  </div>
);

const MonthBlock = (props) => {
  // We need to add empty placeholders so first day will be displayed at the
  //  proper weekday place
  const firstDay = props.weeks[0][0];
  const daysToMonday = firstDay.date.isoWeekday() - 1;

  return (
    <div className={`month ${props.name}`}>
      {daysToMonday === 0 && <WeekPlaceholder />}
      {props.weeks.map((days, index) => (
        <div className="week" key={index}>
          {index === 0 && Array.from(Array(daysToMonday)).map((v, dayNo) => <CellPlaceholder key={dayNo} />)}
          {days.map((cell) => (
            <Cell
              key={cell.ts}
              level={cell.level}
              date={cell.date}
              value={cell.value}
              onClick={props.onClick}
              formatPopover={props.formatPopover}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default class HeatMapCalendar extends React.Component {
  monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  getProcessedData = () => {
    let minValue;
    let maxValue;

    const processedData = Object.fromEntries(
      Array.from(this.props.data.entries()).map(([strTs, value]) => {
        // find min and max values to categorize cells
        minValue = minValue !== undefined ? Math.min(minValue, value) : value;
        maxValue = maxValue !== undefined ? Math.max(maxValue, value) : value;

        // cut hour, minutes and seconds part
        const date = moment(parseInt(strTs, 10)).startOf('day');
        const ts = date.unix();

        return [ts, value];
      })
    );

    return [minValue, maxValue, processedData];
  };

  getFirstAndLastDays = () => {
    let firstDay;
    let lastDay;
    if (this.props.startDate) {
      firstDay = moment(this.props.startDate / 1000);
      lastDay = moment(firstDay).add(1, 'years').subtract(1, 'days');
    } else {
      lastDay = moment().startOf('day');
      firstDay = moment(lastDay).subtract(1, 'years').add(1, 'days');
    }
    return [firstDay, lastDay];
  };

  getLevels(minValue, maxValue) {
    // do this in better way

    const zero = 0;
    const first = minValue;
    let second;
    let third;
    let fourth;
    let fifth;

    const diff = maxValue - minValue;
    if (diff < 5) {
      second = first;
      third = first;
      fourth = first;
      fifth = maxValue - diff / 2;
    } else {
      const step = diff / 5;
      second = first + step;
      third = second + step;
      fourth = third + step;
      fifth = fourth + step;
    }

    return [
      [zero, ''],
      [first, ' xs'],
      [second, ' s'],
      [third, ' m'],
      [fourth, ' l'],
      [fifth, ' xl'],
    ];
  }

  groupDaysByWeeksAndMonths(data, firstDay, lastDay, levels) {
    const defaultLevel = levels[0][1];
    const months = [];
    let currentMonth;
    let currentWeek;

    let currentDay = moment(firstDay);
    while (lastDay.isSameOrAfter(currentDay)) {
      // preparation
      if (currentMonth === undefined || currentDay.date() === 1) {
        currentMonth = {
          name: this.monthNames[currentDay.month()],
          weeks: [],
        };
        months.push(currentMonth);
        currentWeek = undefined;
      }
      if (currentWeek === undefined || currentDay.isoWeekday() === 1) {
        currentWeek = [];
        currentMonth.weeks.push(currentWeek);
      }

      // actual data for a day
      const ts = currentDay.unix();
      const value = data.hasOwnProperty(ts) ? data[ts] : 0;

      const suitableLevels = levels.filter(([highValue]) => value <= highValue);
      let level;
      if (suitableLevels.length > 0) {
        level = suitableLevels[0][1];
      }

      currentWeek.push({
        ts: ts,
        level: level || defaultLevel,
        date: currentDay,
        value: value,
      });

      currentDay = moment(currentDay).add(1, 'days');
    }

    if (months[0].name === currentMonth.name) {
      months[0].key = `${months[0].name}-0`;
    }

    return months;
  }

  render() {
    const [minValue, maxValue, data] = this.getProcessedData();
    const [firstDay, lastDay] = this.getFirstAndLastDays();
    const cellsLevels = this.getLevels(minValue, maxValue);

    const months = this.groupDaysByWeeksAndMonths(data, firstDay, lastDay, cellsLevels);

    return (
      <div className="calendar-chart">
        <WeekDaysPanel />
        {months.map((month) => (
          <MonthBlock
            key={month.key || month.name}
            name={month.name}
            weeks={month.weeks}
            cellsLevels={cellsLevels}
            onClick={this.props.onClick}
            formatPopover={this.props.formatPopover}
          />
        ))}
      </div>
    );
  }
}

HeatMapCalendar.propTypes = {
  // NOTE(andreykurilin): ideally, we should use
  //  `PropTypes.objectOf(PropTypes.number).isRequired` validator here, but it
  //  will refuse usage of mobx-state-tree.Map database which is quite similar.
  data: PropTypes.object.isRequired,
  startDate: PropTypes.number,
  onClick: PropTypes.func,
  formatPopover: PropTypes.func,
};
