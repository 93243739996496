import React from 'react';

export default class extends React.PureComponent {
  tagName = 'item';

  defaultClassName = '';

  render() {
    const { children, className, ...props } = this.props;
    props.is = this.tagName;
    if (className !== undefined) {
      props.class = this.defaultClassName ? `${this.defaultClassName} ${className}` : className;
    } else {
      props.class = this.defaultClassName;
    }

    return React.createElement(this.tagName, props, children);
  }
}
