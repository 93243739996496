import React, { useState } from 'react';

import { observer } from 'mobx-react';

import { LeftPanel, RightPanel, SidedPanel } from 'components/layout/Panels';
import History from 'pages/Instances/RecordView/overview/History';
import InfoTab from 'pages/Instances/RecordView/overview/InfoTab';
import JSONView from 'pages/Instances/RecordView/overview/JSONTab';

const CheckBoxLikeAButton = (props) => (
  <div
    className="btn-group-item"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      props.onClick();
    }}
  >
    <input type="checkbox" className="check" name="active" id="active" checked={props.checked} readOnly />
    <label className="btn" htmlFor="active">
      {props.title}
    </label>
  </div>
);

const HumanReadable = (props) => {
  return <InfoTab record={props.record} />;
};

export default observer((props) => {
  const [showJSON, setShowJSON] = useState(false);

  return (
    <SidedPanel>
      <LeftPanel>
        <div className="title-container">
          <form className="form-box">
            <div className="btn-group">
              <CheckBoxLikeAButton title="For Humans" checked={!showJSON} onClick={() => setShowJSON(!showJSON)} />
            </div>
          </form>
        </div>
        {!showJSON && <HumanReadable record={props.record} />}
        {showJSON && <JSONView record={props.record} />}
      </LeftPanel>
      <RightPanel>{props.record.versionable_1 && <History record={props.record} />}</RightPanel>
    </SidedPanel>
  );
});
