/// ///////////////////////////////////////////////////////////////////////////
//                                                                           //
// Popover is a dynamic window that appears as soon as mouse enters the      //
// "trigger" and disappears as soon as mouse leaves it.                      //
//                                                                           //
// Usage:                                                                    //
//                                                                           //
//    <Popover>                                                              //
//      <Popover.Trigger>                                                    //
//        <Icon class="component-with-an-icon" />                            //
//      </Popover.Trigger>                                                   //
//      <Popover.Window                                                      //
//        title="An optional text to display at the top of window"           //
//      >                                                                    //
//        <span>Some text to display at the body</span>                      //
//        <Popover.Footer>                                                   //
//          The text to display at the bottom.                               //
//        </Popover.Footer>                                                  //
//      </Popover.Window>                                                    //
//                                                                           //
/// ///////////////////////////////////////////////////////////////////////////
import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import PropTypesUtils from 'lib/propTypes-utils';

const GLOBAL_CONTAINER_ID = 'popovers-root-container';

const Trigger = (props) => props.children;
const WindowBody = (props) => <div className="body">{props.children}</div>;
const WindowFooter = (props) => <div className="footer">{props.children}</div>;

const Window = (props) => {
  let footer;
  const body = React.Children.map(props.children, (child) => {
    if (child && child.type === WindowFooter) {
      if (footer === undefined) {
        footer = child;
      }
      return null;
    }
    return child;
  });

  return (
    <>
      {props.title && <div className="header">{props.title}</div>}
      <WindowBody>{body}</WindowBody>
      {footer}
    </>
  );
};

Window.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const WindowManager = (props) => {
  if (!props.show) {
    return null;
  }
  const element = (
    <div
      className="popover"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={{ position: 'absolute', top: props.positionY, left: props.positionX, zIndex: 10 }}
    >
      {props.children}
    </div>
  );
  return ReactDOM.createPortal(element, document.getElementById(GLOBAL_CONTAINER_ID));
};

export default class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggered: false,
      mouseEnteredPopoverWindow: false,
      positionX: null,
      positionY: null,
    };
  }

  getPosition = () => {
    let positionX = this.state.positionX;
    let positionY = this.state.positionY;
    if (positionX === null) {
      const rect = this.ref.getBoundingClientRect();
      positionX = window.scrollX + rect.left;
      positionY = window.scrollY + rect.top;
    }
    return [positionX, positionY];
  };

  handleMouseEnterAndLeavePopoverWindow = (e) => {
    this.setState((prevState) => ({
      triggered: prevState.triggered,
      mouseEnteredPopoverWindow: e.type === 'mouseenter',
      positionX: prevState.positionX,
      positionY: prevState.positionY,
    }));
  };

  handleMouseEnterAndLeaveTrigger = (e) => {
    this.setState((prevState) => {
      let positionX = prevState.positionX;
      let positionY = prevState.positionY;

      const triggered = e.type === 'mouseenter';
      if (triggered) {
        [positionX, positionY] = this.getPosition();
      }

      return {
        triggered: triggered,
        mouseEnteredPopoverWindow: prevState.mouseEnteredPopoverWindow,
        positionX: positionX,
        positionY: positionY,
      };
    });
  };

  render() {
    const children = React.Children.toArray(this.props.children);

    let trigger;
    let popover;

    if (children[0].type === Trigger) {
      trigger = children[0];
      popover = children[1];
    } else {
      trigger = children[1];
      popover = children[0];
    }

    return (
      <>
        <div
          onMouseEnter={this.handleMouseEnterAndLeaveTrigger}
          onMouseLeave={this.handleMouseEnterAndLeaveTrigger}
          ref={(ref) => {
            this.ref = ref;
          }}
        >
          {trigger}
        </div>
        <WindowManager
          show={this.state.mouseEnteredPopoverWindow || this.state.triggered}
          positionX={this.state.positionX}
          positionY={this.state.positionY}
          onMouseEnter={this.handleMouseEnterAndLeavePopoverWindow}
          onMouseLeave={this.handleMouseEnterAndLeavePopoverWindow}
        >
          {popover}
        </WindowManager>
      </>
    );
  }
}

Popover.propTypes = {
  children: PropTypesUtils.array(2, [Trigger, Window], true).isRequired,
};

Popover.Trigger = Trigger;
Popover.Window = Window;
Popover.WindowBody = WindowBody;
Popover.WindowFooter = WindowFooter;

export const PopoversRootContainer = () => <div id={GLOBAL_CONTAINER_ID} />;
