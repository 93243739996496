import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { HOST_APP_TYPE } from 'Constants';
import Input from 'components/forms/Input';
import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import Item from 'components/Item';
import { LeftPanel, RightPanel, SidedPanel } from 'components/layout/Panels';
import { Spinner } from 'components/Loader';
import { InstanceLink } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import RegisterHostsManagerS from 'pages/Instances/HostsManager/RegisterHostsManagerS';
var Step1 = inject('instance')(observer(function (props) {
    if (props.store.step !== '1') {
        return null;
    }
    if (props.store.creatingEntity) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { className: "form-box", onSubmit: function (e) {
                e.preventDefault();
            } },
            React.createElement(Input, { key: "name", store: props.store.name }),
            React.createElement("div", { className: "message-placeholder text-danger" }, props.store.error),
            React.createElement("p", null, " Advanced Options: "),
            React.createElement(Input, { key: "external-id", store: props.store.externalID }),
            React.createElement(Input, { key: "description", store: props.store.description })),
        props.store.creatingEntity && (React.createElement("div", { className: "notification ntf-wait" },
            React.createElement(Item, null))),
        React.createElement("div", { className: "steps-action" },
            React.createElement("button", { type: "button", className: "btn-primary", onClick: function () {
                    props.store.tryCreate(props.instance.Applications);
                }, disabled: props.store.creatingEntity || !props.store.name.isDone() }, "Next"),
            React.createElement(InstanceLink, { to: "/hosts-managers", className: "btn btn-default" }, "Cancel"))));
}));
var Step2 = inject('instance', 'notifications')(observer(function (props) {
    if (props.store.step !== '2') {
        return null;
    }
    var value = [
        '# ITLook config with access key, keep it safe',
        "export ITLOOK_CONFIG=\"".concat(props.store.secret, "\""),
        '',
        '# Run hosts manager using docker',
        "docker run -d -e ITLOOK_CONFIG=${ITLOOK_CONFIG} ".concat(process.env.HOST_APP_URL, " itlook-host-app"),
    ].join('\n');
    var msg = 'A new hosts manager is successfully created.';
    var onSuccess = function () {
        if (props.notifications) {
            props.notifications.success(msg);
        }
        props.instance.Applications.fetch();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Run application:"),
        React.createElement(SimpleTextViewer, { syntax: Syntaxes.bash, value: value }),
        React.createElement("div", { className: "steps-action" },
            React.createElement(InstanceLink, { className: "btn btn-primary", to: "/hosts-managers?success=".concat(msg), onClick: onSuccess }, "Done"))));
}));
// this step doesn't exist yet
var HeaderStepItem = observer(function (props) {
    var className = 'disabled';
    if (props.step < props.store.step) {
        className = 'done';
    }
    else if (props.step === props.store.step) {
        className = 'current';
    }
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "sign" }),
        React.createElement("div", null, props.children)));
});
var Description = function () { return (React.createElement(React.Fragment, null,
    React.createElement("h4", null, "Run one hosts manager per every private network."),
    React.createElement("p", null, "Agent is going to connect securely to ITLook and allow access to hosts from that network."),
    React.createElement("p", null,
        "ITLook Agents are better and more secure alternative to jump boxes.",
        React.createElement("br", null),
        "To stay secure, without key rotation, we use one time token approach."))); };
export default observer(function () {
    var store = useState(RegisterHostsManagerS.create({
        applicationType: HOST_APP_TYPE,
    }))[0];
    return (React.createElement(ContainerBox, null,
        React.createElement(PageHeader, { to: "/hosts-managers", title: "Hosts Manager", subTitle: "Create new", documentTitle: "Hosts Manager", instanceLink: true }),
        React.createElement(SidedPanel, { key: "main" },
            React.createElement(LeftPanel, null,
                React.createElement("div", { className: "steps" },
                    React.createElement(HeaderStepItem, { store: store, step: "1" },
                        React.createElement("span", null, "1. Create Manager")),
                    React.createElement(HeaderStepItem, { store: store, step: "2" },
                        React.createElement("span", null, "2. Run it!"))),
                React.createElement(Step1, { store: store }),
                React.createElement(Step2, { store: store })),
            React.createElement(RightPanel, null,
                React.createElement(Description, null)))));
});
