import { getParent, types } from 'mobx-state-tree';

import { UnexpectedErrorMessage } from 'Constants';
import requests from 'lib/requests';

const TransportLayer = types.model('TransportLayer', {}).actions((self) => ({
  findAuthToken(url) {
    if (url.startsWith('/m')) {
      return getParent(self).Profile.token;
    }
    return getParent(self).Instances.getCurrent().token;
  },

  execMethod(method, { url, query, body, onFailure, onSuccess, onFinish, onCrash }) {
    const args = {
      url,
      query,
      authToken: self.findAuthToken(url),
      onSuccess,
      onFailure: (response, errors) => self.onFailure(url, response, errors, onFailure),
      onFinish,
      onCrash: (response, errors) => self.onCrash(response, errors, onCrash),
    };
    if (body) {
      args.body = body;
    }
    return method(args);
  },

  get({ url, query, onFailure, onSuccess, onFinish, onCrash } = {}) {
    self.execMethod(requests.GET, { url, query, onFailure, onSuccess, onFinish, onCrash });
  },
  post({ url, query, body, onFailure, onSuccess, onFinish, onCrash } = {}) {
    self.execMethod(requests.POST, { url, query, body, onFailure, onSuccess, onFinish, onCrash });
  },
  put({ url, query, body, onFailure, onSuccess, onFinish, onCrash } = {}) {
    self.execMethod(requests.PUT, { url, query, body, onFailure, onSuccess, onFinish, onCrash });
  },
  delete({ url, query, body, onFailure, onSuccess, onFinish, onCrash } = {}) {
    self.execMethod(requests.DELETE, { url, query, body, onFailure, onSuccess, onFinish, onCrash });
  },
  onFailure(requestUrl, response, errors, onFailure) {
    if (!onFailure) {
      getParent(self).Profile.globalOnFailure(response, errors, onFailure);
    } else {
      onFailure(response, errors);
    }
  },
  onCrash(response, errors, onCrash) {
    if (!onCrash) {
      console.trace(UnexpectedErrorMessage);
      console.error(response);
      console.error(errors);
      getParent(self).Notifications.error(UnexpectedErrorMessage);
    } else {
      onCrash(response, errors);
    }
  },
}));

export default TransportLayer;
