import { types } from 'mobx-state-tree';

export const AnySimpleType = types.union(
  types.boolean,
  types.integer,
  types.number,
  types.string,
  types.undefined,
  types.null
);

export const JSONType = types.union(
  AnySimpleType,
  types.array(types.late(() => JSONType)),
  types.map(types.late(() => JSONType))
);

export const RootModelPart = types.model('Root_1Part', {});
