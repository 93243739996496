import PropTypes from 'prop-types';

const chainPropTypes = (...validators) => {
  if (process.env.NODE_ENV === 'production') {
    return () => null;
  }

  return (...args) => {
    let error;
    validators.forEach((validator) => {
      if (error === undefined || error === null) {
        error = validator(...args);
      }
    });
    return error;
  };
};

const lengthValidator = (length) => {
  return (props, propName, componentName) => {
    if (props[propName] && props[propName].length !== length) {
      return new Error(
        `The array at \`${propName}\` supplied to \`${componentName}\` has ` +
          `invalid length \`${props[propName].length}\`, expected \`${length}\`.`
      );
    }
  };
};

// NOTE(andreykurilin): there is builtin `PropTypes.element` validator, but it doesn't validate the type of the element...
const elementOfType = (...types) => {
  const namesString = types.map((t) => (t.name ? t.name : t)).join('`, `');

  return (props, propName, componentName, location, propFullName) => {
    let error;
    const prop = props[propName];
    if (!types.some((t) => t === prop.type)) {
      const actualTypeName = prop.type.name ? prop.type.name : prop.type;
      error = new Error(
        `Invalid ${location} \`${propFullName}\` of type \`${actualTypeName}\`` +
          `supplied to \`${componentName}\`, expected instance of \`${namesString}\`.`
      );
    }
    return error;
  };
};

const typesUniquenessValidator = (props, propName, componentName) => {
  const types = {};
  props[propName].forEach((item) => {
    if (item !== null && item !== undefined) {
      if (!types.hasOwnProperty(item.type)) {
        types[item] = 1;
      } else {
        types[item] += 1;
      }
    }
  });

  let error;
  Object.entries(types).forEach(([typeName, count]) => {
    if (!error && count > 1) {
      error = new Error(
        `The array at \`${propName}\` supplied to \`${componentName}\` has ` +
          `invalid items. Element of type \`${typeName}\` appears more than once.`
      );
    }
  });
  return error;
};

const arrayValidator = (length, types, typesUniqueness) => {
  let upstreamArrayValidator;
  const validators = [];
  if (length !== undefined) {
    validators.push(lengthValidator(length));
  }

  if (types !== undefined) {
    upstreamArrayValidator = PropTypes.arrayOf(elementOfType(...types));
  } else {
    upstreamArrayValidator = PropTypes.array;
  }

  if (typesUniqueness) {
    validators.push(typesUniquenessValidator);
  }

  const typeChecker = chainPropTypes(upstreamArrayValidator, ...validators);
  typeChecker.isRequired = chainPropTypes(upstreamArrayValidator.isRequired, ...validators);

  return typeChecker;
};

export default {
  chainPropTypes: chainPropTypes,
  lengthValidator: lengthValidator,
  elementOfType: elementOfType,
  typesUniquenessValidator: typesUniquenessValidator,
  array: arrayValidator,
};
