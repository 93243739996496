import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { inject, observer } from 'mobx-react';

import moment from 'moment';

import { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import Icon from 'components/Icon';
import { InventorySearchLink, RecordLink } from 'components/InventoryLinks';
import Table, { TableCell, TableRow } from 'components/Table';

import { useStores } from 'stores/Store';

const ReferenceField = (props) => {
  const data = props.data;
  if (Array.isArray(data)) {
    const items = data.map((id) => {
      return (
        <li key={id}>
          <RecordLink recordId={id} />
        </li>
      );
    });
    return (
      <ul className="btn-list" key={items}>
        {items}
      </ul>
    );
  }
  return <RecordLink recordId={data} />;
};

const SearchIcon = inject('instance')(
  observer((props) => {
    const navigate = useNavigate();

    const onClick = () => {
      navigate(`/i/${props.instance.name}${props.url}`);
    };

    return <Icon className="search-item" onClick={onClick} />;
  })
);

const RecordFieldRow = observer((props) => {
  const { store } = useStores();
  const loaded = store.Models.loaded;
  useEffect(() => {
    if (!store.Models.loaded && !store.Models.loading) {
      store.Models.fetch();
    }
  }, [loaded]);

  const getKeySpec = () => {
    const model = store.Models.getByIdentifier(props.modelName);
    if (!loaded || !model) {
      return null;
    }
    return model.definition.obj.fields[props.fieldName];
  };

  const isReference = () => {
    const spec = getKeySpec();
    return (
      spec &&
      (spec.type === 'reference' ||
        (spec.type === 'array' && (spec.items === 'reference' || spec.items.type === 'reference')))
    );
  };

  const isJSONText = () => {
    const spec = getKeySpec();
    return spec && spec.type === 'text' && spec.contentType === 'json';
  };

  const isTimestamp = () => {
    const spec = getKeySpec();
    return spec && spec.type === 'timestamp';
  };

  let formattedValue;

  let disableSearchIcon = false;

  if (isReference()) {
    if (props.value && props.value.length) {
      formattedValue = <ReferenceField key={props.fieldName} data={props.value} />;
      disableSearchIcon = true;
    }
  } else if (Array.isArray(props.value)) {
    disableSearchIcon = true;
    formattedValue = props.value.map((item) => (
      <li key={`${props.fieldName}-${item}`}>
        <InventorySearchLink
          modelname={props.modelName}
          query={`${props.modelName}.${props.fieldName}=='${item}'`}
          title={item}
          className="btn btn-text btn-default btn-small"
        >
          {item}
        </InventorySearchLink>
      </li>
    ));
    formattedValue = <ul className="btn-list"> {formattedValue} </ul>;
  } else if (typeof props.value === 'boolean') {
    formattedValue = props.value.toString();
  } else if (isTimestamp()) {
    formattedValue = moment(props.value).format('YYYY-MM-DD hh:mm:ss');
  } else if (isJSONText()) {
    disableSearchIcon = true;
    formattedValue = (
      <SimpleTextViewer
        syntax={Syntaxes.json}
        value={JSON.stringify(JSON.parse(props.value), null, 4)}
        excludeActionsButtons
      />
    );
  } else {
    formattedValue = props.value;
  }

  let searchIcon;
  if (disableSearchIcon) {
    searchIcon = null;
  } else {
    const valueQuery = typeof props.value === 'number' ? props.value : `'${props.value}'`;
    const queryLink = `/search?q=${encodeURIComponent(`${props.modelName}.${props.fieldName}==${valueQuery}`)}`;
    searchIcon = <SearchIcon url={queryLink} />;
  }

  return (
    <TableRow>
      <TableCell key={`cell-span-${props.fieldName}`} />
      <TableCell key={`cell-span2-${props.fieldName}`} />
      <TableCell key={`cell-title-${props.fieldName}`}>{props.fieldName}</TableCell>
      <TableCell key={`cell-${props.fieldName}`}>{formattedValue}</TableCell>
      <TableCell key={`cell-link-${props.fieldName}`}>{searchIcon}</TableCell>
    </TableRow>
  );
});

const ModelNameRow = (props) => {
  const { store } = useStores();

  return (
    <TableRow key={`row-header-${props.modelName}`}>
      <TableCell colSpan={5}>
        <div
          className="entity-type entity-icon"
          style={{ background: `url(${store.Models.getPicture(props.modelName)})` }}
        />
        <b>{props.modelName}</b>
      </TableCell>
    </TableRow>
  );
};

@inject('store')
@observer
export default class extends React.Component {
  STATIC_FIELDS = ['@model', '@version'];

  componentDidMount() {
    if (!this.props.store.Models.loaded && !this.props.store.Models.loading) {
      this.props.store.Models.fetch();
    }
  }

  getNameForRow = (row) => {
    let partPriority;
    if (row.props.modelName.startsWith('std::types/')) {
      partPriority = 3;
    } else if (row.props.modelName.startsWith('std::')) {
      partPriority = 2;
    } else {
      partPriority = 1;
    }

    let endPart;
    if (row.type === ModelNameRow) {
      // no matter of sort order, we should put model title in the top
      endPart = '1';
    } else {
      endPart = `2-${row.props.fieldName}`;
    }
    return `${partPriority}-${row.props.modelName}-${endPart}`;
  };

  sortRows = (rowA, rowB) => {
    const fullNameA = this.getNameForRow(rowA);
    const fullNameB = this.getNameForRow(rowB);

    const result = fullNameA.localeCompare(fullNameB);

    return result;
  };

  render() {
    const rows = [];
    Object.entries(this.props.record.data.toJSON()).forEach(([modelName, partValue]) => {
      if (!this.STATIC_FIELDS.includes(modelName)) {
        rows.push(
          <ModelNameRow key={modelName} modelName={modelName} />,
          ...Array.from(
            Object.entries(partValue).map(([fieldName, value]) => (
              <RecordFieldRow
                key={`${modelName}.${fieldName}`}
                modelName={modelName}
                fieldName={fieldName}
                value={value}
              />
            ))
          )
        );
      }
    });

    rows.sort(this.sortRows);

    return (
      <Table headers={[]} onSortChange={() => {}} noHover selected="field">
        {rows}
      </Table>
    );
  }
}
