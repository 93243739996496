import { types } from 'mobx-state-tree';

import Store from 'stores/Store';

const Swagger = types
  .model('Swagger', {
    url: types.string,
    loading: true,
  })
  .volatile(() => ({
    original_history_push_state: window.history.pushState,
    specData: {},
    tokenProvider: () => null,
  }))
  .views((self) => ({
    get version() {
      if (!self.loading && self.specData !== null) {
        if (self.specData.hasOwnProperty('swagger')) {
          return 2;
        }
        // openapi
        return 3;
      }
      return null;
    },
    get baseURL() {
      if (self.version === 2) {
        return self.specData.basePath;
      }
      if (self.version === 3) {
        // NOTE(andreykurilin: `servers` field of openapi spec includes baseURL
        //  for all apis. If it is missing, it defaults to [{"url": "/"}]
        const servers = self.specData.servers || [{ url: '/' }];
        return servers[0].url;
      }
      return null;
    },
  }))
  .actions((self) => ({
    setTokenProvider(tokenProvider) {
      self.tokenProvider = tokenProvider;
    },
    fetch() {
      Store.TransportLayer.get({
        url: self.url,
        onSuccess: self.fetchSuccess,
      });
    },
    fetchSuccess(response, response_data) {
      // FIXME(andreykurilin): include `/i` at Bizbook API, so we have
      //    a correct `openapi.json` spec that can be reused without patching
      if (self.url.startsWith('/i')) {
        response_data.servers = [{ url: '/i/' }];
      }

      // this terrible hack
      self.specData = response_data;
      self.loading = false;
    },
    requestPatcher(request) {
      // TODO(boris-42): For some reason headers are broken and will be always
      //                 undefined, address this later
      if (request.headers.Authorization === undefined) {
        request.headers.Authorization = `Bearer ${self.tokenProvider()}`;
      }
      return request;
    },
    patch_deeplinking() {
      window.history.pushState = function pushState(state, title, url) {
        if (state === null && title === null && url.startsWith('#/')) {
          url = window.location.pathname + url;
        }
        self.original_history_push_state.apply(this, [state, title, url]);
      };
    },
    unpatch_deeplinking() {
      window.history.pushState = self.original_history_push_state;
    },
  }));

export default Swagger;
