import React from 'react';

import { inject, observer } from 'mobx-react';

import { InstanceStates } from 'Constants';
import { withNavigate } from 'lib/router-helper';

import { ChartLine, ChartView, DeletedActiveChart, RecordsByAdminsChart, RecordsByModelsChart } from 'components/Chart';
import * as ContentHeader from 'components/ContentHeader';
import { Spinner } from 'components/Loader';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { ContainerBox, PageHeader } from 'components/Page';
import { ExistsOnlyForAdmin } from 'pages/NotFound';

const InstanceStatusMap = {
  requested: 'text-warning',
  ready: 'text-success',
  deleting: 'text-danger',
};

@inject('instance')
@observer
export class RecordsByAppsChart extends React.Component {
  componentDidMount() {
    if (!this.props.instance.Applications.loading) {
      this.props.instance.Applications.fetch();
    }
  }

  render() {
    if (!this.props.instance.DetailedStats.loaded) {
      return <Spinner />;
    }
    const chart = this.props.instance.DetailedStats.getChartByName('records_by_apps');

    const data = [];
    Array.from(chart.data.keys()).forEach((key) => {
      // NOTE: temporary ignore record for the current instance until will be handled on the backend
      if (key !== this.props.instance.id) {
        const record = this.props.instance.InventoryRecords.getById(key);
        const name = record.loaded ? record.inventory_1.displayName : key;
        data.push({ name: name, value: chart.data.get(key) });
      }
    });

    return <ChartView title="Records by Applications" data={data} threshold={10} />;
  }
}

@inject('instance')
@observer
export class Charts extends React.Component {
  componentDidMount() {
    // no matter whether we already loaded or not items, let's refresh the list
    if (!this.props.instance.DetailedStats.loading) {
      this.props.instance.DetailedStats.fetch();
    }
  }

  render() {
    const stats = this.props.instance.DetailedStats;
    if (!stats.loaded) {
      return <Spinner />;
    }
    return (
      <ChartLine>
        <RecordsByModelsChart chart={stats.getChartByName('records_by_models')} loaded={stats.loaded} />
        <RecordsByAppsChart />
        <RecordsByAdminsChart chart={stats.getChartByName('records_by_admin')} loaded={stats.loaded} />
        <DeletedActiveChart chart={stats.getChartByName('deleted_vs_active')} loaded={stats.loaded} />
      </ChartLine>
    );
  }
}

@withNavigate
@inject('instance', 'store')
@observer
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { confirmDelete: false };
  }

  askConfirmation = () => {
    this.setState({ confirmDelete: true });
  };

  deleteInstance = () => {
    this.props.store.Instances.delete(this.props.instance.id, () => {
      this.props.instance.updateProperties({ status: InstanceStates.Deleting });
      this.props.navigate('/');
    });
  };

  closeModalWindow = () => {
    this.setState({ confirmDelete: false });
  };

  render() {
    return (
      <ExistsOnlyForAdmin>
        <ContainerBox>
          <PageHeader title="Instance" documentTitle={`${this.props.instance.name} settings`} />
          <ContentHeader.Container>
            <ContentHeader.Column>
              <ContentHeader.Item>
                <span>ID:</span> {this.props.instance.id}
              </ContentHeader.Item>
              <ContentHeader.Item>
                <span>Name:</span> {this.props.instance.name}
              </ContentHeader.Item>
              <ContentHeader.Item>
                <span>Display Name:</span> {this.props.instance.displayName}
              </ContentHeader.Item>
            </ContentHeader.Column>

            <ContentHeader.Column large>
              <ContentHeader.Item>
                <span>Requested by:</span> {this.props.instance.requestedBy}
              </ContentHeader.Item>
              <ContentHeader.Item>
                <span>Status:</span>
                <b className={InstanceStatusMap[this.props.instance.status]}>{this.props.instance.status}</b>
              </ContentHeader.Item>
            </ContentHeader.Column>

            <ContentHeader.Column large>
              <ContentHeader.Item>
                <span>Region:</span> {this.props.instance.region}
              </ContentHeader.Item>
              <ContentHeader.Item>
                <span>Zone:</span> {this.props.instance.zone}
              </ContentHeader.Item>
            </ContentHeader.Column>

            <ContentHeader.Column>
              <button type="button" className="btn-danger" onClick={this.askConfirmation}>
                Delete instance
              </button>
            </ContentHeader.Column>
          </ContentHeader.Container>
          {this.state.confirmDelete && (
            <ConfirmationAboutDeleteWindow
              onClose={this.closeModalWindow}
              onCancel={this.closeModalWindow}
              onDelete={this.deleteInstance}
            />
          )}
          <Charts />
        </ContainerBox>
      </ExistsOnlyForAdmin>
    );
  }
}
