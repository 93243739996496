import React from 'react';

import { PageHeader } from 'components/Page';
import { TabView, TabViewContent, TabViewsSwitcher } from 'components/TabsPageView';
import OrganizationInfo from 'pages/Organization/OrganizationInfo';
import OrganizationSettings from 'pages/Organization/Settings';
import UsersList from 'pages/Organization/Users/UsersList';

export default () => (
  <TabViewsSwitcher>
    {/* TODO: We should be able somehow to make page header dependent on active tab view */}
    <PageHeader title="Administration" />
    <TabView title="Organization" key="info" url="info">
      <TabViewContent>
        <OrganizationInfo />
      </TabViewContent>
    </TabView>
    <TabView title="Users" key="users" url="users">
      <TabViewContent>
        <UsersList />
      </TabViewContent>
    </TabView>

    {!process.env.DISABLE_NOT_WORKING && (
      <TabView title="Settings" key="settings" url="settings" onlyAdmin>
        <TabViewContent>
          <OrganizationSettings />
        </TabViewContent>
      </TabView>
    )}
  </TabViewsSwitcher>
);
