import React from 'react';
import { UUID4 } from 'lib/uuid-utils';
import { SimpleFormItem } from 'components/forms/FormItem';
export var FormItemWithLabel = function (props) { return (React.createElement(SimpleFormItem, { className: props.className, onClick: props.onClick },
    React.createElement("div", { className: "item-container" },
        (props.label || props.description) && (React.createElement("div", { className: "name-container" },
            props.label && React.createElement("div", { className: "name" }, props.label),
            props.description && React.createElement("div", { className: "help-text" }, props.description))),
        props.children))); };
var Switcher = function (_a) {
    var label = _a.label, description = _a.description, checked = _a.checked, onChange = _a.onChange, itemID = _a.itemID, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    itemID = itemID || UUID4();
    return (React.createElement(FormItemWithLabel, { label: label, description: description },
        React.createElement("div", { className: "btn-group switcher" },
            React.createElement("div", { className: "btn-group-item" },
                React.createElement("input", { id: itemID, type: "checkbox", className: "check", onClick: onChange, 
                    // add dummy onChange handler to suppress warning at browser console
                    onChange: function () { }, checked: checked, disabled: disabled }),
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                React.createElement("label", { className: "btn", htmlFor: itemID })))));
};
export default Switcher;
