import React, { useState } from 'react';

import { inject, observer } from 'mobx-react';

import Input from 'components/forms/Input';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
import { ContainerBox, PageHeader } from 'components/Page';

import { PasswordChangeForm } from 'stores/Profile';

const UserPassword = inject(
  'store',
  'profile'
)(
  observer((props) => {
    const [form] = useState(PasswordChangeForm.create({}));
    const user = props.profile.user;
    return (
      <div className="user-profile-container">
        <div className="header">
          <div className="avatar" />
          <div className="name">
            <span>{user.fullName}</span>
            <span>{user.role}</span>
          </div>
        </div>
        <form>
          <div className="form-box">
            <Input key="current" store={form.current} />
            <Input key="new-password1" store={form.newPassword1} />
            <Input key="new-password2" store={form.newPassword2} />

            <div className={(form.error && 'help-text text-danger') || 'help-text'}>{form.message || form.error}</div>

            <div className="form-item">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!form.filled || form.loading}
                onClick={() => {
                  form.submitNewPassword(user.email);
                }}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  })
);

export default inject('profile')(
  observer((props) => {
    const { profile } = props;
    const user = profile.user;
    if (user == null) {
      return '';
    }
    return (
      <ContainerBox>
        <PageHeader title="Profile" />
        <SidedPanel>
          <LeftPanel>
            <UserPassword />
          </LeftPanel>
        </SidedPanel>
      </ContainerBox>
    );
  })
);
