import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Input, { EmailInputStore, InputStore, NoDisplayError } from 'components/forms/Input';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
export default inject('org')(observer(function (props) {
    var orgName = InputStore.create({
        label: 'Name',
        disabled: true,
        error: NoDisplayError.create({}),
    });
    var orgDisplayName = InputStore.create({
        label: 'Display name',
        disabled: true,
        error: NoDisplayError.create({}),
    });
    var ownerEmail = EmailInputStore.create({
        label: 'Owner email',
        disabled: true,
        error: NoDisplayError.create({}),
    });
    useEffect(function () {
        if (!props.org.Info.loading) {
            props.org.Info.fetch();
        }
    }, []);
    useEffect(function () {
        if (props.org.Info.name !== null) {
            orgName.setValue(props.org.Info.name);
            orgDisplayName.setValue(props.org.Info.displayName);
            ownerEmail.setValue(props.org.Info.owner || '');
        }
    }, [props.org.Info.name]);
    return (React.createElement(SidedPanel, null,
        React.createElement(LeftPanel, null,
            React.createElement("form", { className: "form-box" },
                React.createElement(Input, { key: "name", store: orgName }),
                React.createElement(Input, { key: "displayName", store: orgDisplayName }),
                React.createElement(Input, { key: "email", store: ownerEmail })))));
}));
