/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid unpinned">
        <div className="header">
          <div className="headerbar">
            <div className="headerbar-container">
              <a href="home.html" className="icon home" />
              <div className="global-search">
                <div className="dynamic-dropdown" style={{ display: 'none' }}>
                  <div className="form-box">
                    <div className="form-item">
                      <select>
                        <option selected>Select</option>
                        <option value="1">Item 1</option>
                        <option value="2">Item 2</option>
                        <option value="3">Item 3</option>
                        <option value="4">Item 4</option>
                        <option value="5">Item 5</option>
                        <option value="6">Item 6</option>
                        <option value="7">Item 7</option>
                        <option value="8">Item 8</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="dynamic-search">
                  <div className="form-box">
                    <div className="form-item search-button header-search">
                      <div className="item-container">
                        <input type="text" id="form-field" />
                        <div className="button-container">
                          <div className="button-container-item">
                            <button type="submit" className="search" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="autocomplete-container" style={{ display: 'none' }}>
                    <div className="autocomplete">
                      <form>
                        <div className="form-box">
                          <fieldset className="fieldset">
                            <div className="checkbox bounce">
                              <input type="checkbox" id="check-option-11" value="1" />
                              <svg viewBox="0 0 21 21">
                                <polyline points="5 10.75 8.5 14.25 16 6" />
                              </svg>
                              <label htmlFor="check-option-11">Hide Deleted Items</label>
                            </div>
                          </fieldset>
                        </div>
                      </form>
                      <form>
                        <div className="form-box-line">
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                All k8s Nodes
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                My Resources
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <h4>Resent Search Query</h4>
                      <ul className="autocomplete-results">
                        <li>Boris</li>
                        <li>Boris Bullet Dodger</li>
                        <li>Boris Brejcha</li>
                        <li>Boris Pavlovich</li>
                        <li>Boris Johnson</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="icon helper">
                <div className="hint" style={{ display: 'none' }}>
                  <div className="header">Title</div>
                  <div className="body">
                    <b>Comparison Operators:</b>
                    <p> &gt;, &lt;, &gt;=, &lt;=, ==, != (or &gt;&gt;)</p>
                    <b>Special Operators:</b>
                    <p> %= (fuzzy search), ~= (regex match)</p>
                    <b>Array values operators:</b>
                    <p>IN and CONTAINS</p>
                    <b>Value Types</b>
                    <b>Strings:</b>
                    <p>'abcdef'</p>
                    <b>Integers:</b>
                    <p>1, 5, 1050</p>
                    <b>Floats:</b>
                    <p>1.5, 20.05, 0.33</p>
                    <b>Arrays:</b>
                    <p>[1, 2, 3, 10.5]</p>
                    <b>Functions-Values:</b>
                    <p>
                      id('some_value') returns arrays of @id(s) of records that match std::types/Inventory:1.identity ==
                      'some_value'
                    </p>
                    <b>Function Statements:</b>
                    <p>
                      inherits('some-model-name') filter results to return only records that inherit 'some-model-name'
                    </p>
                    <p>
                      isSet('some-attr-name')/isNotSet('some-attr-name') filter results to return only records that has
                      or hasn't 'some-attr-name' attribute
                    </p>
                    <b>Complex queries:</b>
                    <p>One can use AND and OR to connect multiple statements</p>
                    <b>Brackets:</b>
                    <p>( ) - first executed in brackets</p>
                  </div>
                  <div className="footer">
                    <button type="button" className="btn btn-default">
                      Close
                    </button>
                  </div>
                </div>
              </div>

              <a className="spacer" />

              <a href="#">Organization Name</a>
              <a href="models.html" className="icon models" title="Models" />
              <span className="divider" />
              <a href="#" className="icon notification badge" title="Notifications" style={{ display: 'none' }} />
              <a href="#" className="icon user" title="User Profile" />
              <a href="login.html" className="icon logout" title="Logout" />
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div className="brand-box">
            <div className="logo" />
            <div className="pin" />
          </div>
          <nav>
            <ul>
              <li>
                <a href="#" className="dashboard">
                  <span>Dashboard</span>
                </a>
              </li>
              <li className="active">
                <a href="#" className="hosts">
                  <span>Hosts</span>
                </a>
              </li>
              <li>
                <a href="#" className="containers">
                  <span>Containers</span>
                </a>
              </li>
              <li>
                <a href="#" className="scripts">
                  <span>Scripts</span>
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="apps.html" className="agents">
                  <span>Agents</span>
                </a>
              </li>
              <li>
                <a href="#" className="devs">
                  <span>Devs Only</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content">
          <div className="container-box">
            <div className="page-headline">
              <div className="title">
                <span>Hosts</span>
              </div>
            </div>
            <div className="empty-message-container">
              <div className="add-host-cover" />
              <div className="welcome-message">The first step in getting started is to add a host.</div>
              <button type="button" className="btn btn-primary">Add New Host</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
