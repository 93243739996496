var _a;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Icon from 'components/Icon';
import { ScriptPart_1 } from 'stores/Instances/InventoryRecords';
var controlsPerModel = (_a = {},
    _a[ScriptPart_1.name] = function (instance, record) { return "/i/".concat(instance.name, "/scripts/edit?id=").concat(record.id); },
    _a);
export default inject('instance')(observer(function (props) {
    var navigate = useNavigate();
    var redirectURL = '';
    Object.keys(controlsPerModel).some(function (modelName) {
        if (props.record.data.has(modelName)) {
            redirectURL = controlsPerModel[modelName](props.instance, props.record);
            return true;
        }
        return false;
    });
    if (!redirectURL) {
        return null;
    }
    return (React.createElement("button", { type: "button", className: "btn btn-default", onClick: function () { return navigate(redirectURL); } },
        React.createElement(Icon, { className: "edit" }),
        "Edit"));
}));
