import React, { useState } from 'react';

import { observer } from 'mobx-react';

import 'regenerator-runtime/runtime';

import { formatHostInfo } from 'lib/hosts';

import Icon from 'components/Icon';
import { FileDownloader, FileUploader } from 'pages/TerminalsModal/TerminalFileOperations';
import { TerminalSearch } from 'pages/TerminalsModal/TerminalSearch';
import { XTerm } from 'pages/TerminalsModal/XTerm';

const TerminalBody = observer((props) => {
  const { fileUploader } = props.session;

  return (
    <div
      className="terminal-body-wrapper"
      onDrop={fileUploader.handleDrop}
      onDragOver={fileUploader.onDragOver}
      onDragEnterCapture={fileUploader.onDragOver}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeaveCapture={fileUploader.onDragLeaveCapture}
    >
      {props.children}
    </div>
  );
});

const TerminalHeader = observer((props) => {
  const { session } = props;
  const { target } = session;

  const [showDownloader, setShowDownloader] = useState(false);

  const { hostname, userAtIP } = formatHostInfo(
    target.data.get('std::host/Host:1'),
    target.data.get('std::host/Container:1')
  );

  return (
    <>
      <div className="terminal-tools-container">
        <div className="terminal-session-info">
          Connected to: {target.container_1 && 'container'}
          {hostname} <span>{userAtIP}</span>
        </div>
        <div className="spacer" />
        <div className="terminal-tools-container">
          <TerminalSearch session={session} />
          <button
            type="button"
            className="btn btn-default btn-transparent btn-small download"
            onClick={() => setShowDownloader(true)}
          >
            <Icon className="terminal-download" />
            Download
          </button>
          <button
            type="button"
            className="btn btn-default btn-transparent btn-small upload"
            onClick={session.fileUploader.onClick}
          >
            <Icon className="terminal-upload" />
            {session.fileUploader.buttonLabel}
          </button>
        </div>
      </div>
      <div className="global-modals-placeholder">
        {showDownloader && (
          <FileDownloader fileDownloader={session.fileDownloader} onClose={() => setShowDownloader(false)} />
        )}
        <FileUploader fileUploader={session.fileUploader} onClose={() => setShowDownloader(false)} />
      </div>
    </>
  );
});

const TerminalTab = observer((props) => {
  const terminals = props.terminalsStore;
  const session = terminals.selectedSession;
  const { fileUploader } = session;

  const uploadHelper = fileUploader.dragActive ? (
    <div className="file-upload-zone" key="file-upload-zone">
      <div className="icon upload-file" />
      <p>To upload your file, please drag and drop it into this area.</p>
    </div>
  ) : null;

  return (
    <TerminalBody session={session}>
      <TerminalHeader session={session} />
      <div className="terminal-window">
        {uploadHelper}
        <XTerm session={session} />
      </div>
    </TerminalBody>
  );
});

export default TerminalTab;
