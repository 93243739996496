import React from 'react';

export const RightSideBar = (props) => (
  <div className={`rightbar-container${props.show === undefined || props.show ? ' rightbar-show' : ''}`}>
    {props.children}
  </div>
);

export const RightSideBarItem = (props) => (
  <div className={`rightbar-item ${props.className ? props.className : ''}`}>{props.children}</div>
);
