var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { types } from 'mobx-state-tree';
import { UserStates } from 'Constants';
import * as queryString from 'lib/query-string';
import { sortObjects } from 'lib/sort-utils';
import { InputStore, NoDisplayError } from 'components/forms/Input';
var defaultHeaders = [
    { title: 'Full Name', value: 'fullName' },
    { value: 'email' },
    { value: 'role' },
    { value: 'state' },
];
var defaultSortFieldsOrder = defaultHeaders.map(function (h) { return h.value; });
var UsersPageS = types
    .model('UsersPage', {
    filters: types.optional(types.map(types.boolean), { active: true, suspended: true, invited: true }),
    sortBy: 'fullName',
    sortBack: false,
    nameFilter: types.optional(InputStore, function () {
        return InputStore.create({
            formItemClassName: 'search',
            inputType: 'search',
            placeholder: 'Search',
            label: 'Search',
            error: NoDisplayError.create({}),
        });
    }),
    email: types.maybeNull(types.string),
    userIsAdmin: false,
    confirmDeleteForUser: types.maybeNull(types.string),
    userDeleteIsInProgress: false,
})
    .volatile(function () { return ({
    navigate: (function () { }),
    usersStore: undefined,
}); })
    .views(function (self) { return ({
    get showActive() {
        return self.filters.get('active');
    },
    get showSuspended() {
        return self.filters.get('suspended');
    },
    get showInvited() {
        return self.filters.get('invited');
    },
    get usersListURL() {
        var query = Object.fromEntries(self.filters);
        query.sort_back = self.sortBack;
        query.sort_by = self.sortBy;
        if (self.nameFilter.value !== '') {
            query.q = self.nameFilter.value;
        }
        if (self.email !== null) {
            query.selected = self.email;
        }
        return "/organization/users?".concat(queryString.stringify(query));
    },
    get allUsers() {
        if (self.usersStore === undefined) {
            return [];
        }
        var users = self.usersStore.list();
        users.sort(function (userA, userB) {
            return sortObjects(userA, userB, self.sortBy, defaultSortFieldsOrder, self.sortBack);
        });
        return users;
    },
    get users() {
        var _this = this;
        return this.allUsers.filter(function (user) {
            if (!((_this.showActive && user.state === UserStates.ACTIVE) ||
                (_this.showSuspended && user.state === UserStates.SUSPENDED) ||
                (_this.showInvited && user.state === UserStates.INVITED))) {
                return false;
            }
            if (self.nameFilter.value !== '') {
                var query = self.nameFilter.value.toLowerCase();
                var fullName = user.fullName.toLowerCase();
                return fullName.includes(query) || user.email.includes(query);
            }
            return true;
        });
    },
    get tableHeaders() {
        if (self.userIsAdmin) {
            return __spreadArray(__spreadArray([], defaultHeaders, true), [{ noselect: true, empty: true, value: '' }], false);
        }
        return defaultHeaders;
    },
}); })
    .actions(function (self) { return ({
    registerNavigator: function (func) {
        self.navigate = func;
    },
    registerUsersStore: function (store) {
        self.usersStore = store;
        if (!self.usersStore.loading) {
            self.usersStore.load();
        }
    },
    setIsAdmin: function (userIsAdmin) {
        self.userIsAdmin = userIsAdmin;
    },
    loadFromQuery: function (rawQueryString) {
        var query = queryString.parse(rawQueryString);
        if (query.search) {
            self.nameFilter.setValue(query.search);
        }
        if (query.sort_by) {
            self.sortBy = query.sort_by;
        }
        if (query.sort_back === 'true') {
            self.sortBack = true;
        }
        self.email = query.selected || null;
        ['active', 'invited', 'suspended'].forEach(function (f) {
            if (query[f] === 'false') {
                self.filters.set(f, false);
            }
        });
    },
    toggleFilter: function (filter) {
        self.filters.set(filter, !self.filters.get(filter));
    },
    onSortChange: function (value, sortBack) {
        self.sortBy = value;
        self.sortBack = sortBack;
        if (self.navigate) {
            self.navigate(self.usersListURL);
        }
    },
    search: function () {
        self.navigate(self.usersListURL);
    },
    setUserToShow: function (email) {
        if (self.email === email) {
            self.email = null;
        }
        else {
            self.email = email;
        }
    },
    setConfirmDeleteForUser: function (email) {
        self.confirmDeleteForUser = email;
    },
    setUserDeleteIsInProgress: function (value) {
        self.userDeleteIsInProgress = value;
    },
    deleteSelectedUser: function () {
        var _this = this;
        var end = function () {
            _this.setConfirmDeleteForUser(null);
            _this.setUserDeleteIsInProgress(false);
        };
        if (self.usersStore && self.confirmDeleteForUser) {
            self.userDeleteIsInProgress = true;
            self.usersStore.deleteUser(self.confirmDeleteForUser).then(end).catch(end);
        }
    },
}); });
export default UsersPageS;
