import React from 'react';

import { inject, observer } from 'mobx-react';

import PropTypes from 'prop-types';

import * as queryString from 'lib/query-string';

import { InstanceLink } from 'components/Nav';

export const getURLParams = (query, page, sortBy, reverseOrder) => {
  const params = {
    q: query,
    page: page || 1,
  };
  if (sortBy) {
    params.sortBy = sortBy;
  }
  if (reverseOrder !== undefined) {
    params.reverseOrder = reverseOrder;
  }
  return queryString.stringify(params);
};

export const RecordLink = inject('instance')(
  observer((props) => {
    const record = props.instance.InventoryRecords.getById(props.recordId);
    let title;
    if (record.loaded && !record.notFound && record.inventory_1) {
      title = record.inventory_1.displayName;
    } else {
      title = record.id;
    }
    const to = props.to || `/records/${record.id}`;

    let text = title;
    if (props.getText !== undefined) {
      text = props.getText(record);
    }

    let className;
    if (props.className === undefined) {
      className = `btn btn-text btn-primary ${props.isUser ? 'btn-avatar' : 'btn-small'}`;
    } else {
      className = props.className;
    }

    return (
      <InstanceLink to={to} title={title} className={className}>
        {text}
      </InstanceLink>
    );
  })
);

RecordLink.propTypes = {
  recordId: PropTypes.string.isRequired,
  to: PropTypes.string,
  getText: PropTypes.func,
  isUser: PropTypes.bool,
  className: PropTypes.string,
};

RecordLink.defaultProps = {
  isUser: false,
};

export const UserLink = inject('instance')(
  observer((props) => {
    const record = props.instance.InventoryRecords.getById(props.recordId);

    let title;
    let avatarLetters = '--';

    if (!record.loaded) {
      title = `Loading: ${record.id}`;
    } else if (record.notFound) {
      title = `Not found: ${record.id}`;
    } else {
      title = record.inventory_1.displayName;
      const firstName = record.data.get('std::system/User:1').get('firstName');
      const lastName = record.data.get('std::system/User:1').get('lastName');
      avatarLetters = `${firstName.charAt(0)}${lastName.charAt(0)}`;
    }

    return (
      <InstanceLink to={`/records/${record.id}`} title={title} className="btn btn-text btn-primary btn-avatar">
        {avatarLetters}
      </InstanceLink>
    );
  })
);

UserLink.propTypes = {
  recordId: PropTypes.string.isRequired,
};

export const InventorySearchLink = (props) => {
  let prefix;
  if (props.modelname && props.modelname.startsWith('std::host/Host:1')) {
    prefix = 'hosts';
  } else if (props.modelname && props.modelname.startsWith('std::code/Script:1')) {
    prefix = 'scripts';
  } else {
    prefix = 'search';
  }

  return <InstanceLink to={`/${prefix}?${getURLParams(props.query)}`} {...props} />;
};
