/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid deploy">
        <div className="float-message-placeholder">
          <div className="notification ntf-info close">
            <item>
              Have a nice day Dude!
            </item>
            <item className="btn-close" />
          </div>
        </div>

        <div className="content">

          <div className="laptop-box">
            <div className="laptop-progress"></div>
            <div className="laptop"></div>
          </div>

          <div className="laptop-msg">
            <div>Thank you for your patience.</div>
            <div>The deployment should finish in under a minute.</div>
            <div className="text-warning">The deployment is taking longer than expected. Please restart the process.</div>
          </div>

        </div>

      </div>
    );
  }
}
export default Page;
