import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withNavigate = (Component) => {
  const DummyComponent = (props) => {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  };

  return DummyComponent;
};

export const withLocation = (Component) => {
  const DummyComponent = (props) => {
    const location = useLocation();

    return <Component {...props} location={location} />;
  };

  return DummyComponent;
};

export const withParams = (Component) => {
  const DummyComponent = (props) => {
    const params = useParams();
    return <Component {...props} params={params} />;
  };

  return DummyComponent;
};
