/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid unpinned">
        <div className="header">
          <div className="headerbar">
            <div className="headerbar-container">
              <a href="home.html" className="icon home" />
              <div className="global-search">
                <div className="dynamic-dropdown" style={{ display: 'none' }}>
                  <div className="form-box">
                    <div className="form-item">
                      <select>
                        <option selected>Select</option>
                        <option value="1">Item 1</option>
                        <option value="2">Item 2</option>
                        <option value="3">Item 3</option>
                        <option value="4">Item 4</option>
                        <option value="5">Item 5</option>
                        <option value="6">Item 6</option>
                        <option value="7">Item 7</option>
                        <option value="8">Item 8</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="dynamic-search">
                  <div className="form-box">
                    <div className="form-item search-button header-search">
                      <div className="item-container">
                        <input type="text" id="form-field" />
                        <div className="button-container">
                          <div className="button-container-item">
                            <button type="submit" className="search" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="icon helper">
                <div className="hint" style={{ display: 'none' }}>
                  <div className="header">Title</div>
                  <div className="body">
                    <b>Comparison Operators:</b>
                    <p> &gt;, &lt;, &gt;=, &lt;=, ==, != (or &gt;&gt;)</p>
                    <b>Special Operators:</b>
                    <p> %= (fuzzy search), ~= (regex match)</p>
                    <b>Array values operators:</b>
                    <p>IN and CONTAINS</p>
                    <b>Value Types</b>
                    <b>Strings:</b>
                    <p>'abcdef'</p>
                    <b>Integers:</b>
                    <p>1, 5, 1050</p>
                    <b>Floats:</b>
                    <p>1.5, 20.05, 0.33</p>
                    <b>Arrays:</b>
                    <p>[1, 2, 3, 10.5]</p>
                    <b>Functions-Values:</b>
                    <p>
                      id('some_value') returns arrays of @id(s) of records that match std::types/Inventory:1.identity ==
                      'some_value'
                    </p>
                    <b>Function Statements:</b>
                    <p>
                      inherits('some-model-name') filter results to return only records that inherit 'some-model-name'
                    </p>
                    <p>
                      isSet('some-attr-name')/isNotSet('some-attr-name') filter results to return only records that has
                      or hasn't 'some-attr-name' attribute
                    </p>
                    <b>Complex queries:</b>
                    <p>One can use AND and OR to connect multiple statements</p>
                    <b>Brackets:</b>
                    <p>( ) - first executed in brackets</p>
                  </div>
                  <div className="footer">
                    <button type="button" className="btn btn-default">
                      Close
                    </button>
                  </div>
                </div>
              </div>

              <a className="spacer" />

              <a href="#">Organization Name</a>
              <a href="models.html" className="icon models" title="Models" />
              <span className="divider" />
              <a href="#" className="icon notification badge" title="Notifications" style={{ display: 'none' }} />
              <a href="#" className="icon user" title="User Profile" />
              <a href="login.html" className="icon logout" title="Logout" />
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div className="brand-box">
            <div className="logo" />
            <div className="pin" />
          </div>
          <nav>
            <ul>
              <li>
                <a href="#" className="dashboard">
                  <span>Dashboard</span>
                </a>
              </li>
              <li className="active">
                <a href="#" className="hosts">
                  <span>Hosts</span>
                </a>
              </li>
              <li>
                <a href="#" className="containers">
                  <span>Containers</span>
                </a>
              </li>
              <li>
                <a href="#" className="scripts">
                  <span>Scripts</span>
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="apps.html" className="agents">
                  <span>Agents</span>
                </a>
              </li>
              <li>
                <a href="#" className="devs">
                  <span>Devs Only</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content">
          <div className="container-box">

            <div className="page-headline">

              <div className="title">
                <span>
                  <a href="/i/main/hosts">Scripts</a>
                </span>
                <span>
                  <a href="/i/main/hosts">testing</a>
                </span>
                <span>hello-wait-hello.sh</span>
              </div>

              <button type="button" className="btn btn-primary">
                Just Example Button
              </button>
            </div>

            <div className="filters-box">
              <div className="form-box">
                <div className="form-item search">
                  <div className="item-container">
                    <input type="text" id="form-field" placeholder="Search" />
                    <label htmlFor="form-item">Search</label>
                  </div>
                </div>
              </div>

              <form className="form-box">
                <div className="btn-group">
                  <div className="btn-group-item">
                    <input type="checkbox" className="check" name="active" id="active" checked="" />
                    <label className="btn" htmlFor="active">
                      Item One
                    </label>
                  </div>

                  <div className="btn-group-item">
                    <input type="checkbox" className="check" name="suspended" id="suspended" checked="" />
                    <label className="btn" htmlFor="suspended">
                      Item Two
                    </label>
                  </div>

                  <div className="btn-group-item">
                    <input type="checkbox" className="check" name="invited" id="invited" checked="" />
                    <label className="btn" htmlFor="invited">
                      Item Three
                    </label>
                  </div>
                </div>
              </form>
            </div>

            <div className="details-container">
              <div className="details-item-box">
                <div>
                  <span>Staus:</span>
                  <b className="text-success">ok</b> - host is reachable
                </div>
              </div>
              <div className="details-item-box">
                <div>
                  <span>Description:</span>
                  Auto-registered host
                </div>
              </div>
              <div className="details-item-box">
                <div>
                  <span>Access:</span>
                  ubuntu@192.168.0.36
                </div>
              </div>
              <div className="details-item-box">
                <div>
                  <div className="actions-box">
                    <icon type="button" className="icon edit"></icon>
                    <icon type="button" className="icon delete"></icon>
                  </div>
                </div>
              </div>
              <div className="expander">
                <div className="caret"></div>
              </div>
            </div>

            <div className="tabs-container">
              <ul>
                <li className="active">
                  <a href="">Terminals</a>
                </li>
                <li>
                  <a href="">Containers</a>
                </li>
                <li>
                  <a href="">Sessions</a>
                </li>
                <li>
                  <a href="">Script Runs</a>
                </li>
              </ul>
              <div className="addition">
                <a>4732f5eb-d889-480d-965c-9fd790c5b47f</a>
                <div className="icon copy" />
              </div>
            </div>

            <div className="terminals-info-container">
              <div className="title-container">
                <div className="title">
                  <div>Active Connections</div>
                </div>
                <button type="button" className="btn btn-success">
                  Start New Session
                </button>
              </div>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div class="sortup">Name</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div style={{ textAlign: 'center' }}>You have no active connections.</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="terminals-info-container">
              <div className="title-container">
                <div className="title">
                  <div>Sessions History</div>
                </div>
              </div>
              <div class="table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div class="sortup">Activity</div>
                      </th>
                      <th>
                        <div>Description</div>
                      </th>
                      <th>
                        <div>Related To</div>
                      </th>
                      <th>
                        <div>Updated At</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="4">
                        <div style={{ textAlign: 'center' }}>No records to display.</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="title-container">
              <div className="title">
                <div>Etended Information Container</div>
              </div>
            </div>

            <div className="two-panels">

              <div className="left">
                <div className="extended-info-container">
                  <div className="extended-info-parameters-block">
                    <div className="extended-info-parameters-header">
                      <div className="icon history-record"></div>
                      <div>std::host/Session:1</div>
                    </div>
                    <div className="extended-info-parameters-body">
                      <div className="extended-info-parameters-body-item">
                        <div className="name">finishedAt</div>
                        <div className="value">2023-08-12 03:44:11</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">startedAt</div>
                        <div className="value">2023-08-12 03:33:52</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">state</div>
                        <div className="value">closed</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">target</div>
                        <div className="value"><a title="Fremont hosts" class="btn btn-text btn-primary btn-small" href="/i/main/records/8666c433-a4cb-49d5-afcc-d2007e2d2036">CI-3</a></div>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">trafficIn</div>
                        <div className="value">6</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">trafficOut</div>
                        <div className="value">218</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">user</div>
                        <div className="value"><a href="#">Bogdan Dudko</a></div>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">xtermCols</div>
                        <div className="value">187</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">xtermRows</div>
                        <div className="value">84</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">xtermShell</div>
                        <div className="value">default</div>
                        <icon className="icon search-item"></icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="extended-info-container">
                  <div className="extended-info-parameters-block">
                    <div className="extended-info-parameters-header">
                      <icon className="icon user"></icon>
                      <div>std::types/Versionable:1</div>
                    </div>
                    <div className="extended-info-parameters-body">
                      <div className="extended-info-parameters-body-item">
                        <div className="name">accessIP</div>
                        <div className="value"><a href="#">192.168.1.1</a></div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">accessUser</div>
                        <div className="value">test</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">description</div>
                        <div className="value">—</div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">identity</div>
                        <div className="value">
                          <a title="192.168.0.36" class="btn btn-text btn-default btn-small" href="#">192.168.0.36</a>
                          <a modelname="std::types/Inventory:1" query="std::types/Inventory:1.identity=='Auto-registered host'" title="Auto-registered host" class="btn btn-text btn-default btn-small" href="/i/main/search?page=1&amp;q=std%3A%3Atypes%2FInventory%3A1.identity%3D%3D%27Auto-registered%20host%27">Auto-registered host</a>
                          <a modelname="std::types/Inventory:1" query="std::types/Inventory:1.identity=='ci-3'" title="ci-3" class="btn btn-text btn-default btn-small" href="/i/main/search?page=1&amp;q=std%3A%3Atypes%2FInventory%3A1.identity%3D%3D%27ci-3%27">ci-3</a>
                        </div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">hostname</div>
                        <div className="value"><a title="Fremont hosts" class="btn btn-text btn-primary btn-small" href="/i/main/records/8666c433-a4cb-49d5-afcc-d2007e2d2036">Fremont hosts</a></div>
                        <icon className="icon search-item"></icon>
                      </div>
                      <div className="extended-info-parameters-body-item">
                        <div className="name">id</div>
                        <div className="value">8e2a810f-5fb3-4aa0-b8a4-b99a2cab3882</div>
                        <icon className="icon search-item"></icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">

                <div className="history-container">
                  <div className="history-item-container">
                    <div className="group">
                      <div className="date-item">
                        <div className="date-item">
                          <div className="date">
                            <span>12th August</span>
                            <span>2023</span>
                          </div>
                        </div>
                      </div>
                      <div className="history-item-group">
                        <div className="history-item">
                          <div className="changes-counter">
                            <span>3</span>
                          </div>
                          <div className="history-message">
                            <span>debian</span>
                            <span>std::host/Host:1</span>
                          </div>
                          <div className="history-version">
                            Ver. 204
                          </div>
                          <div className="history-date">
                            2023-08-25 06:50:46
                          </div>
                          <div className="actions">
                            <div className="hint-container">
                              <icon className="icon document-details"></icon>
                            </div>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                            std::types/Inventory:1.<b>statusDescription</b>
                          </div>
                          <div className="history-changes">
                            <span>"status of host is unknown"</span> → <span>"host is reachable"</span>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                          std::types/Inventory:1.<b>status</b>
                          </div>
                          <div className="history-changes">
                            <span>"unknown"</span> → <span>"ok"</span>
                          </div>
                        </div>
                      </div>
                      <div className="history-item-group">
                        <div className="history-item">
                          <div className="changes-counter">
                            <span>3</span>
                          </div>
                          <div className="history-message">
                            <span>debian</span>
                            <span>std::host/Host:1</span>
                          </div>
                          <div className="history-version">
                            Ver. 204
                          </div>
                          <div className="history-date">
                            2023-08-25 06:50:46
                          </div>
                          <div className="actions">
                            <div className="hint-container">
                              <icon className="icon document-details"></icon>
                            </div>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                            std::types/Inventory:1.<b>statusDescription</b>
                          </div>
                          <div className="history-changes">
                            <span>"status of host is unknown"</span> → <span>"host is reachable"</span>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                          std::types/Inventory:1.<b>status</b>
                          </div>
                          <div className="history-changes">
                            <span>"unknown"</span> → <span>"ok"</span>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                            std::types/Inventory:1.<b>statusDescription</b>
                          </div>
                          <div className="history-changes">
                            <span>"status of host is unknown"</span> → <span>"host is reachable"</span>
                          </div>
                        </div>
                        <div className="history-item">
                          <div className="history-object">
                          std::types/Inventory:1.<b>status</b>
                          </div>
                          <div className="history-changes">
                            <span>"unknown"</span> → <span>"ok"</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
              </div>

            </div>

            

          </div>
        </div>
      </div>
    );
  }
}
export default Page;
