import React from 'react';

import { observer } from 'mobx-react';

import * as ContentHeader from 'components/ContentHeader';
import { InventorySearchLink, RecordLink } from 'components/InventoryLinks';
import { Description, ID, Labels, Status } from 'pages/Instances/RecordView/Header/CommonItems';

export default observer((props) => (
  <ContentHeader.Container>
    <ContentHeader.Column>
      <Status record={props.record} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ID record={props.record} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <Description description={props.record.inventory_1.description} />
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ContentHeader.Item>
        <span>Runtime - command:</span> {props.record.container_1.runtime} - {props.record.container_1.command}
      </ContentHeader.Item>
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ContentHeader.Item>
        <span>Image name:</span>
        <InventorySearchLink
          query={`std::host/Container:1.imageName=='${props.record.container_1.imageName}'`}
          className="btn-text"
        >
          {props.record.container_1.imageName}
        </InventorySearchLink>
      </ContentHeader.Item>
    </ContentHeader.Column>
    <ContentHeader.Column>
      <ContentHeader.Item>
        <span>Host:</span> <RecordLink recordId={props.record.container_1.host} className="btn-text" />
      </ContentHeader.Item>
      <Labels record={props.record} />
    </ContentHeader.Column>
  </ContentHeader.Container>
));
