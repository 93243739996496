import { cast, getParentOfType, types } from 'mobx-state-tree';
import { NotificationTypes } from 'Constants';
import { UUID4 } from 'lib/uuid-utils';
var Notification = types
    .model('Notification', {
    id: types.string,
    messages: types.array(types.string),
    type: types.enumeration(Object.keys(NotificationTypes)),
})
    .actions(function (self) { return ({
    forget: function () {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getParentOfType(self, Notifications).deleteItem(self.id);
    },
}); });
var Notifications = types
    .model('Notifications', {
    items: types.array(Notification),
})
    .actions(function (self) { return ({
    notify: function (messages, type) {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }
        self.items.push({ messages: messages, type: type, id: UUID4() });
    },
    error: function (messages) {
        this.notify(messages, NotificationTypes.error);
    },
    success: function (messages) {
        this.notify(messages, NotificationTypes.success);
    },
    warn: function (messages) {
        this.notify(messages, NotificationTypes.warning);
    },
    info: function (messages) {
        this.notify(messages, NotificationTypes.info);
    },
    deleteItem: function (notificationID) {
        self.items = cast(self.items.filter(function (n) { return n.id !== notificationID; }));
    },
}); });
export default Notifications;
