import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { HOST_APP_TYPE } from 'Constants';
import Input from 'components/forms/Input';
import { EditForm } from 'stores/Instances/Applications';
var HostsManagerConfigurationTab = inject('instance')(observer(function (props) {
    var form = useState(EditForm.create({ id: props.record.id }))[0];
    var navigate = useNavigate();
    useEffect(function () {
        form.registerOnUpdate(function () {
            var record = props.instance.InventoryRecords.getById(props.record.id, function () {
                navigate("/i/".concat(props.instance.name, "/records/").concat(record.id, "/app-hosts"));
            }, true);
        });
    }, []);
    return (React.createElement("form", { className: "form-box" },
        React.createElement(Input, { key: "name", store: form.name }),
        React.createElement(Input, { key: "description", store: form.description }),
        React.createElement("div", { className: "message-placeholder" }, form.error),
        React.createElement("div", { className: "form-item" },
            React.createElement("button", { className: "btn-primary", type: "button", disabled: !form.hasSomethingToUpdate() || form.updatingEntity, onClick: function () {
                    form.tryUpdate(props.instance.Applications);
                } }, "Save"))));
}));
// @ts-ignore: eslint does not like assigning additional items to elements
HostsManagerConfigurationTab.suites = function (record) { return record.app_1 && record.app_1.applicationType === HOST_APP_TYPE; };
export default HostsManagerConfigurationTab;
