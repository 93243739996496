import React from 'react';

import { observer } from 'mobx-react';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { Spinner } from 'components/Loader';

@observer
export default class extends React.Component {
  componentDidMount() {
    if (this.props.swaggerStore.loading) {
      this.props.swaggerStore.patch_deeplinking();
      this.props.swaggerStore.fetch();
    }
  }

  componentWillUnmount() {
    this.props.swaggerStore.unpatch_deeplinking();
  }

  render() {
    if (this.props.swaggerStore.loading) {
      return <Spinner />;
    }
    return (
      <>
        {this.props.swaggerStore.baseURL && <h4>Base path: {this.props.swaggerStore.baseURL}</h4>}
        <SwaggerUI
          spec={this.props.swaggerStore.specData}
          deepLinking
          requestInterceptor={this.props.swaggerStore.requestPatcher}
        />
      </>
    );
  }
}
