/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid login">
        <div className="float-message-placeholder">
          <div className="notification ntf-warning close">
            <item>
              <a href="https://youtu.be/9fyX_ZVJW9E">https://youtu.be/9fyX_ZVJW9E</a>
            </item>
            <item className="btn-close" />
          </div>
        </div>

        <div className="content">
          <canvas className="login-animated-container" />
          <div className="container-login">
            <div className="login-box">
              <div className="logo" />
              <div className="title">
                <span>Sign in</span>
                <span>
                  <a href="sign-up.html">Create an account</a>
                </span>
              </div>
              <form className="form-box">
                <div className="form-item">
                  <input className="form" type="email" placeholder="Email" autoFocus />
                  <label>Email</label>
                </div>
                <div className="form-item">
                  <input className="form" type="password" placeholder="Password" />
                  <label>Password</label>
                </div>
                <div className="form-item">
                  <input className="form" type="text" placeholder="Organization" />
                  <label>Organization</label>
                </div>
                <div className="message-placeholder">Login failed. Please, try again.</div>
                <div className="form-item">
                  <button type="button" className="btn-primary btn-block">
                    Sign In
                  </button>
                </div>
                <hr />
                <div className="login-services-container">
                  <a href="#" className="btn btn-default btn-icon">
                    <icon className="google" />
                    Google
                  </a>
                  <a href="#" className="btn btn-default btn-icon">
                    <icon className="github" />
                    Github
                  </a>
                </div>
                <div className="login-link-container">
                  <a href="forgot-password.html">Forgot password?</a>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="footer-login">
            <div className="copyrights">2021 &copy; Made with &#10084; in Kharkiv</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
