import { getParent, resolveIdentifier, types } from 'mobx-state-tree';

import axios from 'axios';

import { HOST_APP_TYPE, InstanceReadinessStages, InstanceStates, UserRoles } from 'Constants';
import { ITLookWebSocket } from 'lib/itlook-websocket';
import * as tokensStorage from 'lib/token';

import Applications from 'stores/Instances/Applications';
import GlobalSearch from 'stores/Instances/GlobalSearch';
import InventoryRecords from 'stores/Instances/InventoryRecords';
import Store from 'stores/Store';
import Swagger from 'stores/Swagger';

export const Instance = types
  .model('Instance', {
    id: types.identifier, // the id of the item itself

    // the instance that doesn't exist anymore
    phantom: false,

    loading: false,
    navItemOverride: types.maybeNull(types.string),

    // real data
    name: types.string,
    displayName: types.string,
    admins: types.array(types.string),
    requestedBy: types.string,
    token: types.maybeNull(types.string),

    // rea
    _hostsCount: -1,

    globalSearch: types.optional(GlobalSearch, () => GlobalSearch.create({})),
    // Instance related items
    Applications: types.optional(Applications, () => Applications.create({})),
    InventoryRecords: types.optional(
      types.late(() => InventoryRecords),
      () => InventoryRecords.create({})
    ),
    Swagger: types.optional(Swagger, () => Swagger.create({ url: '/i/api/openapi.json' })),
  })
  .volatile(() => ({
    _socket: null,
  }))
  .actions((self) => ({
    afterCreate() {
      self.Swagger.setTokenProvider(() => self.token);
    },
    getSocket() {
      if (self.token && self._socket === null) {
        self._socket = new ITLookWebSocket(`${self.origin.replace('http', 'ws')}/ws/user?token=${self.token}`);
      }
      return self._socket;
    },
    setToken(token) {
      tokensStorage.setInstanceToken(self.id, token);
      self.token = token;
      self.checkReadiness();
    },
    setHostsCount(value) {
      self._hostsCount = value;
    },
    checkReadiness() {
      if (!self.Applications.loaded && !self.Applications.loading) {
        self.Applications.fetch();
      }
      self.InventoryRecords.mstats(
        "inherits('std::host/Host:1') AND isNotSet('std::types/Versionable:1.deletedAt')",
        '@model'
      ).then((responseData) => {
        if (responseData.data.hasOwnProperty('std::host/Host:1')) {
          self.setHostsCount(responseData.data['std::host/Host:1']);
        } else {
          self.setHostsCount(0);
          // pull the latest state of hosts to catch after-registered hosts
          setTimeout(self.checkReadiness, 1000);
        }
      });
    },
    getToken(onSuccess) {
      self.TransportLayer.post({
        url: '/m/api/v1/auth/tokens/instance-token',
        body: { instance: self.id },
        onSuccess: (response, response_data) => {
          self.setToken(response_data.data.token);
          if (onSuccess) {
            onSuccess();
          }
        },
        onFailure: (resp) => getParent(self).onFailure(resp),
      });
    },
    finishLoading() {
      self.loading = false;
    },
    update() {
      if (self.phantom) {
        // nothing to do here
        return;
      }

      self.org_info.fetch();
    },
    updateProperties(data) {
      if (data.hasOwnProperty('phantom')) {
        self.phantom = data.phantom;
      } else {
        if (data.name) {
          self.name = data.name;
        }
        if (data.displayName) {
          self.displayName = data.displayName;
        }
        if (data.admins) {
          self.admins = data.admins;
        }
        if (data.status) {
          self.status = data.status;
        }
      }
    },
    setNavItemOverride(value) {
      self.navItemOverride = value;
    },
  }))
  .views((self) => ({
    get api() {
      return axios.create({
        baseUrl: self.origin,
        headers: {
          Authorization: `Bearer ${self.token}`,
        },
        timeout: 5000,
        transformResponse: [
          (data) => {
            data = JSON.parse(data);
            if (data.status >= 300) {
              console.log(`Request failed ${data.request} with status ${data.status}: ${data.statusText}`);
            }
            return data;
          },
        ],
      });
    },
    get origin() {
      return (process.env.NODE_ENV === 'development' && process.env.DEV_ORIGIN_URL) || window.location.origin;
    },
    get org_info() {
      return Store.Organization.Info;
    },
    get status() {
      return self.org_info.instance_status;
    },
    get amIAdmin() {
      const profile = Store.Profile;
      return profile.isLogged && (profile.user.role === UserRoles.ADMIN || self.admins.includes(profile.user.email));
    },
    get TransportLayer() {
      return Store.TransportLayer;
    },
    get readinessState() {
      if (self.status !== InstanceStates.Ready || !self.Applications.loaded || self._hostsCount === -1) {
        return InstanceReadinessStages.unknown;
      }
      if (self.Applications.filter(HOST_APP_TYPE).length === 0) {
        return InstanceReadinessStages.notHostApp;
      }
      if (self._hostsCount > 0) {
        return InstanceReadinessStages.hostExists;
      }
      return InstanceReadinessStages.hostAppExists;
    },
  }));

const Instances = types
  .model('Instances', {
    _items: types.array(Instance),
    current: types.maybeNull(types.string),
    lastSelected: types.maybeNull(types.string),
    loaded: false,
    loading: false,
  })
  .views((self) => ({
    get items() {
      return self._items.filter((i) => !i.phantom);
    },
    get TransportLayer() {
      return Store.TransportLayer;
    },
    get hideLeftPanel() {
      if (self.loaded) {
        const instance = self.getCurrent();
        if (instance !== null) {
          return instance.readinessState !== InstanceReadinessStages.hostExists;
        }
      }
      return true;
    },
    getByName(instance) {
      return self.items.find((i) => i.name === instance);
    },
    getCurrent() {
      if (self.current) {
        return self.getByName(self.current);
      }
      if (self.items.length !== 0) {
        return self.items[0];
      }
    },
    get(instanceId) {
      return resolveIdentifier(Instance, self._items, instanceId);
    },
  }))
  .actions((self) => ({
    getLastSelectedItem() {
      return self.getByName(tokensStorage.getLastSelectedInstance());
    },
    setCurrentSelectedItem(instanceName) {
      if (self.current !== instanceName) {
        // reset overriden navigation item
        const currentObj = self.getCurrent();
        if (currentObj) {
          currentObj.setNavItemOverride(null);
        }
      }
      self.current = instanceName;
      tokensStorage.setLastSelectedInstance(instanceName);
    },
    fetch() {
      self.loading = true;
      self.pushItems([{ id: 'main', name: 'main', display_name: 'main', admins: [], requested_by: '' }]);
      // it should pick proper status for an instance from account api
      self._items[0].update();
    },
    finishLoading() {
      self.loading = false;
    },
    pushItems(data) {
      self.loaded = true;
      const currentInstances = new Set(data.map((i) => i.id));

      self._items.forEach((instance) => {
        if (!currentInstances.has(instance.id)) {
          instance.updateProperties({ phantom: true });
        }
      });
      data.forEach((instance) => {
        const cachedInstance = self.get(instance.id);
        if (!cachedInstance) {
          self._items.push({
            id: instance.id,
            name: instance.name,
            displayName: instance.display_name,
            admins: instance.admins,
            requestedBy: instance.requested_by,
            status: instance.status,
          });
        } else {
          cachedInstance.updateProperties(instance);
        }
      });
      self.setCurrentSelectedItem(self._items[0].name);
    },
    onFailure(response) {
      if (response.status === 403) {
        // portal token is bad...
        Store.Profile.logout();
      }
    },
  }));
export default Instances;

export const InstancesPageState = types
  .model('InstancesPageState', {
    sortBy: 'displayName',
    sortBack: false,
    confirmDeleteForID: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setUserIsAdmin(value) {
      self.userIsAdmin = value;
    },
    onSortChange(value, reverse) {
      self.sortBy = value;
      self.sortBack = reverse;
    },
    setConfirmDeleteForID(instanceID) {
      self.confirmDeleteForID = instanceID;
    },
  }));
