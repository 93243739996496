const compareTwoItems = (itemA, itemB) => {
  if (typeof itemA === 'string') {
    return itemA.localeCompare(itemB);
  }
  if (itemA < itemB) {
    return -1;
  }
  if (itemA > itemB) {
    return 1;
  }
  return 0;
};

export const sortObjects = (firstItem, secondItem, primaryFieldLabel, defaultFieldsOrder, reverseOrder) => {
  const fieldLabels = [primaryFieldLabel, ...defaultFieldsOrder];
  for (let i = 0; i < fieldLabels.length; i++) {
    const fieldLabel = fieldLabels[i];
    let result = compareTwoItems(firstItem[fieldLabel], secondItem[fieldLabel]);
    if (result !== 0) {
      if (fieldLabel === primaryFieldLabel && reverseOrder) {
        // NOTE(andreykurilin): we should apply reverse ordering only for
        //  primary field search
        result *= -1;
      }
      return result;
    }
  }

  return 0;
};
