import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Input from 'components/forms/Input/Input';
import InputS, { NoDisplayError } from 'components/forms/Input/InputS';
export default observer(function (props) {
    var state = useState(InputS.create({
        value: props.value,
        disabled: true,
        // there is no validators, so no errors are expected, no need to show placeholder
        error: NoDisplayError.create(),
    }))[0];
    useEffect(function () {
        state.setValue(props.value);
    }, [props.value]);
    return React.createElement(Input, { store: state }, props.children);
});
