import React from 'react';
import Item from 'components/Item';
// eslint-disable-next-line import/prefer-default-export
export var ConfirmationAboutDeleteWindow = function (props) {
    return (React.createElement("div", { className: "modal" },
        React.createElement("div", { className: "modal-box" },
            React.createElement("div", { className: "modal-header" },
                React.createElement(Item, null, "Are your sure?"),
                React.createElement(Item, { className: "btn-close", onClick: function () { return !props.deleteInProgress && props.onClose(); } })),
            React.createElement("div", { className: "modal-body" },
                React.createElement("p", null, "This operation is irreversible. Are you sure you want to continue?")),
            React.createElement("div", { className: "modal-footer" },
                React.createElement("button", { key: "cancel", className: "btn-default", type: "button", onClick: props.onCancel, disabled: props.deleteInProgress }, "Cancel"),
                React.createElement("button", { key: "delete", className: "btn-danger", type: "button", onClick: props.onDelete, disabled: props.deleteInProgress }, "Delete")))));
};
