import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Checkbox from 'components/Checkbox';
import { SimpleFormItem } from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import TextEditor, { SimpleTextViewer, Syntaxes } from 'components/forms/TextEditor';
import { LeftPanel, RightPanel, SidedPanel } from 'components/layout/Panels';
import { Spinner } from 'components/Loader';
import { InstanceLink, InstanceNavItemOverrider } from 'components/Nav';
import { ContainerBox, PageHeader } from 'components/Page';
import RadioFiledSet from 'components/Radio';
import { TabsBar, TabSelector } from 'components/TabsBar';
import NewHostS, { AuthMethods, RegistrationMethods } from 'pages/Instances/Hosts/RegisterNewHostS';
import NotFound from 'pages/NotFound';
var ButtonGroup = inject('notifications', 'instance')(observer(function (props) {
    var navigate = useNavigate();
    var notifications = props.notifications;
    var cancel = function () {
        var url = "/i/".concat(props.instance.name);
        if (props.calledFromManagerPage) {
            url = "".concat(url, "/hosts-managers/").concat(props.store.application.selected);
        }
        else {
            url = "".concat(url, "/hosts");
        }
        navigate(url);
    };
    var submit = function () {
        notifications.error('Sorry, this type of registration is temporary disabled.');
        // props.store.registerHost((hostID: string) => {
        //  notifications.success('Host is successfully registered.');
        //  navigate(`/i/${props.instance.name}/records/${hostID}`);
        // });
    };
    return (React.createElement("div", { className: "form-box-line" },
        React.createElement(SimpleFormItem, null,
            props.store.registrationMethod === RegistrationMethods.manual && (React.createElement("button", { type: "button", className: "btn btn-primary", disabled: !props.store.isReady(), onClick: submit }, "Create")),
            React.createElement("button", { type: "button", className: "btn btn-default", onClick: cancel }, "Cancel"))));
}));
var RegistrationMethodSelector = observer(function (props) {
    if (!props.store.application.selected) {
        return null;
    }
    var methods = [
        { value: RegistrationMethods.auto, label: 'Auto-registration' },
        { value: RegistrationMethods.manual, label: 'Manual registration' },
    ];
    return (React.createElement("div", { className: "form-box" },
        !props.store.registrationMethod && React.createElement("h4", null, "Registration method"),
        React.createElement(RadioFiledSet, { items: methods, selected: props.store.registrationMethod, onSelect: props.store.setRegistrationMethod, horizontalView: true })));
});
var AuthenticationSetup = observer(function (props) {
    if (props.store.authMethod === AuthMethods.creds) {
        return (React.createElement("div", { className: "form-box form-box-line-stretch" },
            React.createElement(Input, { key: "username", store: props.store.username }),
            React.createElement(Input, { key: "password", store: props.store.password })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-box" },
            React.createElement(Input, { key: "username", store: props.store.username })),
        React.createElement("div", { className: "form-box" },
            React.createElement(Input, { key: "keyType", store: props.store.keyType })),
        React.createElement("div", { className: "form-box" },
            React.createElement(TextEditor, { store: props.store.privateKey })),
        React.createElement("div", { className: "form-box" },
            React.createElement(Input, { key: "passphrase", store: props.store.passphrase }))));
});
var AutoRegistration = observer(function (props) { return (React.createElement(React.Fragment, null,
    React.createElement("h4", null, "Run commands below on target host to auto register it"),
    React.createElement("div", { className: "form-box" },
        React.createElement(Input, { key: "description", store: props.store.description })),
    React.createElement("div", { className: "form-box" },
        React.createElement(Input, { key: "labels", store: props.store.labels })),
    React.createElement(TabsBar, null, ['wget', 'curl'].map(function (tool) { return (React.createElement(TabSelector, { key: tool, title: tool.toUpperCase(), active: props.store.downloader === tool, link: "#", onClick: function (e) { return props.store.setDownloader(tool, e); } })); })),
    React.createElement("div", { className: "form-box" },
        React.createElement("fieldset", { className: "fieldset" },
            React.createElement(Checkbox, { label: "Compress to one-liner", checked: props.store.oneLiner, onChange: props.store.toggleOneLiner }))),
    React.createElement("div", { className: "form-box" },
        React.createElement(SimpleTextViewer, { key: props.store.autoRegistrationCommand, syntax: Syntaxes.bash, value: props.store.autoRegistrationCommand })))); });
var AutoRegistrationInstruction = function () { return (React.createElement("div", { className: "host-autoregistration-instruction" },
    React.createElement("h4", null, "Script is going to:"),
    React.createElement("ol", null,
        React.createElement("li", null, "Auto discover IP address, host name and active user name"),
        React.createElement("li", null, "Request manager to add new host"),
        React.createElement("li", null, "Install received public key to ~/.ssh/authorized_keys")),
    React.createElement("i", null, "This is the recommended and most secure way to register host. Every host has unique key, managed by agent and is never send over network nor accessible by users."))); };
var ManualRegistration = observer(function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-box form-box-line-stretch" },
            React.createElement(Input, { key: "hostname", store: props.store.hostName }),
            React.createElement(Input, { key: "ip", store: props.store.hostIP })),
        React.createElement("div", { className: "form-box" },
            React.createElement(Input, { key: "description", store: props.store.description })),
        React.createElement("div", { className: "form-box" },
            React.createElement(Input, { key: "labels", store: props.store.labels })),
        React.createElement("h4", null, "Authentication method"),
        React.createElement("div", { className: "form-box" },
            React.createElement(RadioFiledSet, { items: Object.values(AuthMethods), selected: props.store.authMethodName, onSelect: props.store.setAuthMethod, horizontalView: true })),
        React.createElement(AuthenticationSetup, { store: props.store }),
        React.createElement("div", { className: "message-placeholder text-danger" }, props.store.hostName.error.value)));
});
export default inject('instance')(observer(function (props) {
    var store = useState(NewHostS.create({}))[0];
    var _a = useState(false), calledFromManagerPage = _a[0], setCalledFromManagerPage = _a[1];
    var searchParams = useSearchParams()[0];
    useEffect(function () {
        store.linkInstanceStore(props.instance);
        if (!props.instance.Applications.loading) {
            props.instance.Applications.fetch();
        }
    }, []);
    useEffect(function () {
        var applicationID = searchParams.get('applicationID');
        if (props.instance.Applications.loaded && store.application.options.length === 0) {
            store.initApplicationsDropdown();
        }
        if (props.applicationID) {
            // applicationID comes from App.jsx as a part of 'Onboard workflow'
            store.application.handleSelect(props.applicationID);
        }
        else if (applicationID) {
            setCalledFromManagerPage(true);
            store.application.handleSelect(applicationID);
        }
    }, [searchParams, props.applicationID, props.instance.Applications.loaded]);
    if (!props.instance.Applications.loaded) {
        return React.createElement(Spinner, null);
    }
    var title;
    var titleURL;
    var subTitles;
    var documentTitle = 'Register new host';
    if (searchParams.get('applicationID') && store.application.selected) {
        var app = props.instance.Applications.apps.get(store.application.selected);
        if (!app) {
            return React.createElement(NotFound, { message: "Host Manager with ID=".concat(store.application.selected, " does not exist.") });
        }
        title = 'Hosts Manager';
        titleURL = '/hosts-managers';
        subTitles = [React.createElement(InstanceLink, { to: "/hosts-managers/".concat(app.id) }, app.name), documentTitle];
    }
    else {
        title = 'Hosts';
        titleURL = '/hosts';
        subTitles = ['Register new'];
    }
    return (React.createElement(React.Fragment, null,
        calledFromManagerPage && React.createElement(InstanceNavItemOverrider, { baseUrl: "hosts-managers" }),
        React.createElement(ContainerBox, null,
            React.createElement(PageHeader, { to: titleURL, title: title, subTitle: subTitles, documentTitle: documentTitle, instanceLink: true }),
            React.createElement(SidedPanel, { key: "control" },
                React.createElement(LeftPanel, null,
                    React.createElement("form", null,
                        React.createElement("div", { className: "form-box" },
                            React.createElement(Input, { store: store.application })),
                        React.createElement(RegistrationMethodSelector, { store: store })))),
            React.createElement(SidedPanel, { key: "main" },
                React.createElement(LeftPanel, null,
                    React.createElement("form", null,
                        store.application.selected && store.registrationMethod === RegistrationMethods.auto && (React.createElement(AutoRegistration, { store: store })),
                        store.application.selected && store.registrationMethod === RegistrationMethods.manual && (React.createElement(ManualRegistration, { store: store })))),
                React.createElement(RightPanel, null, store.application.selected && store.registrationMethod === RegistrationMethods.auto && (React.createElement(AutoRegistrationInstruction, null)))),
            React.createElement(SidedPanel, { key: "footer" },
                React.createElement(LeftPanel, null,
                    React.createElement("form", null,
                        React.createElement(ButtonGroup, { store: store, calledFromManagerPage: calledFromManagerPage })))))));
}));
