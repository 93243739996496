import { types } from 'mobx-state-tree';
import { ErrorStore, InputStore, NoDisplayError } from 'components/forms/Input';
var RegisterHostsManagerS = types
    .model('RegisterHostsManagerS', {
    step: types.optional(types.enumeration(['1', '2', '3', '4']), '1'),
    name: types.optional(InputStore, function () {
        return InputStore.create({
            inputType: 'text',
            label: 'Name',
            placeholder: 'Name of a new manager',
            required: true,
            autoFocus: true,
            error: ErrorStore.create({}),
        });
    }),
    applicationType: types.maybeNull(types.string),
    description: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Description [optional]',
            error: NoDisplayError.create({}),
        });
    }),
    externalID: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'External ID [optional]',
            error: NoDisplayError.create({}),
        });
    }),
    // data from api
    itemID: types.maybeNull(types.string),
    secret: types.maybeNull(types.string),
    creatingEntity: false,
    error: types.maybeNull(types.string),
})
    .actions(function (self) { return ({
    setApplicationType: function (applicationType) {
        self.applicationType = applicationType;
    },
    confirmApplicationType: function () {
        self.step = '2';
    },
    setCreatingEntity: function (value) {
        self.creatingEntity = value;
        self.name.setDisabled(value);
        self.description.setDisabled(value);
        self.externalID.setDisabled(value);
    },
    onSuccessCreate: function (resp, response_data) {
        this.setCreatingEntity(false);
        self.step = '2';
        self.secret = response_data.data.app.secret;
        self.itemID = response_data.data.app.id;
    },
    onFailedCreate: function (e) {
        this.setCreatingEntity(false);
        self.error = e[0];
    },
    tryCreate: function (applicationsStore) {
        self.error = null;
        self.creatingEntity = true;
        var body = {
            application_type: self.applicationType,
            name: self.name.value,
            description: '',
        };
        if (self.description.value) {
            body.description = self.description.value;
        }
        if (self.externalID.value) {
            body.external_id = self.externalID.value;
        }
        applicationsStore.create(body, this.onSuccessCreate, this.onFailedCreate);
    },
}); });
export default RegisterHostsManagerS;
