import React from 'react';

import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

export const SidedPanel = observer((props) => <div className={`two-panels ${props.className}`}>{props.children}</div>);

SidedPanel.propTypes = {
  className: PropTypes.string,
};
SidedPanel.defaultProps = {
  className: '',
};

export const LeftPanel = observer((props) => {
  return <div className="left"> {props.children} </div>;
});

export const RightPanel = observer((props) => {
  return <div className="right"> {props.children} </div>;
});
