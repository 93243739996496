import React from 'react';

import { observer } from 'mobx-react';

import { ContainerBox } from 'components/Page';

@observer
export default class InstanceLoader extends React.Component {
  constructor(props) {
    super(props);
    this.timer = undefined;
  }

  componentDidMount() {
    this.runUpdate();
  }

  componentWillUnmount() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
  }

  runUpdate = () => {
    if (this.props.testPage) {
      return;
    }
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    if (!this.props.loading) {
      this.props.instance.update();
    }
    this.timer = setTimeout(this.runUpdate, 1000);
  };

  render() {
    return (
      <ContainerBox>
        <div className="process-animation-container">
          <div className="process-animation-box">
            <div className="gear-1" />
            <div className="gear-2" />
          </div>
          <div className="process-animation-message">
            <span>Instance is not ready yet.</span>
            <span>Please wait a bit, we are working on deploying and configuring it for you.</span>
          </div>
        </div>
      </ContainerBox>
    );
  }
}
