import React from 'react';
import { inject, observer } from 'mobx-react';
import { ContainerBox, PageHeader } from 'components/Page';
var NotFound = function (_a) {
    var title = _a.title, message = _a.message;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageHeader, { title: title || 'Not found [404]' }),
        React.createElement(ContainerBox, null, message || 'Oops. We cannot find this page.')));
};
export var ExistsOnlyForAdmin = inject('profile')(observer(function (_a) {
    var profile = _a.profile, children = _a.children;
    if (!profile.isLogged) {
        return null;
    }
    if (!profile.user.isAdmin) {
        return React.createElement(NotFound, null);
    }
    return children;
}));
export default NotFound;
