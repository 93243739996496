import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import * as queryString from 'lib/query-string';
import FormItem, { SimpleFormItem } from 'components/forms/FormItem';
import Input from 'components/forms/Input';
import TextEditor from 'components/forms/TextEditor';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
import Spinner from 'components/Loader';
import { ContainerBox, PageHeader } from 'components/Page';
import { EditScriptStore, NewScriptStore, } from 'pages/Instances/Scripts/ScriptEditorS';
var Buttons = inject('notifications')(observer(function (props) {
    var navigate = useNavigate();
    return (React.createElement("div", { className: "form-box-line" },
        React.createElement(SimpleFormItem, { key: "submit" },
            React.createElement("button", { type: "button", className: "btn btn-success", disabled: !props.store.isReady() || props.store.inProgress, onClick: function (e) { return props.store.onSubmit(e); } }, props.store.submitButtonTitle)),
        React.createElement(SimpleFormItem, { key: "cancel" },
            React.createElement("button", { type: "button", className: "btn btn-default", disabled: props.store.inProgress, onClick: function () { return navigate(props.store.redirectOnCancel); } }, "Cancel"))));
}));
var ScriptEditor = inject('instance')(observer(function (props) {
    return (React.createElement(ContainerBox, null,
        React.createElement(PageHeader, { to: "/scripts", title: "Scripts", subTitle: props.store.pageSubtitle, documentTitle: "Scripts", instanceLink: true }),
        React.createElement(SidedPanel, null,
            React.createElement(LeftPanel, null,
                React.createElement("form", null,
                    React.createElement("div", { className: "form-box" },
                        React.createElement(Input, { key: "name", store: props.store.name })),
                    React.createElement("div", { className: "form-box form-box-line-stretch" },
                        React.createElement(Input, { key: "command", store: props.store.command }),
                        React.createElement(Input, { key: "syntax", store: props.store.syntaxSelector })),
                    React.createElement("div", { className: "form-box" },
                        React.createElement(Input, { key: "labels", store: props.store.labels })),
                    React.createElement("div", { className: "form-box" },
                        React.createElement(Input, { key: "description", store: props.store.description }))),
                React.createElement("form", null,
                    React.createElement("div", { className: "form-box" },
                        React.createElement(FormItem, { error: props.store.globalError || '' }))),
                React.createElement("form", null,
                    React.createElement("div", { className: "form-box" },
                        React.createElement(TextEditor, { store: props.store.code }))),
                React.createElement(Buttons, { store: props.store })))));
}));
export var ScriptCreatePage = inject('instance')(observer(function (props) {
    var store = useState(NewScriptStore.create())[0];
    var navigate = useNavigate();
    useEffect(function () {
        store.linkInstanceStore(props.instance);
        store.registerOnSuccess(function (scriptId) { return navigate("/i/".concat(props.instance.name, "/records/").concat(scriptId)); });
        if (!props.instance.Applications.loading) {
            props.instance.Applications.fetch();
        }
    }, []);
    var location = useLocation();
    useEffect(function () {
        var query = queryString.parse(location.search);
        if (query.rootDir) {
            store.name.setValue(query.rootDir);
        }
    }, [location.search]);
    return React.createElement(ScriptEditor, { store: store });
}));
export var ScriptEditPage = inject('instance')(observer(function (props) {
    var _a = useState(null), record = _a[0], setRecord = _a[1];
    var _b = useState(null), store = _b[0], setStore = _b[1];
    var navigate = useNavigate();
    var location = useLocation();
    useEffect(function () {
        var query = queryString.parse(location.search);
        if (query.id) {
            var loadRecord = function (loadedRecord) {
                var ensureParentsLoaded = function () {
                    if (loadedRecord.script_1.parentLoaded) {
                        setRecord(loadedRecord);
                    }
                    else {
                        setTimeout(ensureParentsLoaded, 50);
                    }
                };
                ensureParentsLoaded();
            };
            props.instance.InventoryRecords.getById(query.id, loadRecord);
        }
    }, [location.search]);
    useEffect(function () {
        if (record !== null && record.loaded) {
            var s = EditScriptStore.create({
                scriptID: record.id,
                originalName: record.script_1.path,
                originalCommand: record.script_1.command[0],
                originalDescription: record.inventory_1.description,
                originalSyntax: record.script_1.syntax,
                originalLabels: Array.from(record.root_1.labels),
                originalCode: record.script_1.code.join('\n'),
            });
            s.linkInstanceStore(props.instance);
            s.registerOnSuccess(function () { return navigate("/i/".concat(props.instance.name, "/records/").concat(record.id)); });
            setStore(s);
        }
    }, [record]);
    if (store === null) {
        return React.createElement(Spinner, null);
    }
    return React.createElement(ScriptEditor, { store: store });
}));
