import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Icon from 'components/Icon';
import { ConfirmationAboutDeleteWindow } from 'components/ModalWindow';
import { AppPart_1, HostPart_1, ScriptPart_1 } from 'stores/Instances/InventoryRecords';
var controlsPerModel = [
    {
        model: AppPart_1.name,
        handler: function (record) { return record.app_1.delete(); },
        redirectURLAfterDelete: function (instance) { return "/i/".concat(instance.name, "/agents"); },
    },
    {
        model: HostPart_1.name,
        handler: function (record) { return record.host_1.delete(); },
        redirectURLAfterDelete: function (instance) { return "/i/".concat(instance.name, "/hosts"); },
    },
    {
        model: ScriptPart_1.name,
        handler: function (record) { return record.script_1.delete(); },
        redirectURLAfterDelete: function (instance) { return "/i/".concat(instance.name, "/scripts"); },
    },
];
var DeleteButton = inject('notifications', 'instance')(observer(function (props) {
    var _a = useState(false), showDeleteConfirmation = _a[0], setShowDeleteConfirmation = _a[1];
    var _b = useState(false), deleteInProgress = _b[0], setDeleteInProgress = _b[1];
    var navigate = useNavigate();
    var notifications = props.notifications;
    var onClick = function () {
        setShowDeleteConfirmation(true);
    };
    var handleDelete = function () {
        setDeleteInProgress(true);
        var errorOrPromise = props.controls.handler(props.record);
        Promise.resolve(errorOrPromise).then(function (error) {
            if (!error) {
                notifications.success("Record ".concat(props.record.id, " is deleted."));
                // small delay to make sure backend finishes updating caches
                var redirectURLAfterDelete_1 = props.controls.redirectURLAfterDelete(props.instance);
                setTimeout(function () { return navigate(redirectURLAfterDelete_1); }, 500);
            }
            else {
                notifications.error("Failed to delete record: ".concat(error));
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: onClick },
            React.createElement(Icon, { className: "delete-btn" }),
            "Delete"),
        showDeleteConfirmation && (React.createElement(ConfirmationAboutDeleteWindow, { onClose: function () { return setShowDeleteConfirmation(false); }, onCancel: function () { return setShowDeleteConfirmation(false); }, onDelete: handleDelete, deleteInProgress: deleteInProgress }))));
}));
export default observer(function (props) {
    var controls;
    controlsPerModel.some(function (controlSpec) {
        if (props.record.data.has(controlSpec.model)) {
            controls = controlSpec;
            return true;
        }
        return false;
    });
    if (controls) {
        return React.createElement(DeleteButton, { record: props.record, controls: controls });
    }
    return null;
});
