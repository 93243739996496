var _a;
import React from 'react';
import { getType } from 'mobx-state-tree';
import DropdownSelector from 'components/forms/Input/DropdownSelector';
import DropdownSelectorStore from 'components/forms/Input/DropdownSelectorS';
import EmailInputStore from 'components/forms/Input/EmailInputS';
import FilePathInputStore from 'components/forms/Input/FilePathInputS';
import BaseInput from 'components/forms/Input/Input';
import InputStore, { ErrorStore, NoDisplayError } from 'components/forms/Input/InputS';
import PasswordInput from 'components/forms/Input/PasswordInput';
import PasswordInputStore from 'components/forms/Input/PasswordInputS';
import TagsInput from 'components/forms/Input/TagsInput';
import TagsInputStore from 'components/forms/Input/TagsInputS';
export { DropdownSelectorStore, InputStore, EmailInputStore, FilePathInputStore, PasswordInputStore, TagsInputStore };
export { ErrorStore, NoDisplayError };
var ComponentsMap = (_a = {},
    _a[PasswordInputStore.name] = PasswordInput,
    _a[TagsInputStore.name] = TagsInput,
    _a[DropdownSelectorStore.name] = DropdownSelector,
    _a);
export default (function (props) {
    // @ts-ignore: TS cannot match input store to our components
    return React.createElement(ComponentsMap[getType(props.store).name] || BaseInput, props);
});
