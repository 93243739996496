/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid deploy">
        <div className="float-message-placeholder">
          <div className="notification ntf-info close">
            <item>
              Have a nice day Dude!
            </item>
            <item className="btn-close" />
          </div>
        </div>

        <div className="content">

          <div className="laptop-box">
            <div className="laptop-blinking"></div>
            <div className="laptop-dead"></div>
          </div>

          <div className="laptop-msg">
            <div className="text-warning">Something’s not right.</div>
            <button className="btn btn-primary">Please reload</button>
          </div>

        </div>

      </div>
    );
  }
}
export default Page;
