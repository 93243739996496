var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { types } from 'mobx-state-tree';
import { HOST_APP_TYPE } from 'Constants';
import { DropdownSelectorStore, ErrorStore, InputStore, NoDisplayError, PasswordInputStore, TagsInputStore, } from 'components/forms/Input';
import TextEditorS from 'components/forms/TextEditorS';
// eslint-disable-next-line no-template-curly-in-string
var AUTH_HEADER = "'Authorization: Bearer '${ITLOOK_TOKEN}";
export var RegistrationMethods = {
    auto: 'auto',
    manual: 'manual',
};
export var AuthMethods = {
    creds: { label: 'Password authentication', value: 'creds' },
    pubkey: { label: 'Public key authentication', value: 'pubkey' },
};
var NewHostS = types
    .model('NewHostS', {
    application: types.optional(DropdownSelectorStore, function () {
        return DropdownSelectorStore.create({
            label: 'Agent',
            placeholder: '-- select a manager to connect the new host --',
        });
    }),
    registrationMethod: types.optional(types.enumeration('registrationMethod', Object.values(RegistrationMethods)), RegistrationMethods.auto),
    hostName: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Host name',
            required: true,
            onChangeHandlingDelay: 0,
            error: NoDisplayError.create({}),
        });
    }),
    hostIP: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Host IP',
            required: true,
            onChangeHandlingDelay: 0,
            // FIXME(andreykurilin): add validation for ip address
            error: NoDisplayError.create({}),
        });
    }),
    description: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Description',
            onChangeHandlingDelay: 0,
            error: NoDisplayError.create({}),
        });
    }),
    labels: types.optional(TagsInputStore, function () {
        return TagsInputStore.create({
            label: 'Labels',
            placeholder: 'Add new label',
            error: ErrorStore.create({}),
        });
    }),
    authMethodName: types.optional(types.enumeration('authMethod', Object.keys(AuthMethods)), AuthMethods.creds.value),
    username: types.optional(InputStore, function () {
        return InputStore.create({
            label: 'Username',
            required: true,
            error: NoDisplayError.create({}),
        });
    }),
    password: types.optional(PasswordInputStore, function () {
        return PasswordInputStore.create({
            label: 'Password',
            required: true,
            error: ErrorStore.create({}),
        });
    }),
    keyType: types.optional(DropdownSelectorStore, function () {
        return DropdownSelectorStore.create({
            label: 'Key Type',
            options: [
                { title: 'SSH-Key', value: 'ssh_key' },
                { title: 'x509', value: 'X.509' },
            ],
            selected: 'ssh_key',
        });
    }),
    privateKey: types.optional(TextEditorS, function () {
        return TextEditorS.create({
            label: 'Private key',
            description: 'It is highly recommended to generate unique keys for ITLook system. ' +
                'Ideally, unique keys should be generated for each registered host.',
            excludeActionsButtons: true,
        });
    }),
    key: types.maybeNull(types.string),
    passphrase: types.optional(PasswordInputStore, function () {
        return PasswordInputStore.create({
            label: 'Passphrase',
            description: 'An optional passphrase for the private key.',
            error: ErrorStore.create({}),
        });
    }),
    downloader: types.optional(types.enumeration('downloader', ['curl', 'wget']), 'wget'),
    oneLiner: false,
    creating: false,
})
    .volatile(function () { return ({
    instance: null,
}); })
    .views(function (self) { return ({
    get authMethod() {
        return AuthMethods[self.authMethodName];
    },
    isReady: function () {
        var authDataIsFilled = false;
        if (self.authMethodName === AuthMethods.creds.value) {
            authDataIsFilled = Boolean(self.username.isDone() && self.password.isDone());
        }
        else {
            authDataIsFilled = Boolean(self.username.isDone() && self.keyType.selected && self.privateKey.value);
        }
        return (!self.creating &&
            self.application.selected &&
            self.hostName.isDone() &&
            self.hostIP.isDone() &&
            authDataIsFilled &&
            self.labels.isDone());
    },
    get apiURL() {
        // eslint-disable-next-line no-template-curly-in-string
        var appID = self.oneLiner ? self.application.selected : "'${ITLOOK_APP_ID}'";
        // TODO: get tunnel for the app.
        return "tunnel/app/".concat(appID, "/api/v1/host/autoregister");
    },
    get environmentVariables() {
        var envVars = [
            {
                variable: "ITLOOK_TOKEN='".concat(self.instance.token, "'"),
                description: 'ITLook system access token.',
            },
            {
                variable: "ITLOOK_APP_ID=\"".concat(self.application.selected, "\""),
                description: 'The ID of host application that will be used for host communication.',
                excludeIfOneLiner: true,
            },
        ];
        if (self.description.value) {
            envVars.push({
                variable: "ITLOOK_HOST_DESCRIPTION='".concat(self.description.value, "'"),
                description: 'The description for the host.',
            });
        }
        if (self.labels.items.length > 0) {
            var labelsStr = encodeURI(JSON.stringify(self.labels.items));
            envVars.push({
                variable: "ITLOOK_HOST_LABELS='".concat(labelsStr, "'"),
                description: 'The labels to assign to the host.',
            });
        }
        if (self.oneLiner) {
            return envVars
                .filter(function (envVar) { return !envVar.excludeIfOneLiner; })
                .map(function (envVar) { return "export ".concat(envVar.variable); })
                .join('; ');
        }
        return envVars.map(function (envVar) { return "# ".concat(envVar.description, "\nexport ").concat(envVar.variable); }).join('\n\n');
    },
    get autoRegistrationCommand() {
        var script = '';
        if (this.environmentVariables) {
            script += this.environmentVariables + (self.oneLiner ? '; ' : '\n\n');
        }
        var delimiter = self.oneLiner ? ' ' : ' \\\n  ';
        if (self.downloader === 'curl') {
            script += [
                'bash <(',
                'curl',
                "-H 'Content-Type: application/json'",
                "-H ".concat(AUTH_HEADER),
                '-X POST',
                "-d '{}'",
                "'".concat(self.instance.origin, "/").concat(this.apiURL, "')"),
            ].join(delimiter);
        }
        else {
            script += [
                'bash <(',
                'wget',
                '-O- -q',
                "--header 'Content-Type: application/json'",
                "--header ".concat(AUTH_HEADER),
                "--post-data '{}'",
                "'".concat(self.instance.origin, "/").concat(this.apiURL, "')"),
            ].join(delimiter);
        }
        return script;
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        // todo: add validation for IP
    },
    linkInstanceStore: function (store) {
        self.instance = store;
    },
    initApplicationsDropdown: function () {
        var apps = self.instance.Applications.filter(HOST_APP_TYPE);
        self.application.setOptions(apps.map(function (app) { return ({ value: app.id, title: app.name }); }));
    },
    setDownloader: function (tool, e) {
        if (e !== undefined) {
            e.stopPropagation();
            e.preventDefault();
        }
        self.downloader = tool;
    },
    toggleOneLiner: function () {
        self.oneLiner = !self.oneLiner;
    },
    setRegistrationMethod: function (value) {
        self.registrationMethod = value;
    },
    setAuthMethod: function (value) {
        self.authMethodName = value;
    },
    setCreating: function (value) {
        self.creating = value;
        self.labels.setDisabled(value);
        self.hostName.setDisabled(value);
        self.hostIP.setDisabled(value);
        self.username.setDisabled(value);
        self.password.setDisabled(value);
        self.passphrase.setDisabled(value);
        self.privateKey.setReadOnly(value);
    },
    registerHost: function (onSuccess) {
        var _this = this;
        var socket = self.instance.getSocket();
        socket.afterOpen(function () { return __awaiter(_this, void 0, void 0, function () {
            var app, accessOpts, body, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        app = socket.ensureAppConnection(self.application.selected);
                        accessOpts = {
                            accessUser: self.username.value,
                        };
                        if (self.authMethodName === AuthMethods.creds.value) {
                            accessOpts.accessPassword = self.password.value;
                        }
                        else {
                            accessOpts.accessKeyType = self.keyType.selected;
                            accessOpts.accessKey = self.privateKey.value;
                            accessOpts.accessKeyPassphrase = self.passphrase.value;
                        }
                        body = __assign({ hostname: self.hostName.value, description: self.description.value || '', labels: self.labels.items, ips: [self.hostIP.value], accessIP: self.hostIP.value }, accessOpts);
                        this.setCreating(true);
                        return [4 /*yield*/, app.rpc('host.create', { host: body })];
                    case 1:
                        result = _a.sent();
                        this.handleCreateHost(result, onSuccess);
                        return [2 /*return*/];
                }
            });
        }); });
    },
    handleCreateHost: function (result, onSuccess) {
        if (!result.ok) {
            // API  does not tell which field is wrong, it may or may be not a name error ;(
            self.hostName.setError(result.message);
        }
        else {
            onSuccess(result.host['std::types/Root:1'].id);
        }
        this.setCreating(false);
    },
    onSubmit: function (e, onSuccess) {
        e.stopPropagation();
        if (self.isReady()) {
            this.registerHost(onSuccess);
        }
    },
}); });
export default NewHostS;
