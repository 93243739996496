var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
var ColumnHeader = function (props) {
    if (props.empty) {
        return React.createElement("th", { key: props.itemKey });
    }
    var onClick;
    var className = '';
    if (props.noselect) {
        onClick = function () { };
    }
    else {
        onClick = function () { return props.onClick(props.value); };
        if (props.selected) {
            if (!props.reverse) {
                className = 'sortup';
            }
            else {
                className = 'sortdown';
            }
        }
    }
    return (React.createElement("th", null,
        React.createElement("div", { className: className, key: props.itemKey || props.value, onClick: onClick }, props.title || "".concat(props.value[0].toUpperCase()).concat(props.value.slice(1)))));
};
export var TableRow = function (props) { return (React.createElement("tr", { style: props.style, onClick: props.onClick, title: props.title }, props.children)); };
export var TableCell = function (props) {
    var _a, _b;
    return (React.createElement("td", { rowSpan: (_a = props.rowSpan) !== null && _a !== void 0 ? _a : 1, colSpan: (_b = props.colSpan) !== null && _b !== void 0 ? _b : undefined, className: props.className }, props.children));
};
export default (function (props) {
    var _a = useState(props.selected || props.headers[0].value), selectedItem = _a[0], setSelected = _a[1];
    var _b = useState(Boolean(props.reverseOrderByDefault)), reverse = _b[0], setReverse = _b[1];
    var onColumnHeaderClick = function (value) {
        var newReverseValue;
        if (selectedItem === value) {
            newReverseValue = !reverse;
        }
        else {
            newReverseValue = false;
        }
        setReverse(newReverseValue);
        setSelected(value);
        props.onSortChange(value, newReverseValue);
    };
    useEffect(function () {
        setReverse(props.reverse || false);
    }, [props.reverse]);
    var classNames = ['table', props.className, props.noHover ? 'table-no-hover' : null];
    var tableClassName = classNames.filter(function (c) { return c; }).join(' ');
    return (React.createElement("div", { className: tableClassName },
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement(TableRow, { key: "header" }, props.headers.map(function (header) { return (React.createElement(ColumnHeader, __assign({ key: header.itemKey || header.value, onClick: onColumnHeaderClick, selected: selectedItem === header.value, reverse: reverse }, header))); }))),
            React.createElement("tbody", null, props.children))));
});
