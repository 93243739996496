import { types } from 'mobx-state-tree';

import Info from 'stores/Organization/Info';
import Settings from 'stores/Organization/Settings';
import Users from 'stores/Organization/Users';

export default types.model('Organization', {
  Info: types.optional(Info, () => Info.create({})),
  Settings: types.optional(Settings, () => Settings.create({})),
  Users: types.optional(Users, () => Users.create({})),
});
