export const fetchFieldStats = async ({ transport, uuids, fieldName, startTimestamp, stopTimestamp, interval }) => {
  return new Promise((resolve, reject) => {
    transport.get({
      url: '/i/api/v1/record/mfield_stats',
      query: {
        id: uuids,
        field: fieldName,
        start_timestamp: startTimestamp,
        stop_timestamp: stopTimestamp,
        interval: interval,
      },
      onSuccess: (response, responseData) => {
        // const splittedFieldName = fieldName.split('.', 2);
        // const modelName = splittedFieldName[0];
        // const croppedFieldName = splittedFieldName[1];
        const result = Object.fromEntries(
          Object.keys(responseData.data.results).map((recordId) => {
            let rawStats = responseData.data.results[recordId].data;

            // dirty fix for the case when historical stats are missing and API
            // returns null
            /* FIXME(andreykurilin): the origin of this code comes from InventoryRecords
                  that has caching and some other cool features. StatsS does not have
                  access to InventoryRecords, so we cannot use it here. Fix or delete
                  commented code someday.
             */
            if (rawStats.length > 0) {
              const firstElem = rawStats[0][responseData.data.field_type];
              const lastElem = rawStats[rawStats.length - 1][responseData.data.field_type];
              if (firstElem.distribution.null === 1 && lastElem.distribution.null === 1) {
                rawStats = [];
              }
              /*
              if (firstElem.distribution.null === 1 && lastElem.distribution.null === 1) {
                if (this.hasItem(recordId)) {
                  const currentValue = this.getById(recordId).data.get(modelName).get(croppedFieldName);
                  const distribution = {};
                  distribution[currentValue] = 1;
                  rawStats.forEach((stat) => {
                    stat[responseData.data.field_type].distribution = distribution;
                  });
                }
              }
               */
            }

            return [
              recordId,
              {
                id: recordId,
                fieldName: fieldName,
                stats: rawStats.map((stat) => ({
                  timestamp: stat.timestamp,
                  stats: stat[responseData.data.field_type].distribution,
                })),
              },
            ];
          })
        );
        resolve(result);
      },
      onFailure: reject,
    });
  });
};
