import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { inject, observer, Provider } from 'mobx-react';

import Input from 'components/forms/Input';
import Hint from 'components/Hint';
import { getURLParams } from 'components/InventoryLinks';

const SearchTypeToDefaultQuery = {
  hosts: "inherits('std::host/Host:1')",
  containers: "inherits('std::host/Container:1')",
  scripts: "inherits('std::code/Script:1')",
};

const handleEvent = (handler) => {
  return (e) => {
    if (handler !== null) {
      handler(e);
    }
    // NOTE(andreykurilin): this is needed to prevent loosing focus at input
    e.preventDefault();
    e.stopPropagation();
  };
};

const SyntaxHelper = () => {
  return (
    <>
      <b>Comparison Operators:</b>
      <p> &gt;, &lt;, &gt;=, &lt;=, ==, != (or &gt;&gt;)</p>
      <b>Special Operators:</b>
      <p> %= (fuzzy search), ~= (regex match)</p>
      <b>Array values operators:</b> <p>IN and CONTAINS</p>
      <b>Value Types</b>
      <b>Strings:</b> <p>'abcdef'</p>
      <b>Integers:</b> <p>1, 5, 1050</p>
      <b>Floats:</b> <p>1.5, 20.05, 0.33</p>
      <b>Arrays:</b> <p>[1, 2, 3, 10.5]</p>
      <b>Functions-Values:</b>
      <p>
        id('some_value') returns arrays of @id(s) of records that match std::types/Inventory:1.identity == 'some_value'
      </p>
      <b>Function Statements:</b>
      <p>inherits('some-model-name') filter results to return only records that inherit 'some-model-name'</p>
      <p>
        isSet('some-attr-name')/isNotSet('some-attr-name') filter results to return only records that has or hasn't
        'some-attr-name' attribute
      </p>
      <b>Complex queries:</b>
      <p>One can use AND and OR to connect multiple statements</p>
      <b>Brackets:</b> <p>( ) - first executed in brackets</p>
    </>
  );
};

export const InputHelpBlock = () => (
  <Hint className="hint-left" title="Syntax description">
    <Hint.Trigger>
      <div className="icon helper" />
    </Hint.Trigger>
    <Hint.Window title="Operators">
      <SyntaxHelper />
    </Hint.Window>
  </Hint>
);

const SuggestionItem = (props) => (
  <div className="form-item" onClick={handleEvent(props.onSelect)}>
    <div className="item-container">
      <button type="button" className="btn btn-primary btn-tag">
        {props.label}
      </button>
    </div>
  </div>
);

const TypesSuggestionBlock = inject('globalSearch')(
  observer((props) => {
    const userEnteredValue = props.globalSearch.input.value || '';
    const items = Object.entries(SearchTypeToDefaultQuery).filter(([, value]) =>
      userEnteredValue ? !userEnteredValue.includes(value) : !props.globalSearch.defaultQuery !== value
    );

    if (!items) {
      // we do not have something to suggest users at the moment
      return null;
    }

    return (
      <form>
        <div className="form-box-line">
          {items.map(([key, value]) => (
            <SuggestionItem
              key={key}
              label={`Find ${key}`}
              onSelect={() =>
                props.globalSearch.setValue(userEnteredValue ? `${userEnteredValue} AND ${value}` : value)
              }
            />
          ))}
        </div>
      </form>
    );
  })
);

// FIXME(andreykurilin): fetch recent history from API as soon it starts providing it.
const HistoryBlock = () => {
  /*
  <h4>Recent search queries</h4>
   <ul className="autocomplete-results">
    <li>Boris</li>
    <li>Boris Bullet Dodger</li>
    <li>Boris Brejcha</li>
    <li>Boris Pavlovich</li>
    <li>Boris Johnson</li>
  </ul> */
  return null;
};

const SearchWindowContent = inject('globalSearch')(
  observer(() => {
    return (
      <>
        {/* TODO(andreykurilin): find a better way to hide/show deleted
        <form>
          <div className="form-box">
            <fieldset className="fieldset">
              <Checkbox
                label="Hide deleted items"
                checked={props.globalSearch.hideDeleted}
                onChange={handleEvent(props.globalSearch.toggleHideDeleted)}
              />
            </fieldset>
          </div>
        </form>
        */}
        <TypesSuggestionBlock />
        <HistoryBlock />
      </>
    );
  })
);

const SearchWindow = inject('globalSearch')(
  observer((props) => {
    if (!props.globalSearch.showTooltip) {
      return null;
    }
    return (
      <div className="autocomplete-container" onMouseDown={handleEvent(null)}>
        <div className="autocomplete">
          <SearchWindowContent />
        </div>
      </div>
    );
  })
);

export default inject('instance')(
  observer((props) => {
    const navigate = useNavigate();

    const globalSearch = props.instance.globalSearch;

    const submit = () => {
      navigate(`/i/${props.instance.name}/search?${getURLParams(globalSearch.query)}`);
    };

    useEffect(() => {
      globalSearch.input.registerOnEnterPressHandler(submit);
    }, []);

    return (
      <Provider globalSearch={globalSearch}>
        <div className="global-search">
          <div className="dynamic-search">
            <div className="form-box">
              <Input store={globalSearch.input}>
                <div className="button-container">
                  <div className="button-container-item">
                    <button type="button" className="search" onClick={submit} />
                  </div>
                </div>
              </Input>
            </div>
            <SearchWindow store={globalSearch} />
          </div>
        </div>
        <div className="icon helper">
          <InputHelpBlock />
        </div>
      </Provider>
    );
  })
);
