import { types } from 'mobx-state-tree';
import requests from 'lib/requests';
import { AuthenticationMixin } from 'pages/Auth/LoginS';
var OAuth2CallbackForm = AuthenticationMixin.named('OAuth2CallbackForm')
    .props({
    provider: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    notRegisteredYet: false,
    completed: false,
})
    .actions(function (self) { return ({
    markAsNotRegistered: function () {
        self.notRegisteredYet = true;
    },
    login: function () {
        requests.GET({
            url: "/m/api/v1/auth/oauth2/".concat(self.provider, "/callback"),
            query: {
                code: self.code,
                state: self.state,
            },
            onFailure: self.processError,
            onSuccess: function (response, responseBody) {
                self.setRedirectURL(responseBody.data.start_url);
                self.onSuccessLogin(responseBody.data.token);
            },
            onFinish: function () {
                self.setProcessing(false);
            },
        });
    },
}); });
export default OAuth2CallbackForm;
