import { getParentOfType, types } from 'mobx-state-tree';

import moment from 'moment';

import Store from 'stores/Store';

const RecordFieldChange = types.model('RecordFieldChange', {
  fieldName: types.string,
  old: types.frozen(),
  new: types.frozen(),
});

const HistoricalReport = types
  .model('HistoricalReport', {
    snapshot: types.frozen(),
    version: types.number,
    timestamp: types.number,
    modelName: types.string,
    // key is full field name
    allChanges: types.array(RecordFieldChange),
  })
  .views((self) => ({
    get changes() {
      return self.allChanges.filter(
        (ch) =>
          ch.fieldName !== 'std::types/Versionable:1.version' && ch.fieldName !== 'std::types/Verisionable:1.updatedAt'
      );
    },
    get displayName() {
      const inventory = self.snapshot['std::types/Inventory:1'];
      return inventory ? inventory.displayName : self.snapshot['std::types/Root:1'].id;
    },
    get recordId() {
      return self.snapshot['std::types/Root:1'].id;
    },
    get mChangedAt() {
      return moment(self.timestamp);
    },
    get changedAt() {
      return self.mChangedAt.format('YYYY-MM-DD hh:mm:ss');
    },
  }));

const HistoricalStats = types
  .model('HistoricalStats', {
    loading: false,
    loaded: false,
    data: types.map(types.number),
  })
  .views((self) => ({
    get recordId() {
      return getParentOfType(self, InventoryRecordHistory).recordId;
    },
  }))
  .actions((self) => ({
    fetch(ts) {
      self.loading = true;

      let query = '';
      if (ts) {
        query = `?start_timestamp=${ts}`;
      }

      Store.TransportLayer.get({
        url: `/i/api/v1/record/${self.recordId}/history/stats${query}`,
        onSuccess: (response, response_data) => {
          self.saveStats(response_data.data);
        },
        onFailure: getParentOfType(self, InventoryRecordHistory).onGetHistoryAPIError,
        onFinish: self.finishLoading,
      });
    },
    saveStats(data) {
      self.data = data;
      self.loaded = true;
    },
    finishLoading() {
      self.loading = false;
    },
  }));

export const GlobalModelFilters = {
  all: 'all',
  target: 'target',
};

const InventoryRecordHistory = types
  .model('History', {
    recordId: types.maybeNull(types.string),
    reports: types.array(HistoricalReport),
    models: types.array(types.string),

    loading: false,
    loaded: false,

    // HeatMapCalendar chart displays stats for a period that starts from this
    //    ts (default to now - 1 year) to <this-ts + 1 year>
    periodStartDate: types.maybeNull(types.number),
    // Historical reports would be displayed starting from this ts in
    //    descending order (i.e events that had happened before the date)
    beforeDate: types.maybeNull(types.number),
    // Timestamp that implements "load more" button
    nextToLoad: types.maybeNull(types.number),

    modelFilter: GlobalModelFilters.target,

    Stats: types.optional(HistoricalStats, () => HistoricalStats.create({})),
  })
  .views((self) => ({
    get selectedYear() {
      return moment(self.beforeDate ? self.beforeDate : undefined).year();
    },
  }))
  .actions((self) => ({
    resetModelFilter() {
      self.modelFilter = GlobalModelFilters.all;
    },
    setModelFilter(filter) {
      if (filter !== self.modelFilter) {
        self.modelFilter = filter;
        self.fetch();
      }
    },
    fetch(next) {
      const URLParams = { size: 50 };
      if (next !== undefined) {
        URLParams.start_timestamp = next;
      } else {
        if (self.beforeDate) {
          URLParams.start_timestamp = self.beforeDate;
        } else {
          self.Stats.fetch(self.periodStartDate);
        }
        // it is a fresh call
        self.loaded = false;
      }

      if (self.modelFilter === GlobalModelFilters.target) {
        URLParams.filter_ids = [self.recordId];
      } else if (self.modelFilter !== GlobalModelFilters.all) {
        URLParams.filter_models = [self.modelFilter];
      }

      self.loading = true;
      Store.TransportLayer.get({
        url: `/i/api/v1/record/${self.recordId}/history`,
        query: URLParams,
        onSuccess: (response, response_data) => {
          self.saveHistory(response_data.data, next !== undefined);
        },
        onFailure: self.onGetHistoryAPIError,
        onFinish: self.finishLoading,
      });
    },
    finishLoading() {
      self.loading = false;
    },
    saveHistory(data, append) {
      const reports = data.changes.map((historyRecordData) => ({
        snapshot: historyRecordData.snapshot || historyRecordData.snaphost,
        version: historyRecordData.versions.new,
        timestamp: historyRecordData.timestamp,
        modelName: historyRecordData.model,
        allChanges: Object.entries(historyRecordData.changes || {}).map(([fieldName, value]) => ({
          fieldName: fieldName,
          ...value,
        })),
      }));

      if (reports.length === 0) {
        self.loaded = true;
      } else {
        if (append) {
          self.reports.push(...reports);
        } else {
          self.reports = reports;
          self.models = data.models;
          self.loaded = true;
        }

        self.nextToLoad = data.pagination.next_page;
      }
    },
    onGetHistoryAPIError() {
      Store.Notifications.error(`Failed to fetch history for '${self.recordId}' record. Please try again later.`);
      self.loaded = true;
    },
    resetSelectedDate() {
      self.startDate = null;
    },
    setBeforeDate(timestamp) {
      self.beforeDate = timestamp;
      self.nextToLoad = null;
      self.fetch();
    },
    setPeriod(startDate) {
      self.periodStartDate = startDate;
      self.Stats.fetch(self.periodStartDate);
      self.setBeforeDate(moment(self.periodStartDate).add(1, 'years').subtract(1, 'days').unix());
    },
  }));

export default InventoryRecordHistory;
