import { types } from 'mobx-state-tree';
import { jwtDecode } from 'jwt-decode';
import requests from 'lib/requests';
import { EmailInputStore, ErrorStore, PasswordInputStore } from 'components/forms/Input';
import { makePasswordEqualityValidator } from 'components/forms/Input/PasswordInputS';
export var PasswordRecoveryForm = types
    .model('PasswordRecoveryForm', {
    email: types.optional(EmailInputStore, function () { return EmailInputStore.create({ error: ErrorStore.create({}) }); }),
    error: types.maybeNull(types.string),
    completed: false,
})
    .actions(function (self) { return ({
    setError: function (message) {
        self.error = message;
    },
    setLoading: function (value) {
        self.email.setDisabled(value);
    },
    submit: function (onSuccess) {
        var _this = this;
        this.setLoading(true);
        requests.POST({
            url: "/m/api/v1/users/".concat(self.email.value, "/reset-password"),
            onFailure: function (response, errors) {
                _this.setError(errors[1]);
            },
            onSuccess: function () {
                onSuccess();
            },
            onFinish: function () { return _this.setLoading(false); },
        });
        self.completed = true;
    },
}); })
    .views(function (self) { return ({
    get filled() {
        return self.email.isDone();
    },
}); });
export var PasswordUpdateForm = types
    .model('PasswordUpdateForm', {
    token: types.maybeNull(types.string),
    newPassword1: types.optional(PasswordInputStore, function () {
        return PasswordInputStore.create({ label: 'New password', error: ErrorStore.create({}), schema: {} });
    }),
    newPassword2: types.optional(PasswordInputStore, function () {
        return PasswordInputStore.create({ label: 'Confirm new password', error: ErrorStore.create({}) });
    }),
    error: types.maybeNull(types.string),
    saving: false,
})
    .volatile(function () { return ({
    updateURLPath: function () { },
}); })
    .views(function (self) { return ({
    get email() {
        if (self.token) {
            var parsed = jwtDecode(self.token);
            return parsed.sub;
        }
        return null;
    },
}); })
    .actions(function (self) { return ({
    onPassword1Change: function () {
        if (self.newPassword2.value !== null) {
            self.newPassword2.validate();
        }
    },
    setToken: function (token) {
        self.token = token;
    },
    setSaving: function (value) {
        self.saving = value;
        self.newPassword1.setDisabled(value);
        self.newPassword2.setDisabled(value);
    },
    setError: function (message) {
        self.error = message;
        this.setSaving(false);
    },
    submit: function () {
        var _this = this;
        this.setSaving(true);
        requests.POST({
            url: "/m/api/v1/users/".concat(self.email),
            authToken: self.token,
            body: { password: self.newPassword1.value },
            onFailure: function (response, errors) {
                _this.setError(errors[1]);
            },
            onSuccess: function () {
                // FIXME
                // self.updateURLPath(`/?action=${LoginActions['password-is-updated'].urlValue}`);
            },
        });
    },
    afterCreate: function () {
        self.newPassword1.registerOnChangeHandler(this.onPassword1Change);
        self.newPassword2.addInputValidator(makePasswordEqualityValidator(self.newPassword1));
    },
}); });
