import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import FormItem from 'components/forms/FormItem';
export default observer(function (_a) {
    var store = _a.store;
    useEffect(function () { return store.componentDidMount(); }, []);
    return (React.createElement(FormItem, { onClick: function (e) { return !store.disabled && e.stopPropagation(); }, label: store.label, description: store.description, itemID: store.formID },
        React.createElement("select", { id: store.formID, name: store.name, onChange: function (e) { return store.handleSelect(e.target.value); }, value: store.defaultValue, disabled: store.disabled, ref: store.selectRef },
            store.placeholderToDisplay && (React.createElement("option", { key: "placeholder", disabled: true, value: store.formID }, store.placeholderToDisplay)),
            store.options.map(function (opt) { return (React.createElement("option", { key: opt.value, value: opt.value }, opt.title || opt.value)); }))));
});
