import React from 'react';

import { inject, observer } from 'mobx-react';

import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';

import { ChartColors } from 'Constants';

import { Spinner } from 'components/Loader';

export const ChartView = observer((props) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const data = getDataFromChart(props.data, props.threshold);
  const pieChart = (
    <PieChart width={400} height={400}>
      <Legend
        align="center"
        wrapperStyle={{
          backgroundColor: '#f5f5f5',
          border: '1px solid #d5d5d5',
          borderRadius: 3,
          lineHeight: '40px',
        }}
      />
      <Tooltip wrapperStyle={{ backgroundColor: '#ccc' }} />

      <Pie
        data={data}
        cx={200}
        cy={130}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={ChartColors[index % ChartColors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
  return (
    <div>
      <div style={{ textAlign: 'center' }}>{props.title}</div>
      {data.length > 0 ? pieChart : <div>No data</div>}
    </div>
  );
});

export const ChartLine = (props) => <div className="charts-container">{props.children}</div>;

const getDataFromChart = (chart, threshold) => {
  let rawData = [];
  const data = [];
  let total = 0;
  let others = 0;

  if (Array.isArray(chart)) {
    rawData = chart;
  } else {
    Array.from(chart.keys()).forEach((key) => {
      const value = chart.get(key);
      total += value;
      rawData.push({ name: key, value: value });
    });
  }

  if (!threshold) {
    return rawData;
  }

  rawData.forEach((value) => {
    if ((value.value / total) * 100 < threshold) {
      others += value.value;
    } else {
      data.push({ name: value.name, value: value.value });
    }
  });

  if (others > 0) {
    data.push({ name: 'Others', value: others });
  }
  return data;
};

@observer
export class RecordsByModelsChart extends React.Component {
  render() {
    if (!this.props.loaded) {
      return <Spinner />;
    }
    const chart = this.props.chart;

    return <ChartView threshold={10} title="Records by Models" data={chart.data} />;
  }
}

@inject('instance')
@observer
export class RecordsByAdminsChart extends React.Component {
  render() {
    if (!this.props.loaded) {
      return <Spinner />;
    }
    const chart = this.props.chart;
    const data = [];
    Array.from(chart.data.keys()).forEach((key) => {
      const record = this.props.instance.InventoryRecords.getById(key);
      const name = record.loaded ? record.inventory_1.displayName : key;
      data.push({ name: name, value: chart.data.get(key) });
    });

    return <ChartView threshold={10} title="Records by Admins" data={data} />;
  }
}

@observer
export class DeletedActiveChart extends React.Component {
  render() {
    if (!this.props.loaded) {
      return <Spinner />;
    }
    const chart = this.props.chart;
    const data = [
      { name: 'Deleted', value: chart.data.get('deleted') },
      { name: 'Active', value: chart.data.get('active') },
    ];
    return <ChartView title="Deleted vs Active" data={data} />;
  }
}

@inject('instance')
@observer
export class HistoricalLatestChart extends React.Component {
  render() {
    if (!this.props.loaded) {
      return <Spinner />;
    }
    const chart = this.props.chart;
    const total = chart.data.get('latest') + chart.data.get('historical');

    const latest = (chart.data.get('latest') / total) * 100;
    const historical = (chart.data.get('historical') / total) * 100;
    return (
      <div style={{ fontSize: '50px', marginLeft: '100px', marginTop: '100px' }}>
        <div>
          Latest: <span style={{ color: '#1a936f' }}>{latest.toFixed(2)}%</span>
        </div>
        <div>
          Historical: <span style={{ color: '#96cee8' }}>{historical.toFixed(2)}%</span>
        </div>
      </div>
    );
  }
}
