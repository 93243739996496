import React from 'react';
import { observer } from 'mobx-react';
import FormItem from 'components/forms/FormItem';
import Input from 'components/forms/Input/Input';
var TagItem = function (props) { return (React.createElement("div", { className: "item" },
    React.createElement("div", null, props.name),
    React.createElement("div", { className: "close-icon", onClick: function () { return props.onClick(props.name); } }, "+"))); };
export default observer(function (props) { return (React.createElement(FormItem, { error: props.store.error.formattedValue, description: props.store.description },
    React.createElement("div", { className: "item-container" },
        React.createElement("div", { className: "multipleselect-container" },
            React.createElement("div", { className: "multipleselect" },
                React.createElement("div", { className: "filtered-items" }, props.store.items.map(function (tag) { return (React.createElement(TagItem, { key: tag, name: tag, onClick: props.store.removeItem })); })),
                React.createElement("div", { className: "multipleselect-input" },
                    React.createElement(Input, { store: props.store }))))))); });
