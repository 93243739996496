import { getParent, getParentOfType, types } from 'mobx-state-tree';

import axios from 'axios';
import moment from 'moment';

import { AppSecretStates } from 'Constants';
import { ITLookWebSocket } from 'lib/itlook-websocket';

import { ErrorStore, InputStore, NoDisplayError } from 'components/forms/Input';

import { Instance } from 'stores/Instances/Instances';
import Store from 'stores/Store';

export const Chart = types.model('Chart', {
  name: types.string,
  data: types.optional(types.map(types.integer), {}),
});

export const HeatMapValue = types.model('HeatMapValue', {
  date: types.Date,
  count: types.integer,
});

export const HeatMap = types.model('HeatMap', {
  start_date: types.Date,
  end_date: types.Date,
  values: types.array(HeatMapValue),
});

const Secret = types
  .model('Secret', {
    id: types.string,
    issued_at: types.number,
    revoked: types.number,
    state: types.enumeration(Object.values(AppSecretStates)),
  })
  .views((self) => ({
    get issuedAt() {
      return moment.unix(self.issued_at);
    },
    get revokedAt() {
      if (self.state === AppSecretStates.DEACTIVATED) {
        return moment.unix(self.revoked);
      }
      return false;
    },
  }));

const SecretsManager = types
  .model('SecretsManager', {
    // used to control secrets list
    loading: false,
    // used to control secret rotation
    processing: false,
    loaded: false,
    justCreatedSecretId: types.maybeNull(types.string),
    justCreatedSecret: types.maybeNull(types.string),
    items: types.array(Secret),
  })
  .actions((self) => ({
    push(secrets) {
      let thereIsAlreadyOnActive = false;

      self.items = secrets
        .slice()
        .sort((s1, s2) => {
          return s2.issued_at - s1.issued_at;
        })
        .map((s) => {
          let state;
          if (s.revoked > 0) {
            state = AppSecretStates.DEACTIVATED;
          } else if (thereIsAlreadyOnActive) {
            state = AppSecretStates.RETIRING;
          } else {
            state = AppSecretStates.LATEST;
            thereIsAlreadyOnActive = true;
          }
          return Secret.create({ state: state, ...s });
        });
      self.loaded = true;
    },
    fetch() {
      self.loading = true;

      Store.TransportLayer.get({
        url: `/i/api/v1/apps/${self.appId}/secrets/`,
        onSuccess: (response, response_data) => {
          self.push(response_data.data);
        },
        onFinish: self.finishLoading,
      });
    },
    finishLoading() {
      self.loading = false;
    },
    finishProcessing() {
      self.processing = false;
    },
    startRotation() {
      self.processing = true;
      Store.TransportLayer.post({
        url: `/i/api/v1/apps/${self.appId}/secrets/rotate`,
        onSuccess: self.saveJustCreated,
        onFinish: self.finishProcessing,
      });
    },
    saveJustCreated(resp, resp_data) {
      self.justCreatedSecretId = resp_data.data.secret_id;
      self.justCreatedSecret = resp_data.data.secret;
    },
    finishRotation() {
      Store.TransportLayer.delete({
        url: `/i/api/v1/apps/${self.appId}/secrets/rotate`,
        onSuccess: () => {
          self.fetch();
          self.resetJustCreated();
        },
      });
    },
    resetJustCreated() {
      self.justCreatedSecretId = null;
      self.justCreatedSecret = null;
    },
  }))
  .views((self) => ({
    get appId() {
      return getParent(self).id;
    },
    get activeCount() {
      return self.items.filter((secret) => secret.state !== AppSecretStates.DEACTIVATED).length;
    },
  }));

const ApplicationStatsDetailed = types
  .model('ApplicationStatsDetailed', {
    applicationId: types.maybeNull(types.string),
    loading: false,
    loaded: false,
    // actual stats
    charts: types.array(Chart),
    heatmap: types.maybeNull(HeatMap),
    systems: 0,
    records: types.optional(types.map(types.number), {}),
  })
  .actions((self) => ({
    fetch(applicationId) {
      self.applicationId = applicationId;
      self.loading = true;

      Store.TransportLayer.get({
        url: `/i/api/v1/apps/${self.applicationId}/stats`,
        onSuccess: (response, response_data) => {
          self.saveStats(response, response_data);
        },
      });
    },
    saveStats(response, response_data) {
      self.charts = response_data.data.charts.map((c) => Chart.create({ name: c.name, data: c.data }));
      const heatmapValues = response_data.data.changes_heatmap.values.map((val) => {
        return HeatMapValue.create({ date: new Date(val.date), count: val.count });
      });
      self.heatmap = HeatMap.create({
        start_date: new Date(response_data.data.changes_heatmap.start_date),
        end_date: new Date(response_data.data.changes_heatmap.enf_date),
        values: heatmapValues,
      });
      self.finishLoading();
    },
    finishLoading() {
      self.loaded = true;
      self.loading = false;
    },
    getChartByName(chart) {
      return self.charts.find((i) => i.name === chart);
    },
  }));

const Application = types
  .model('Application', {
    id: types.identifier,
    externalID: types.maybeNull(types.string),
    applicationType: types.string,
    status: types.string,
    state: types.string,
    name: types.string,
    description: types.string,
    createdAt: types.number,
    updatedAt: types.number,
    managedBySystem: types.boolean,

    DetailedStats: types.optional(ApplicationStatsDetailed, () => ApplicationStatsDetailed.create({})),
    Secrets: types.optional(SecretsManager, () => SecretsManager.create({})),
  })
  .views((self) => ({
    get mCreatedAt() {
      return moment(self.createdAt);
    },
    get mUpdatedAt() {
      return moment(self.updatedAt);
    },
    get instance() {
      return getParentOfType(self, Instance);
    },
  }))
  .actions((self) => ({
    tunnel: async () => {
      const result = await self.instance.api.get(`/i/api/v1/tunnels/tunnel_for_app/${self.id}`);
      return {
        url: `${result.data.data.tunnel_url}/app/${self.id}`,
        token: result.data.data.token,
      };
    },
    api: async () => {
      const tunnel = await self.tunnel();
      return axios.create({
        baseURL: tunnel.url,
        headers: {
          Authorization: `Bearer ${tunnel.token}`,
        },
      });
    },
    socket: async (socketType, { onOpen, onData, onError = null, onClose = null, initAtStart = true } = {}) => {
      const tunnel = await self.tunnel();
      const wsurl = `${tunnel.url.replace('http', 'ws')}/api/v1/ws`;
      const ws = new ITLookWebSocket(`${wsurl}?token=${tunnel.token}&type=${socketType}`, {
        onOpen,
        onData,
        onError,
        onClose,
        initAtStart,
      });
      if (initAtStart) {
        await ws.connected();
      }
      return ws;
    },
  }));

export default types
  .model('Applications', {
    apps: types.map(Application),
    loading: false,
    loaded: false,
    error: types.maybeNull(types.boolean),
    errorMsg: types.maybeNull(types.string),
  })
  .views((self) => ({
    get instance() {
      return getParentOfType(self, Instance);
    },
    filter(applicationType) {
      return Array.from(self.apps.values()).filter((app) => app.applicationType === applicationType);
    },
    mapAppDataToModel: (app) => {
      return {
        id: app.id,
        externalID: app.external_id,
        applicationType: app.application_type,
        name: app.name,
        description: app.description,
        state: app.state,
        status: app.status,
        // TODO(andreykurilin): use app.last_heartbeat_at
        createdAt: app.created_at,
        updatedAt: app.updated_at,
        managedBySystem: app.managed_by_system,
      };
    },
  }))
  .actions((self) => ({
    updateAppsData(applications) {
      applications.forEach((app) => {
        self.apps.set(app.id, self.mapAppDataToModel(app));
      });
    },
    updateAppData: (app) => {
      self.apps.set(app.id, self.mapAppDataToModel(app));
    },
    startLoading: () => {
      self.error = null;
      self.errorMsg = null;
      self.loading = true;
    },
    finishLoading: () => {
      self.loading = false;
      self.loaded = true;
    },
    setError: (error) => {
      self.error = true;
      self.errorMsg = error.toString();
      console.log(self.errorMsg);
    },
    fetch: async () => {
      if (self.loading) {
        return;
      }
      self.startLoading();
      try {
        const resp = await self.instance.api.get('/i/api/v1/apps');
        self.updateAppsData(resp.data.data.apps);
      } catch (error) {
        self.setError(error);
      } finally {
        self.finishLoading();
      }
    },
    create: (application, onSuccessCreate, onFailedCreate) => {
      Store.TransportLayer.post({
        url: '/i/api/v1/apps',
        body: application,
        onFailure: (e, errors) => onFailedCreate(errors),
        onSuccess: onSuccessCreate,
      });
    },
    update: (applicationId, application, onSuccessUpdate, onFailedUpdate) => {
      Store.TransportLayer.put({
        url: `/i/api/v1/apps/${applicationId}`,
        body: application,
        onFailure: (e, errors) => onFailedUpdate(errors),
        onSuccess: onSuccessUpdate,
      });
    },
    delete: (applicationID, onSuccess, onFailure) => {
      self.apps.delete(applicationID);
      const url = `/i/api/v1/apps/${applicationID}`;
      Store.TransportLayer.delete({
        url: url,
        onFailure: onFailure,
        onSuccess: onSuccess,
      });
    },
    getById: async (id) => {
      if (!self.apps.has(id)) {
        // TODO handlers for all problems
        // - Not found app
        // - Internal error
        const resp = await self.instance.api.get(`/api/v1/apps/${id}`);
        self.updateAppData(resp.data);
      }
      return self.apps.get(id);
    },
  }));

export const EditForm = types
  .model('EditForm', {
    id: types.string,
    name: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Name',
        placeholder: 'New name for agent',
        error: ErrorStore.create({}),
      })
    ),
    description: types.optional(InputStore, () =>
      InputStore.create({
        label: 'Description',
        placeholder: 'New description',
        error: NoDisplayError.create({}),
      })
    ),
    updatingEntity: false,
    error: types.maybeNull(types.string),
  })
  .views((self) => ({
    hasSomethingToUpdate() {
      return self.name.value || self.description.value;
    },
  }))
  .volatile(() => ({
    onUpdate: () => {},
  }))
  .actions((self) => ({
    registerOnUpdate(handler) {
      self.onUpdate = handler;
    },
    setUpdatingEntity(value) {
      self.updatingEntity = value;
      self.name.setDisabled(value);
      self.description.setDisabled(value);
    },
    tryUpdate(applicationsStore) {
      self.setUpdatingEntity(true);
      self.error = null;
      const body = {};
      if (self.name.value) {
        body.name = self.name.value;
      }
      if (self.description.value) {
        body.description = self.description.value;
      }
      applicationsStore.update(self.id, body, self.onSuccessUpdate, self.onFailedUpdate);
    },
    onSuccessUpdate() {
      self.setUpdatingEntity(false);
      self.onUpdate();
    },
    onFailedUpdate(e) {
      self.setUpdatingEntity(false);
      self.error = e;
    },
  }));
