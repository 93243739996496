import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import FormItem from 'components/forms/FormItem';
var AutoCompleteItem = function (props) {
    var item = props.selected ? React.createElement("b", null, props.item) : props.item;
    return React.createElement("li", { onClick: props.onClick }, item);
};
var AutoCompleteOptionsBlock = observer(function (props) {
    if (!props.store.autocompleteOptions || !props.store.focused) {
        return null;
    }
    return (React.createElement("div", { className: "autocomplete-container" },
        React.createElement("div", { className: "autocomplete" },
            React.createElement("ul", { className: "autocomplete-results" }, props.store.autocompleteOptions.map(function (item, index) { return (React.createElement(AutoCompleteItem, { key: item, item: item, selected: props.store.selectedAutocompleteItem === index, onClick: function () { return props.store.handleOnAutoCompleteItemClick(index); } })); })))));
});
export default observer(function (props) {
    useEffect(function () {
        props.store.componentDidMount();
    }, []);
    return (React.createElement(FormItem, { label: props.store.formItemLabel, required: props.store.required, labelDescription: props.store.labelDescription, description: props.store._passDescriptionToFormItem ? props.store.description : undefined, error: props.store._passErrorToFormItem ? props.store.error.formattedValue : undefined, className: props.store.formItemClassName, thin: props.store.thin },
        React.createElement("div", { className: "item-container" },
            React.createElement("input", { type: props.store.inputType, id: props.store.inputId, name: props.store.name, placeholder: props.store.finalInputPlaceholder, 
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus: props.store.autoFocus !== undefined && props.store.autoFocus, disabled: props.store.disabled, onChange: props.store.handleOnChange, onKeyDown: props.store.handleOnKeyDown, className: props.store.finalInputClassName, style: props.store.inputStyle, ref: props.store.inputRef, onFocus: props.store.handleOnFocus, onBlur: props.store.handleOnFocusOut }),
            props.store.showPlaceholderAfterInput && (React.createElement("label", { htmlFor: props.store.inputId },
                props.store.label,
                props.store.required && React.createElement("b", null),
                props.store.labelDescription !== undefined && (React.createElement("div", { className: "description" }, props.store.labelDescription)))),
            props.children),
        React.createElement(AutoCompleteOptionsBlock, { store: props.store })));
});
