import 'regenerator-runtime/runtime';

export class ITLookWebSocket {
  constructor(connection_uri, { onOpen, onData, onError, onClose, initAtStart = true }) {
    this._connection_uri = connection_uri;
    this._onOpen = onOpen;
    this._onData = onData || (() => ({}));
    this._onClose = onClose || (() => ({}));
    this._onError =
      onError ||
      ((event) => {
        console.log(event);
      });
    this._onOpenHandlers = [];
    this._requestsToHandler = {};

    if (initAtStart) {
      this.connect();
    }
  }

  connect() {
    this._socket = new WebSocket(this._connection_uri);
    this._socket.binaryType = 'arraybuffer';
    this._socket.addEventListener('message', (event) => this.onMessage(event));
    this._socket.addEventListener('close', () => {});
    this._socket.addEventListener('error', (event) => this._onError(event));
    this._socket.addEventListener('open', () => {
      if (this._onOpen) {
        this.afterOpen(this._onOpen);
      }
      this.onOpen();
    });
  }

  connected() {
    return new Promise((resolve) => {
      this.afterOpen(resolve);
    });
  }

  onMessage(message) {
    const payload = JSON.parse(message.data);
    if (payload.rpc) {
      this._requestsToHandler[payload.rpc.request_id](payload.rpc.response);
    } else if (payload.data !== undefined) {
      this._onData(payload.data);
    } else {
      console.log(`received non supported payload ${JSON.stringify(payload, null, 2)}`);
    }
  }

  onOpen() {
    this._onOpenHandlers.forEach((callback) => callback());
  }

  afterOpen(callback) {
    // this._onOpenHandlers.push(callback);
    // Socket has been created. The connection is not yet open
    if (this._socket.readyState === 0) {
      this._onOpenHandlers.push(callback);
    }
    // The connection is open and ready to communicate
    else if (this._socket.readyState === 1) {
      callback();
    } else {
      console.log('Error: socket is about to been closed');
      throw new Error('Socket is about to been closed');
    }
  }

  async send_stream(payload) {
    this._socket.send(JSON.stringify({ data: payload }));
  }

  async send(reqId, payload) {
    const promise = new Promise((resolutionFunc) => {
      this._requestsToHandler[reqId] = resolutionFunc;
    });

    this._socket.send(JSON.stringify(payload));
    return promise;
  }

  async rpc(cmd, args) {
    const reqId = crypto.randomUUID();

    args = args || {};
    return this.send(reqId, {
      rpc: {
        request_id: reqId,
        request: {
          cmd,
          args,
        },
      },
    });
  }
}
