import { getParent, getParentOfType, resolveIdentifier, types } from 'mobx-state-tree';

import moment from 'moment';

import { bytesToString } from 'lib/string-utils';

import { Instance } from 'stores/Instances/Instances';
import RecordHistory from 'stores/Instances/InventoryRecordHistory';
import Store from 'stores/Store';

const AggregatedFieldStats = types.model('AggregatedFieldStats', {
  timestamp: types.number,
  stats: types.map(types.number),
});

export const RecordFieldStats = types.model('RecordFieldStats', {
  id: types.identifier,
  fieldName: types.string,
  stats: types.array(AggregatedFieldStats),
});

const _RESOURCE_CACHE_TTL = 60 * 60 * 1000; // 1 hour in ms

const InventoryPart_1 = types.model('std::types/Inventory:1', {
  displayName: types.string,
  description: types.maybeNull(types.string),
});

const StatusablePart_1 = types.model('std::types/Statusable:1', {
  status: types.string,
  statusDescription: types.maybeNull(types.string),
});

const RootPart_1 = types.model('std::types/Root:1', {
  id: types.string,
  app: types.string,
  labels: types.array(types.string),
});

const VersionablePart_1 = types.model('std::types/Versionable:1', {
  version: types.integer,
  createdAt: types.number,
  updatedAt: types.number,
});

export const HostPart_1 = types
  .model('std::host/Host:1', {
    accessIP: types.string,
    accessUser: types.string,
    hostname: types.string,
    ips: types.array(types.string),
  })
  .actions((self) => ({
    delete() {
      const record = getParentOfType(self, Record);
      const socket = getParentOfType(self, Instance).getSocket();
      return new Promise((resolve) => {
        socket.afterOpen(async () => {
          const app = socket.ensureAppConnection(record.root_1.app);
          const result = await app.rpc('host.delete', { id: record.id });
          return resolve(result.ok ? null : result.error);
        });
      });
    },
  }));

const ContainerPart_1 = types.model('std::host/Container:1', {
  name: types.string,
  imageName: types.string,
  host: types.string,
  runtime: types.string,
  command: types.string,
});

export const ScriptPart_1 = types
  .model('std::system/Script:1', {
    description: types.maybeNull(types.string),
    command: types.array(types.string),
    syntax: types.string,
    code: types.array(types.string),
    createdBy: types.maybeNull(types.string),
  })
  .actions((self) => ({
    delete() {
      const instance = Store.Instances.getCurrent();
      const record = getParentOfType(self, Record);
      return new Promise((resolve) => {
        instance.TransportLayer.delete({
          url: `/i/api/v1/scripts/${record.root_1.id}`,
          onSuccess: () => resolve(),
          onFailure: (response, errors) => resolve(errors.join(' ') || 'Error'),
        });
      });
    },
  }))
  .views((self) => ({
    get parentLoaded() {
      return getParent(self).fs_node_1.isLoaded;
    },
    get path() {
      const absPath = getParent(self).fs_node_1.absPath;

      return (absPath && `/${absPath.join('/')}`) || '';
    },
    get fileName() {
      return getParent(self).fs_node_1.name;
    },
    get dirName() {
      return self.path.slice(0, -(self.fileName.length + 1));
    },
  }));

const FSNode_1 = types
  .model('std::system/FSNode:1', {
    name: types.string,
    parentNode: types.maybeNull(types.string),
    loaded: types.optional(types.boolean, false),
    parentNodeRef: types.optional(types.maybeNull(types.late(() => FSNode_1)), null),
  })
  .views((self) => ({
    get isLoaded() {
      if (self.loaded) {
        if (!self.parentNode) {
          return true;
        }
        return self.parentNodeRef.isLoaded;
      }
      return false;
    },
    get absPath() {
      if (!self.isLoaded) {
        return null;
      }
      if (!self.parentNode) {
        return [];
      }
      return [...self.parentNodeRef.absPath, self.name];
    },
  }))
  .actions((self) => ({
    async afterAttach() {
      if (self.parentNode) {
        const record = await getParentOfType(self, InventoryRecords).getByIdAsync(self.parentNode);
        self.setParent(record.data.toJSON()['std::system/FSNode:1']);
      }
      self.setLoaded();
    },
    setParent(parent) {
      self.parentNodeRef = parent;
    },
    setLoaded() {
      self.loaded = true;
    },
    getAbsPath() {
      if (self.absPath) {
        return self.absPath;
      }
    },
  }));

const ScriptRunPart_1 = types
  .model('std::host/ScriptRun:1', {
    script: types.string,
    scriptVersion: types.integer,
    targets: types.array(types.string),
    startedAt: types.number,
    completedAt: types.maybeNull(types.number),
    queuedAt: types.number,
    state: types.string,
    exitCode: types.maybeNull(types.integer),
    arguments: types.string,
    user: types.string,
    pid: types.maybeNull(types.number),
  })
  .views((self) => ({
    get scriptObj() {
      if (self.script) {
        return getParentOfType(self, InventoryRecords).getById(self.script);
      }
      return null;
    },
  }))
  .actions((self) => ({
    cancel() {
      const socket = Store.Instances.getCurrent().getSocket();
      const record = getParentOfType(self, Record);
      socket.afterOpen(async () => {
        const body = {
          script_run_id: record.root_1.id,
          kill: true,
        };
        const app = socket.ensureAppConnection(record.root_1.app);
        const res = await app.rpc('script.stop', body);
        if (res.code !== 2000) {
          Store.Notifications.error(res.message);
        }
      });
    },
  }));

const SessionPart_1 = types
  .model('std::host/Session:1', {
    startedAt: types.number,
    finishedAt: types.maybeNull(types.number),
    xtermRows: types.number,
    xtermCols: types.number,
    xtermShell: types.string,
    state: types.string,
    trafficIn: types.integer,
    trafficOut: types.integer,
    cause: types.maybeNull(types.string),
    user: types.string,
    target: types.optional(types.string, ''),
  })
  .views((self) => ({
    get targetObj() {
      if (self.target) {
        return getParentOfType(self, InventoryRecords).getById(self.target);
      }
      return null;
    },
    get humanFriendlyTrafficIn() {
      return bytesToString(self.trafficIn);
    },
    get humanFriendlyTrafficOut() {
      return bytesToString(self.trafficOut);
    },
  }));

export const AppPart_1 = types
  .model('std::system/App:1', {
    applicationType: types.string,
    state: types.string,
    externalId: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
  })
  .actions((self) => ({
    delete() {
      const instance = Store.Instances.getCurrent();
      const record = getParentOfType(self, Record);
      return new Promise((resolve) => {
        instance.Applications.delete(
          record.id,
          () => resolve(false),
          (response, errors) => resolve('. '.join(errors))
        );
      });
    },
  }));

const AnyBasicType = [types.null, types.integer, types.string, types.boolean, types.number];

const AnyArrayType = types.array(types.union(...AnyBasicType));

export const Record = types
  .model('Record', {
    id: types.identifier,
    loaded: true,
    loading: false,
    notFound: false,
    fetchedAt: types.maybeNull(types.Date),

    data: types.map(types.union(types.map(types.union(AnyArrayType, ...AnyBasicType)), AnyArrayType, ...AnyBasicType)),
    inventory_1: types.maybeNull(InventoryPart_1),
    statusable_1: types.maybeNull(StatusablePart_1),
    root_1: types.maybeNull(RootPart_1),
    versionable_1: types.maybeNull(VersionablePart_1),
    host_1: types.maybeNull(HostPart_1),
    container_1: types.maybeNull(ContainerPart_1),
    script_1: types.maybeNull(ScriptPart_1),
    fs_node_1: types.maybeNull(FSNode_1),
    script_run_1: types.maybeNull(ScriptRunPart_1),
    session_1: types.maybeNull(SessionPart_1),
    app_1: types.maybeNull(AppPart_1),

    // record details
    history: types.optional(RecordHistory, () => RecordHistory.create({})),
  })
  .views((self) => ({
    get model() {
      return self.data.get('@model');
    },
    get logo() {
      return Store.Models.getPicture(self.model);
    },
    get createdAt() {
      return moment(self.versionable_1.createdAt);
    },
    get updatedAt() {
      return moment(self.versionable_1.updatedAt);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.history.recordId = self.id;
    },
    updateProperties(props) {
      self.fetchedAt = Date.now();
      if (props.notFound) {
        self.loaded = true;
        self.notFound = true;
        // no need to continue check other properties
        return;
      }

      self.data = props;
      self.root_1 = RootPart_1.create(props[RootPart_1.name]);

      [
        { part: VersionablePart_1.name, field: 'versionable_1' },
        { part: InventoryPart_1.name, field: 'inventory_1' },
        { part: StatusablePart_1.name, field: 'statusable_1' },
        { part: ScriptPart_1.name, field: 'script_1' },
        { part: FSNode_1.name, field: 'fs_node_1' },
        { part: AppPart_1.name, field: 'app_1' },
        { part: HostPart_1.name, field: 'host_1' },
        { part: ContainerPart_1.name, field: 'container_1' },
        { part: ScriptRunPart_1.name, field: 'script_run_1' },
        { part: SessionPart_1.name, field: 'session_1' },
      ].forEach((m) => {
        if (props.hasOwnProperty(m.part)) {
          self[m.field] = props[m.part];
        }
      });
      self.loaded = true;
    },
    update(onReady) {
      if (self.notFound) {
        // nothing to do here
        return;
      }
      self.loading = true;
      Store.TransportLayer.get({
        url: `/i/api/v1/record/${self.id}`,
        onSuccess: (response, response_data) => {
          self.updateProperties(response_data.data);
          if (onReady !== undefined) {
            onReady(self);
          }
        },
        onFailure: () => {
          // NOTE(andreykurilin): IDK the alternative to 404 that can be
          //  raised at this call. 5xx cases are handled globally,
          //  as like Forbidden/Unauthorized, so setting notFound should be ok
          self.updateProperties({ notFound: true });
        },
        onFinish: self.finishLoading,
      });
    },
    finishLoading() {
      self.loading = false;
    },
    checkTheNeedForUpdates() {
      return !self.loading && (self.fetchedAt === null || Date.now() - self.fetchedAt.getTime() > _RESOURCE_CACHE_TTL);
    },
  }));

const InventoryRecords = types
  .model('InventoryRecords', {
    _cache: types.array(Record),
  })
  .views((self) => ({
    getAllByModel(model) {
      return self._cache.filter((r) => r.model === model);
    },
  }))
  .actions((self) => ({
    search(searchQuery, start, size, onSuccess, onFailure, sortByField, reverseOrder, onCrash, newTable) {
      const urlParams = { query: searchQuery, start: start, size: size || 25 };
      if (sortByField) {
        urlParams.sort_by = sortByField;
      }
      if (reverseOrder !== undefined) {
        urlParams.sort_order = reverseOrder ? 'desc' : 'asc';
      }
      if (newTable) {
        urlParams.resolve_all = true;
      }
      Store.TransportLayer.get({
        url: '/i/api/v1/record/search',
        query: urlParams,
        onSuccess: (response, responseBody) => {
          const foundRecords = [];
          const allRecords = [...responseBody.data.matches, ...Object.values(responseBody.data.resolved)];
          allRecords.forEach((record) => {
            const id = record['std::types/Root:1'].id;
            let cached_record = resolveIdentifier(Record, self._cache, id);
            if (cached_record === undefined) {
              cached_record = self.pushItem({ id: id });
            }
            cached_record.updateProperties(record);
            foundRecords.push(id);
          });
          if (newTable) {
            onSuccess(response, responseBody);
          }
          onSuccess(foundRecords, responseBody.data.pagination, responseBody.data.models);
        },
        onFailure: onFailure,
        onCrash: onCrash,
      });
    },

    search_v2({ query, start = 0, size = 25, sortByField, reverseOrder, resolveAll = false }) {
      const urlParams = {
        query: query,
        start: start,
        size: size,
      };
      if (sortByField) {
        urlParams.sort_by = sortByField;
      }
      if (reverseOrder !== undefined) {
        urlParams.sort_order = reverseOrder ? 'desc' : 'asc';
      }
      urlParams.resolve_all = resolveAll;

      const promise = new Promise((resolve, reject) => {
        // todo handle this better
        const rejectHandler = () => reject(new Error('Failed to fetch data'));

        Store.TransportLayer.get({
          url: '/i/api/v1/record/search',
          query: urlParams,
          onSuccess: (response, responseBody) => {
            const foundRecords = [];
            const foundRecordsIds = new Set();
            const allRecords = [...responseBody.data.matches, ...Object.values(responseBody.data.resolved)];
            allRecords.forEach((record) => {
              const id = record['std::types/Root:1'].id;
              let cached_record = resolveIdentifier(Record, self._cache, id);
              if (cached_record === undefined) {
                cached_record = self.pushItem({ id: id });
              }
              cached_record.updateProperties(record);
              foundRecords.push(cached_record);
              foundRecordsIds.add(id);
            });
            resolve({
              recordsIds: foundRecordsIds,
              records: foundRecords,
              allRecords: allRecords,
              pagination: responseBody.data.pagination,
              models: responseBody.data.models,
              response: response,
              responseBody: responseBody,
            });
          },
          onFailure: rejectHandler,
          onCrash: rejectHandler,
        });
      });
      return promise;
    },

    getById(id, onReady, force) {
      let record = resolveIdentifier(Record, self._cache, id);
      if (force && record) {
        const index = self._cache.indexOf(record);
        self._cache.splice(index, 1);
        record = undefined;
      }
      if (record === undefined) {
        record = self.pushItem({
          id: id,
          loaded: false,
        });
      }
      if (record.checkTheNeedForUpdates()) {
        record.update(onReady);
      } else if (onReady !== undefined) {
        let timeLeft = 60 * 1000;
        const checker = () => {
          if (record.loaded) {
            onReady(record);
          } else if (timeLeft) {
            timeLeft -= 100;
            setTimeout(checker, 100);
          }
        };
        checker();
      }
      return record;
    },
    getByIdAsync(id) {
      const promise = new Promise((resolutionFunc) => {
        self.getById(id, () => {
          resolutionFunc(self.getById(id));
        });
      });
      return promise;
    },
    getMultiByIDs(IDs, onReady) {
      const IDsToFetch = IDs.filter((recordID) => {
        const record = resolveIdentifier(Record, self._cache, recordID);
        if (record !== undefined) {
          return record.checkTheNeedForUpdates();
        }
        self.pushItem({
          id: recordID,
          loaded: false,
          // we do not load entity yet, but need to set it here so follow up
          // getById calls do not trigger parallel calls to API
          loading: true,
        });

        return true;
      });
      if (IDsToFetch.length > 0) {
        Store.TransportLayer.get({
          url: '/i/api/v1/record/mget',
          query: { id: IDsToFetch },
          onSuccess: (response, response_data) => {
            self.initMulti(IDsToFetch, response_data.data);
            if (onReady !== undefined) {
              onReady(IDs);
            }
          },
        });
      } else {
        // everything is loaded!
        if (onReady !== undefined) {
          onReady(IDs);
        }
      }
    },

    initMulti(requestedIDs, result) {
      requestedIDs.forEach((recordID) => {
        const record = resolveIdentifier(Record, self._cache, recordID);
        if (!result.hasOwnProperty(recordID)) {
          record.updateProperties({ notFound: true });
        } else {
          record.updateProperties(result[recordID]);
        }
      });
    },
    pushItem(data) {
      const record = Record.create(data);
      self._cache.push(record);
      return record;
    },
    hasItem(id) {
      return resolveIdentifier(Record, self._cache, id) !== undefined;
    },
    fetchFieldStats(ids, fieldName, start_timestamp, stop_timestamp, interval, onSuccess, onFailure) {
      Store.TransportLayer.get({
        url: '/i/api/v1/record/mfield_stats',
        query: {
          id: ids,
          field: fieldName,
          start_timestamp: start_timestamp,
          stop_timestamp: stop_timestamp,
          interval: interval,
        },
        onSuccess: (response, response_data) => {
          self.saveFieldStats(response_data, fieldName, onSuccess);
        },
        onFailure: onFailure,
      });
    },
    saveFieldStats(response_data, fieldName, onSuccess) {
      const splittedFieldName = fieldName.split('.', 2);
      const modelName = splittedFieldName[0];
      const croppedFieldName = splittedFieldName[1];
      const res = Object.fromEntries(
        Object.keys(response_data.data.results).map((recordId) => {
          const rawStats = response_data.data.results[recordId].data;

          // dirty fix for the case when historical stats are missing and API
          // returns null
          if (rawStats.length > 0) {
            const firstElem = rawStats[0][response_data.data.field_type];
            const lastElem = rawStats[rawStats.length - 1][response_data.data.field_type];
            if (firstElem.distribution.null === 1 && lastElem.distribution.null === 1) {
              if (this.hasItem(recordId)) {
                const currentValue = this.getById(recordId).data.get(modelName).get(croppedFieldName);
                const distribution = {};
                distribution[currentValue] = 1;
                rawStats.forEach((stat) => {
                  stat[response_data.data.field_type].distribution = distribution;
                });
              }
            }
          }

          const stats = RecordFieldStats.create({
            id: recordId,
            fieldName: fieldName,
            stats: rawStats.map((stat) => ({
              timestamp: stat.timestamp,
              stats: stat[response_data.data.field_type].distribution,
            })),
          });

          return [recordId, stats];
        })
      );
      onSuccess(res);
    },
    mstats(query, fieldName) {
      return new Promise((resolve, reject) => {
        Store.TransportLayer.get({
          url: '/i/api/v1/record/mstats',
          query: { query: query, field: fieldName },
          onSuccess: (response, responseData) => resolve(responseData),
          onFailure: reject,
        });
      });
    },
  }));

export default InventoryRecords;
