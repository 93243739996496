import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { UserRoles } from 'Constants';
import Input, { ErrorStore } from 'components/forms/Input';
import InputS from 'components/forms/Input/InputS';
import ReadOnlyInput from 'components/forms/Input/ReadOnlyInput';
import Switcher, { FormItemWithLabel } from 'components/forms/Switcher';
import { LeftPanel, SidedPanel } from 'components/layout/Panels';
import { Spinner } from 'components/Loader';
var domain_validator = function (value) {
    if (!value.match('^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$')) {
        return 'Domain is not valid.';
    }
    return false;
};
var NewWildcardDomainInputField = inject('org')(observer(function (props) {
    var inputStore = useState(InputS.create({
        error: ErrorStore.create(),
    }))[0];
    useEffect(function () {
        inputStore.addInputValidator(domain_validator);
        inputStore.addInputValidator(function (value) {
            if (props.existingDomains && props.existingDomains.includes(value)) {
                return 'Domain is already registered.';
            }
            return false;
        });
    }, []);
    var onCreate = function () {
        if (!inputStore.value) {
            inputStore.setError('Domain is empty.');
        }
        else if (inputStore.isDone()) {
            props.org.Settings.access.addWildcardDomain(inputStore.value, function () { }, function (response, errors) { return inputStore.setError(errors.join(' ')); });
        }
    };
    return (React.createElement(Input, { store: inputStore },
        React.createElement("button", { className: "btn-only-icon minus", type: "button", onClick: function () {
                inputStore.setValue('');
                inputStore.setError('');
            } }),
        React.createElement("button", { key: "add", className: "btn-only-icon plus", type: "button", onClick: onCreate, disabled: !inputStore.isDone() })));
}));
var OAuth2WildcardDomainsBox = inject('org')(observer(function (props) { return (React.createElement("div", { className: "form-box" },
    React.createElement(FormItemWithLabel, { label: "OAuth2 wildcard domains", description: "Allow to self-create user account for users with email addresses from the specified domain(s)." }),
    props.org.Settings.access.wildcardDomains.map(function (value, index) { return (React.createElement(ReadOnlyInput, { key: "oauth2-wildcard-domain-".concat(value), value: value },
        React.createElement("button", { className: "btn-only-icon minus", type: "button", onClick: function () { return props.org.Settings.access.deleteWildcardDomain(index); } }))); }),
    React.createElement(NewWildcardDomainInputField, { key: 
        /* make key depend on the length of the domain, so onCreate event will clean active input field */
        "oauth2-wildcard-domain-new-".concat(props.org.Settings.access.wildcardDomains.length), existingDomains: props.org.Settings.access.wildcardDomains }))); }));
var AccessSettings = inject('org')(observer(function (props) {
    var settings = props.org.Settings;
    var wildcardInput = useState(InputS.create({
        description: 'User creation is allowed to users with email addresses from the specified domain',
        formItemClassName: 'domain',
        value: settings.access.wildcardDomains ? settings.access.wildcardDomains[0] : undefined,
        label: 'Domain',
        error: ErrorStore.create(),
    }))[0];
    useEffect(function () {
        wildcardInput.addInputValidator(domain_validator);
        wildcardInput.registerOnChangeHandler(function (value) {
            if (wildcardInput.isDone()) {
                settings.access.setWildcardDomains(value);
            }
        });
    }, []);
    if (!settings.loaded) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(SidedPanel, null,
        React.createElement(LeftPanel, null,
            React.createElement("h3", null, "Access"),
            React.createElement("form", null,
                React.createElement("div", { className: "form-box" },
                    React.createElement(Switcher, { key: "oauth2-enabled", itemID: "oauth2-enabled", label: "Authentication via OAuth2", checked: settings.access.OAuth2Enabled, onChange: settings.access.toggleOAuth2Enabled })),
                React.createElement("div", { className: "form-box" },
                    React.createElement(Switcher, { key: "oauth2-only-domain-box", itemID: "oauth2-only-domain-box", label: "OAuth2 Only auth", description: "Restrict authentication via email&password flow.", checked: settings.access.OAuth2Only, onChange: settings.access.toggleOAuth2Only, disabled: !settings.access.OAuth2Enabled })),
                React.createElement(OAuth2WildcardDomainsBox, null),
                React.createElement("div", { className: "form-box" },
                    React.createElement(Switcher, { key: "oauth2-default-user-role", itemID: "oauth2-default-user-role", label: "Assign Admin role to new OAuth2 user.", description: "Whether to assign admin to an unregistered user that logged in via OAuth2 wildcard domain option.", checked: UserRoles.ADMIN === settings.access.wildcardNewUserRole, disabled: !settings.access.OAuth2Enabled, onChange: function () {
                            return settings.access.setWildcardNewUserRole(UserRoles.ADMIN === settings.access.wildcardNewUserRole ? UserRoles.USER : UserRoles.ADMIN);
                        } })),
                React.createElement("div", { className: "form-box" },
                    React.createElement(Input, { store: wildcardInput }),
                    React.createElement("div", { className: "item-container" },
                        React.createElement("button", { className: "btn btn-primary", type: "button", onClick: settings.access.saveWildcardDomains }, "Save domain")))))));
}));
export default inject('org')(observer(function (_a) {
    var org = _a.org;
    useEffect(function () {
        if (!org.Settings.loading) {
            org.Settings.fetch();
        }
    }, []);
    return React.createElement(AccessSettings, null);
}));
