import { cast, types } from 'mobx-state-tree';
import InputStore from 'components/forms/Input/InputS';
var findTags = function (rawValue, enterIsPressed, separators) {
    if (separators === void 0) { separators = ' ,;'; }
    var foundTags = [];
    var trimmedValue = rawValue.trim();
    var newValue = trimmedValue;
    // prepare for parsing
    var startWindow = 0;
    var pointer = -1;
    var quoteStartedWithChar;
    var quotedTag = false;
    while (pointer < trimmedValue.length + 1) {
        var currentChar = void 0;
        if (pointer === trimmedValue.length) {
            currentChar = enterIsPressed || separators.includes(rawValue[rawValue.length - 1]) ? separators[0] : null;
        }
        else {
            currentChar = trimmedValue[pointer];
        }
        if (currentChar !== null && separators.includes(currentChar)) {
            if (!quoteStartedWithChar) {
                var maybeTag = void 0;
                if (quotedTag) {
                    // cut quotes
                    maybeTag = trimmedValue.slice(startWindow + 1, pointer - 1);
                    quotedTag = false;
                }
                else {
                    maybeTag = trimmedValue.slice(startWindow, pointer);
                }
                if (maybeTag) {
                    foundTags.push(maybeTag);
                }
                startWindow = pointer + 1;
                newValue = trimmedValue.slice(startWindow);
            }
            else {
                // quote is not ended yet
            }
        }
        else if (currentChar === '"' || currentChar === "'") {
            if (!quoteStartedWithChar) {
                quoteStartedWithChar = currentChar;
            }
            else if (quoteStartedWithChar === currentChar) {
                quoteStartedWithChar = undefined;
                quotedTag = true;
            }
        }
        pointer += 1;
    }
    return [foundTags, newValue];
};
var TagsInputStore = InputStore.named('TagsInputStore')
    .props({
    items: types.array(types.string),
    inputType: 'search',
    label: 'Tags',
    description: 'Use space, comma or semicolon char to separate different items.',
    placeholder: 'Add new tag',
    onChangeHandlingDelay: 0,
    _passErrorToFormItem: false,
    _passDescriptionToFormItem: false,
})
    .volatile(function () { return ({
    inputParser: findTags,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTagsChangeHandler: function (items) { },
}); })
    .views(function (self) { return ({
    isDone: function () {
        return !self.value && (!self.required || self.items.length > 0);
    },
}); })
    .actions(function (self) { return ({
    setItems: function (items) {
        self.items = cast(items);
        self.onTagsChangeHandler(self.items);
    },
    removeItem: function (tag) {
        this.setItems(self.items.filter(function (t) { return t !== tag; }));
    },
    setInputParser: function (func) {
        self.inputParser = func;
    },
    registerOnTagsChangeHandler: function (func) {
        self.onTagsChangeHandler = func;
    },
    handleRawValueChange: function (value, enterIsPressed) {
        var _a = self.inputParser(value, enterIsPressed), tagsFromValue = _a[0], newInputValue = _a[1];
        self.setValue(newInputValue);
        var tagsToAdd = tagsFromValue.filter(function (tag) { return self.items.indexOf(tag) < 0; });
        if (tagsToAdd.length > 0) {
            this.setItems(self.items.concat(tagsToAdd));
        }
        if (enterIsPressed && newInputValue) {
            self.setError('Invalid tag');
        }
    },
    handleOnFocusOut: function (e) {
        this.handleRawValueChange(self.inputRef && self.inputRef.current ? self.inputRef.current.value : '', true);
        self.focused = false;
        self.onFocusOut(e);
    },
}); })
    .actions(function (self) { return ({
    afterCreate: function () {
        self.onChange = function (value) { return self.handleRawValueChange(value, false); };
        self.onEnterPress = function (value) { return self.handleRawValueChange(value, true); };
    },
}); });
export default TagsInputStore;
