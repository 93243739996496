var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cast, getParentOfType, types } from 'mobx-state-tree';
import { UserStates } from 'Constants';
import Store from 'stores/Store';
var DeletedState = 'archived';
export var User = types
    .model('User', {
    email: types.string,
    first_name: '',
    last_name: '',
    lastLogin: '',
    state: types.string,
    role: types.string,
    processing: false,
})
    .views(function (self) { return ({
    get fullName() {
        return "".concat(self.first_name, " ").concat(self.last_name);
    },
    get isDeleted() {
        // this flag is used to disable sidebar
        return self.state === DeletedState;
    },
}); })
    .actions(function (self) { return ({
    stopProcessing: function () {
        self.processing = false;
    },
    setState: function (state) {
        self.state = state;
    },
    deleteFromParent: function () {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getParentOfType(self, Users).deleteFromStore(self.email);
    },
    toggleActiveState: function () {
        var _this = this;
        self.processing = true;
        var method;
        var newState;
        if (self.state === UserStates.ACTIVE) {
            method = Store.TransportLayer.put;
            newState = UserStates.SUSPENDED;
        }
        else {
            method = Store.TransportLayer.delete;
            newState = UserStates.ACTIVE;
        }
        method({
            url: "/m/api/v1/users/".concat(self.email, "/suspend"),
            onSuccess: function () {
                _this.setState(newState);
                _this.stopProcessing();
            },
            onFailure: this.stopProcessing,
        });
    },
    delete: function () {
        var _this = this;
        Store.TransportLayer.delete({
            url: "/m/api/v1/users/".concat(self.email),
            onSuccess: function () {
                _this.setState(DeletedState);
                _this.deleteFromParent();
            },
        });
    },
    resendInvitation: function () {
        Store.TransportLayer.put({
            url: "/m/api/v1/users/".concat(self.email, "/resend-invitation"),
        });
    },
}); });
var Users = types
    .model('Users', {
    items: types.optional(types.map(User), {}),
    loaded: false,
    loading: false,
})
    .actions(function (self) { return ({
    getLoadedUser: function (email) {
        return self.items.get(email);
    },
    getFromAPI: function (email, onFetchComplete) {
        Store.TransportLayer.get({
            url: "/m/api/v1/users/".concat(email),
            onSuccess: function (res, data) { return onFetchComplete(data.data); },
            onFailure: function () { return onFetchComplete(); },
        });
    },
    setLoaded: function () {
        self.loaded = true;
    },
    setLoading: function () {
        self.loading = false;
    },
    pushItems: function (data) {
        self.items = cast(Object.fromEntries(data.users.map(function (u) { return [u.email, User.create(u)]; })));
    },
    load: function () {
        var _this = this;
        self.loading = true;
        Store.TransportLayer.get({
            url: '/m/api/v1/users?detailed=true',
            onSuccess: function (response, response_data) {
                _this.pushItems(response_data.data);
                _this.setLoaded();
                _this.setLoading();
            },
        });
    },
    createNew: function (email, firstName, lastName, password, role, onSuccess, onFailure) {
        var _this = this;
        Store.TransportLayer.post({
            url: "/m/api/v1/users",
            body: {
                email: email,
                first_name: firstName,
                last_name: lastName,
                password: password,
                role: role,
            },
            onSuccess: function () {
                // reload users
                _this.load();
                if (onSuccess !== undefined) {
                    onSuccess();
                }
            },
            onFailure: onFailure,
        });
    },
    invite: function (email, firstName, lastName, role, onSuccess) {
        var _a;
        var data = (_a = {},
            _a[email] = {
                first_name: firstName,
                last_name: lastName,
                role: role,
            },
            _a);
        Store.TransportLayer.post({
            url: '/m/api/v1/users/invite',
            body: data,
            onSuccess: onSuccess,
        });
    },
    verifyInvite: function (token, onSuccess, onFailure) {
        Store.TransportLayer.get({
            url: '/m/api/v1/auth/tokens',
            authToken: token,
            onSuccess: onSuccess,
            onFailure: onFailure,
        });
    },
    deleteFromStore: function (email) {
        self.items.delete(email);
    },
    completeInvitation: function (token, userData, onSuccess, onFailure) {
        Store.TransportLayer.post({
            url: '/m/api/v1/users',
            authToken: token,
            body: userData,
            onSuccess: onSuccess,
            onFailure: onFailure,
        });
    },
    deleteUser: function (email) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            Store.TransportLayer.delete({
                url: "/m/api/v1/users/".concat(email),
                onSuccess: function () {
                    _this.deleteFromStore(email);
                    resolve(email);
                },
                onFailure: function (resp, errors) {
                    Store.Notifications.error(__spreadArray(['Failed to delete user.'], errors, true));
                    reject(errors);
                },
            });
        });
    },
}); })
    .views(function (self) { return ({
    list: function () {
        return Array.from(self.items.values());
    },
}); });
export default Users;
