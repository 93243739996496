import React from 'react';

import { inject, observer } from 'mobx-react';

import { SimpleTextViewer } from 'components/forms/TextEditor';

@inject('profile')
@inject('instance')
@observer
export default class extends React.Component {
  render() {
    return (
      <div className="form-box">
        <SimpleTextViewer label="Instance API token" value={this.props.instance.token} filename="instance-token.txt" />
        <SimpleTextViewer
          label="Management API token"
          value={this.props.profile.token}
          filename="management-token.txt"
        />
      </div>
    );
  }
}
