import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
// Example style, you can use another
import 'prismjs/themes/prism.css';
import ThirdPartyEditor from 'react-simple-code-editor';
import FormItem from 'components/forms/FormItem';
import TextEditorS from 'components/forms/TextEditorS';
import Icon from 'components/Icon';
export { Syntaxes, SyntaxOptions } from 'components/forms/TextEditorS';
var TextActions = observer(function (props) { return (React.createElement("span", null,
    React.createElement("div", { className: "btn-slider" },
        React.createElement("div", { className: "btn-slider-container" },
            React.createElement("button", { className: "btn-slider-container-item", type: "button" },
                React.createElement(Icon, { className: "slider-btn", title: "Copy/Download" })),
            React.createElement("button", { className: "btn-slider-container-item", type: "button", onClick: function () { return copy(props.store.value); } },
                React.createElement(Icon, { className: "copy-btn", title: "Copy to Clipboard" })),
            React.createElement("a", { download: props.store.filename, href: props.store.fileURL },
                React.createElement("button", { className: "btn-slider-container-item", type: "button" },
                    React.createElement(Icon, { className: "download-btn", title: "Download" }))))))); });
var TextEditor = observer(function (props) { return (React.createElement(FormItem, { label: props.store.label || undefined, description: props.store.description || undefined },
    React.createElement("div", { className: "item-container" },
        React.createElement("div", { className: props.store.containerClassName },
            React.createElement(ThirdPartyEditor
            // we want to trigger re-render on syntax change
            , { 
                // we want to trigger re-render on syntax change
                key: props.store.syntax, value: props.store.value, onValueChange: props.store.setValue, highlight: props.store.processor, padding: 10, readOnly: props.store.readOnly, preClassName: props.store.preClassName }),
            !props.store.excludeActionsButtons && React.createElement(TextActions, { store: props.store }))))); });
export default TextEditor;
export var SimpleTextViewer = function (props) {
    var label = props.label, syntax = props.syntax, filename = props.filename, value = props.value, excludeActionsButtons = props.excludeActionsButtons;
    var store = useState(TextEditorS.create({
        label: label,
        syntax: syntax,
        filename: filename,
        value: value,
        readOnly: true,
        excludeActionsButtons: excludeActionsButtons || false,
    }))[0];
    useEffect(function () {
        store.setValue(value);
    }, [value]);
    useEffect(function () {
        if (label !== undefined) {
            store.setLabel(label);
        }
    }, [label]);
    return React.createElement(TextEditor, { store: store });
};
