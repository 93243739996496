/// ///////////////////////////////////////////////////////////////////////////
//                                                                           //
// Hint is a static window that appears as soon as user clicks the "trigger" //
// and disappears as soon as the "trigger" is clicked one more time or when  //
// special "Close" button that is placed automatically at the bottom of the  //
// window is clicked.                                                        //
//                                                                           //
// Usage:                                                                    //
//                                                                           //
//    <Hint>                                                                 //
//      <Hint.Trigger>                                                       //
//        <icon class="component-with-an-icon" />                            //
//      </Hint.Trigger>                                                      //
//      <Hint.Window                                                         //
//        title="An optional text to display at the top of window"           //
//      >                                                                    //
//        <span>Some text to display at the body</span>                      //
//        <Hint.Footer>                                                      //
//          The text to display at the bottom.                               //
//        </Hint.Footer>                                                     //
//      </Hint.Window>                                                       //
//                                                                           //
/// ///////////////////////////////////////////////////////////////////////////
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import ClickAwayListener from 'react-click-away-listener';

const PopoverTrigger = (props) => props.children;
const WindowBody = (props) => props.children;
const WindowFooter = (props) => <div className="footer">{props.children}</div>;

const WindowManager = (props) => {
  if (!props.triggered) {
    return null;
  }

  let footer;
  const body = React.Children.map(props.children, (child) => {
    if (child && child.type === WindowFooter) {
      if (footer === undefined) {
        footer = child;
      }
      return null;
    }
    return child;
  });

  return (
    <div className={props.className ? `hint ${props.className}` : 'hint'} onClick={(e) => e.stopPropagation()}>
      {props.title && <div className="header">{props.title}</div>}
      <div className="body">{body}</div>
      {footer}
      <WindowFooter>
        <button type="button" className="btn btn-default" onClick={props.onCloseButtonClick}>
          Close
        </button>
      </WindowFooter>
    </div>
  );
};

const Hint = (props) => {
  const [triggered, setTriggered] = useState(false);

  const children = React.Children.toArray(props.children);

  let trigger;
  let hint;

  if (children[0].type === PopoverTrigger) {
    trigger = children[0];
    hint = children[1];
  } else {
    trigger = children[1];
    hint = children[0];
  }

  const onClick = (e) => {
    setTriggered((prevState) => !prevState);
    if (e) {
      e.stopPropagation();
    }
  };

  return (
    <div className="hint-container" onClick={onClick}>
      {trigger}
      <ClickAwayListener onClickAway={() => setTriggered(false)}>
        <div>
          <WindowManager
            title={props.title}
            className={props.className}
            triggered={triggered}
            onCloseButtonClick={onClick}
          >
            {hint}
          </WindowManager>
        </div>
      </ClickAwayListener>
    </div>
  );
};

Hint.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

Hint.Trigger = PopoverTrigger;
Hint.Window = WindowBody;
Hint.WindowFooter = WindowFooter;

export default Hint;
