/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid unpinned">
        <div className="header">
          <div className="headerbar">
            <div className="headerbar-container">
              <a href="home.html" className="icon home" />
              <div className="global-search">
                <div className="dynamic-dropdown" style={{ display: 'none' }}>
                  <div className="form-box">
                    <div className="form-item">
                      <select>
                        <option selected>Select</option>
                        <option value="1">Item 1</option>
                        <option value="2">Item 2</option>
                        <option value="3">Item 3</option>
                        <option value="4">Item 4</option>
                        <option value="5">Item 5</option>
                        <option value="6">Item 6</option>
                        <option value="7">Item 7</option>
                        <option value="8">Item 8</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="dynamic-search">
                  <div className="form-box">
                    <div className="form-item search-button header-search">
                      <div className="item-container">
                        <input type="text" id="form-field" />
                        <div className="button-container">
                          <div className="button-container-item">
                            <button type="submit" className="search" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="autocomplete-container" style={{ display: 'none' }}>
                    <div className="autocomplete">
                      <form>
                        <div className="form-box">
                          <fieldset className="fieldset">
                            <div className="checkbox bounce">
                              <input type="checkbox" id="check-option-11" value="1" />
                              <svg viewBox="0 0 21 21">
                                <polyline points="5 10.75 8.5 14.25 16 6" />
                              </svg>
                              <label htmlFor="check-option-11">Hide Deleted Items</label>
                            </div>
                          </fieldset>
                        </div>
                      </form>
                      <form>
                        <div className="form-box-line">
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                All k8s Nodes
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                My Resources
                              </button>
                            </div>
                          </div>
                          <div className="form-item">
                            <div className="item-container">
                              <button type="button" className="btn btn-primary btn-tag">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <h4>Resent Search Query</h4>
                      <ul className="autocomplete-results">
                        <li>Boris</li>
                        <li>Boris Bullet Dodger</li>
                        <li>Boris Brejcha</li>
                        <li>Boris Pavlovich</li>
                        <li>Boris Johnson</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="icon helper">
                <div className="hint" style={{ display: 'none' }}>
                  <div className="header">Title</div>
                  <div className="body">
                    <b>Comparison Operators:</b>
                    <p> &gt;, &lt;, &gt;=, &lt;=, ==, != (or &gt;&gt;)</p>
                    <b>Special Operators:</b>
                    <p> %= (fuzzy search), ~= (regex match)</p>
                    <b>Array values operators:</b>
                    <p>IN and CONTAINS</p>
                    <b>Value Types</b>
                    <b>Strings:</b>
                    <p>'abcdef'</p>
                    <b>Integers:</b>
                    <p>1, 5, 1050</p>
                    <b>Floats:</b>
                    <p>1.5, 20.05, 0.33</p>
                    <b>Arrays:</b>
                    <p>[1, 2, 3, 10.5]</p>
                    <b>Functions-Values:</b>
                    <p>
                      id('some_value') returns arrays of @id(s) of records that match std::types/Inventory:1.identity ==
                      'some_value'
                    </p>
                    <b>Function Statements:</b>
                    <p>
                      inherits('some-model-name') filter results to return only records that inherit 'some-model-name'
                    </p>
                    <p>
                      isSet('some-attr-name')/isNotSet('some-attr-name') filter results to return only records that has
                      or hasn't 'some-attr-name' attribute
                    </p>
                    <b>Complex queries:</b>
                    <p>One can use AND and OR to connect multiple statements</p>
                    <b>Brackets:</b>
                    <p>( ) - first executed in brackets</p>
                  </div>
                  <div className="footer">
                    <button type="button" className="btn btn-default">
                      Close
                    </button>
                  </div>
                </div>
              </div>

              <a className="spacer" />

              <a href="#">Organization Name</a>
              <a href="models.html" className="icon models" title="Models" />
              <span className="divider" />
              <a href="#" className="icon notification badge" title="Notifications" style={{ display: 'none' }} />
              <a href="#" className="icon user" title="User Profile" />
              <a href="login.html" className="icon logout" title="Logout" />
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div className="brand-box">
            <div className="logo" />
            <div className="pin" />
          </div>
          <nav>
            <ul>
              <li>
                <a href="#" className="dashboard">
                  <span>Dashboard</span>
                </a>
              </li>
              <li className="active">
                <a href="#" className="hosts">
                  <span>Hosts</span>
                </a>
              </li>
              <li>
                <a href="#" className="containers">
                  <span>Containers</span>
                </a>
              </li>
              <li>
                <a href="#" className="scripts">
                  <span>Scripts</span>
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="apps.html" className="agents">
                  <span>Agents</span>
                </a>
              </li>
              <li>
                <a href="#" className="devs">
                  <span>Devs Only</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="content">
          <div className="page-headline">
            <div className="title">
              <span>
                <a href="/i/main/hosts">Hosts</a>
              </span>
              <span>613d567b-9917-4d70-963d-e41adbba1514</span>
            </div>
          </div>
          <div className="container-box">

          <div className="details-container">
            <div className="details-item-box description-box">
              <div>
                <span>Description:</span>
                Some long long text. or not. bla bla bla bla bla bla bla Some long long text. or not. bla bla bla bla bla bla bla Some long long text. or not. bla bla bla bla bla bla bla Some long long text. or not. bla bla bla bla bla bla bla Some long long text. or not. bla bla bla bla bla bla bla
              </div>
            </div>
            <div className="details-item-box">
              <div>
                <span>Status:</span>
                <b className="text-warning">development</b>
              </div>
              <div>
                <span>Inherits:</span>
                <a href="/catalog/models/std::types/Audit:1">std::types/Audit:1</a>
              </div>
            </div>
            <div className="details-item-box">
              <div>
                <div className="actions-box">
                  <icon type="button" className="icon edit"></icon>
                  <icon type="button" className="icon delete"></icon>
                </div>
              </div>
            </div>
            <div className="expander">
              <div className="caret"></div>
            </div>
          </div>

            <div className="tabs-container">
              <ul>
                <li>
                  <a href="">Overview</a>
                </li>
                <li className="active">
                  <a href="">Terminal</a>
                </li>
                <li>
                  <a href="">Graph</a>
                </li>
                <li>
                  <a href="">History</a>
                </li>
                <li>
                  <a href="">JSON</a>
                </li>
              </ul>
              <div className="addition">
                <a>4732f5eb-d889-480d-965c-9fd790c5b47f</a>
                <div className="icon copy" />
              </div>
            </div>

            <div className="title-container">
              <div className="title">
                <div>4732f5eb-d889-480d-965c-9fd790c5b47f</div>
              </div>
              <button type="button" className="btn btn-primary">
                Create New
              </button>
            </div>

            <div className="two-panels">

              <div className="left column">
                <div class="table table-no-hover">
                  <div class="title-container">
                    <div class="title">
                      <div>Overview</div>
                    </div>
                    <form className="form-box">
                        <div className="btn-group">
                          <div className="btn-group-item">
                            <input type="checkbox" className="check" name="active" id="active" checked />
                            <label className="btn" htmlFor="active">
                              For Human
                            </label>
                          </div>
                        </div>
                      </form>
                  </div>
                  <table>
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="1" colspan="5">
                          <div class="entity-type entity-icon" style={{ background: 'url(&quot;/p/api/v1/models/std::types/Inventory:1/picture.svg?token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImQ4MTA5YmI1LWU0YWUtNDZmMy05NjViLTUwMTEwZjA1ODRiNSIsInR5cCI6IkpXVCJ9.eyJ2IjoxLCJqdGkiOiJlMGMwZThlMiIsInN1YiI6ImJvZ2RhbkBpdGxvb2suY29tIiwiaWF0IjoxNjc5NjU4Nzg5LCJzY3AiOiJ1c2VyIiwiaXNzIjoiY29ydGV4IiwiZXhwIjoxNjc5NzQ1MTg5LCJvcmdfaWQiOiJhOTU4NzcwNCJ9.GSm8Oyk5zBTTgZcyTOzVC_IZuItPMv9W6V28pyfALj-e5jlupn9nQnwh9Go3qtlSNj4J83LBv8eHxkoABn2kVceXKkMN-szXSEFodIFOE7viNiDUEW4Nec3tYAY9Td-EaRWaAZ6syER8FYba92DrKfD7hOvcetazlGUhbR0iPRzS1ULLJ1wMi1nZhRq0SXQd8Gu2xENedEdUgrn9OuaK7c8_cb_QZAuloV-sRxa_48RITwb-n9Ejkt0UHccD_dyDajxW3JNEs-AVv-x2yuEfegIpFAx0IuYkPIXD4F4BsaplikUm1oOJMCQHpNotsHiEl2e1L05vyT_mG_oI-2nB6g&quot;)' }} />
                          <b>std::types/Inventory:1</b>
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="1"></td>
                        <td rowspan="1"></td>
                        <td rowspan="1">description</td>
                        <td rowspan="1">CI node for our github related jobs</td>
                        <td rowspan="1">
                          <icon is="icon" class="icon search-item"></icon>
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="1"></td>
                        <td rowspan="1"></td>
                        <td rowspan="1">displayName</td>
                        <td rowspan="1">ci-1</td>
                        <td rowspan="1">
                          <icon is="icon" class="icon search-item"></icon>
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="1"></td>
                        <td rowspan="1"></td>
                        <td rowspan="1">identity</td>
                        <td rowspan="1">
                          <ul class="btn-list">
                            <li>
                              <a query="std::types/Inventory:1.identity=='192.168.0.31'" title="192.168.0.31" class="btn btn-text btn-default btn-small" href="/i/main/inventory?page=1&amp;q=std%3A%3Atypes%2FInventory%3A1.identity%3D%3D%27192.168.0.31%27">192.168.0.31</a>
                            </li>
                            <li>
                              <a query="std::types/Inventory:1.identity=='CI node for our github related jobs'" title="CI node for our github related jobs" class="btn btn-text btn-default btn-small" href="/i/main/inventory?page=1&amp;q=std%3A%3Atypes%2FInventory%3A1.identity%3D%3D%27CI%20node%20for%20our%20github%20related%20jobs%27">CI node for our github related jobs</a>
                            </li>
                            <li>
                              <a query="std::types/Inventory:1.identity=='ci-1'" title="ci-1" class="btn btn-text btn-default btn-small" href="/i/main/inventory?page=1&amp;q=std%3A%3Atypes%2FInventory%3A1.identity%3D%3D%27ci-1%27">ci-1</a>
                            </li>
                          </ul>
                          </td>
                          <td rowspan="1"></td>
                        </tr>
                        <tr>
                          <td rowspan="1"></td>
                          <td rowspan="1"></td>
                          <td rowspan="1">status</td>
                          <td rowspan="1">ok</td>
                          <td rowspan="1">
                            <icon is="icon" class="icon search-item"></icon>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1"></td>
                          <td rowspan="1"></td>
                          <td rowspan="1">statusDescription</td>
                          <td rowspan="1"></td>
                          <td rowspan="1">
                            <icon is="icon" class="icon search-item"></icon>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1" colspan="5">
                          <div class="entity-type entity-icon" style={{ background: 'url(&quot;/p/api/v1/models/std::types/Inventory:1/picture.svg?token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImQ4MTA5YmI1LWU0YWUtNDZmMy05NjViLTUwMTEwZjA1ODRiNSIsInR5cCI6IkpXVCJ9.eyJ2IjoxLCJqdGkiOiJlMGMwZThlMiIsInN1YiI6ImJvZ2RhbkBpdGxvb2suY29tIiwiaWF0IjoxNjc5NjU4Nzg5LCJzY3AiOiJ1c2VyIiwiaXNzIjoiY29ydGV4IiwiZXhwIjoxNjc5NzQ1MTg5LCJvcmdfaWQiOiJhOTU4NzcwNCJ9.GSm8Oyk5zBTTgZcyTOzVC_IZuItPMv9W6V28pyfALj-e5jlupn9nQnwh9Go3qtlSNj4J83LBv8eHxkoABn2kVceXKkMN-szXSEFodIFOE7viNiDUEW4Nec3tYAY9Td-EaRWaAZ6syER8FYba92DrKfD7hOvcetazlGUhbR0iPRzS1ULLJ1wMi1nZhRq0SXQd8Gu2xENedEdUgrn9OuaK7c8_cb_QZAuloV-sRxa_48RITwb-n9Ejkt0UHccD_dyDajxW3JNEs-AVv-x2yuEfegIpFAx0IuYkPIXD4F4BsaplikUm1oOJMCQHpNotsHiEl2e1L05vyT_mG_oI-2nB6g&quot;)' }} />
                            <b>std::types/Root:1</b>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1"></td>
                          <td rowspan="1"></td>
                          <td rowspan="1">app</td>
                          <td rowspan="1">
                            <a title="Fremont Hosts" class="btn btn-text btn-primary btn-small" href="/i/main/inventory/053e507a-fbd1-4fa2-ae75-22939f950915">Fremont Hosts</a>
                          </td>
                          <td rowspan="1"></td>
                        </tr>
                        <tr>
                          <td rowspan="1"></td>
                          <td rowspan="1"></td>
                          <td rowspan="1">id</td>
                          <td rowspan="1">613d567b-9917-4d70-963d-e41adbba1514</td>
                          <td rowspan="1">
                            <icon is="icon" class="icon search-item"></icon>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1" colspan="5">
                          <div class="entity-type entity-icon" style={{ background: 'url(&quot;/p/api/v1/models/std::types/Inventory:1/picture.svg?token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImQ4MTA5YmI1LWU0YWUtNDZmMy05NjViLTUwMTEwZjA1ODRiNSIsInR5cCI6IkpXVCJ9.eyJ2IjoxLCJqdGkiOiJlMGMwZThlMiIsInN1YiI6ImJvZ2RhbkBpdGxvb2suY29tIiwiaWF0IjoxNjc5NjU4Nzg5LCJzY3AiOiJ1c2VyIiwiaXNzIjoiY29ydGV4IiwiZXhwIjoxNjc5NzQ1MTg5LCJvcmdfaWQiOiJhOTU4NzcwNCJ9.GSm8Oyk5zBTTgZcyTOzVC_IZuItPMv9W6V28pyfALj-e5jlupn9nQnwh9Go3qtlSNj4J83LBv8eHxkoABn2kVceXKkMN-szXSEFodIFOE7viNiDUEW4Nec3tYAY9Td-EaRWaAZ6syER8FYba92DrKfD7hOvcetazlGUhbR0iPRzS1ULLJ1wMi1nZhRq0SXQd8Gu2xENedEdUgrn9OuaK7c8_cb_QZAuloV-sRxa_48RITwb-n9Ejkt0UHccD_dyDajxW3JNEs-AVv-x2yuEfegIpFAx0IuYkPIXD4F4BsaplikUm1oOJMCQHpNotsHiEl2e1L05vyT_mG_oI-2nB6g&quot;)' }} />
                            <b>std::types/Versionable:1</b>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="1"></td>
                          <td rowspan="1"></td>
                          <td rowspan="1">createdAt</td>
                          <td rowspan="1">2023-03-24 05:16:38</td>
                          <td rowspan="1">
                            <icon is="icon" class="icon search-item"></icon>
                          </td>
                          </tr>
                          <tr>
                            <td rowspan="1"></td>
                            <td rowspan="1"></td>
                            <td rowspan="1">updatedAt</td>
                            <td rowspan="1">2023-03-24 05:16:38</td>
                            <td rowspan="1">
                              <icon is="icon" class="icon search-item"></icon>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="1"></td>
                            <td rowspan="1"></td>
                            <td rowspan="1">version</td>
                            <td rowspan="1">1</td>
                            <td rowspan="1">
                              <icon is="icon" class="icon search-item"></icon>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
              </div>

              <div className="right column">
                
                <div className="session-history-container">
                  <div class="title-container">
                    <div class="title">
                      <div>Session History</div>
                    </div>
                    <form>
                      <div className="form-box">
                        <div className="form-item">
                          <div className="item-container">
                            <input
                              type="date"
                              name="history"
                              autoComplete="on"
                              value="today"
                              min="2023-01-01"
                              max="2050-12-31"
                            />
                            <label htmlFor="start">Select Date</label>
                          </div>
                          <span class="help-text"></span>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="sortup">Session Start Time</div>
                          </th>
                          <th>
                            <div className="sortup">Duration</div>
                          </th>
                          <th>
                            <div>User</div>
                          </th>
                          <th>
                            <div>Traffic In</div>
                          </th>
                          <th>
                            <div>Traffic Out</div>
                          </th>
                          <th>
                            <div className="sortdown">Files Upload</div>
                          </th>
                          <th>
                            <div>Files Download</div>
                          </th>
                          <th>
                            <div>Description</div>
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>13:04:25 11 Jan 2023</td>
                          <td>1:23:15</td>
                          <td>
                            <a title="BD" className="btn btn-text btn-primary btn-avatar" href="#">
                              BD
                            </a>
                            <span>Benedict Dumberbatch</span>
                          </td>
                          <td>1023 B</td>
                          <td>23 B</td>
                          <td>—</td>
                          <td>
                            <a href="#">1</a>
                          </td>
                          <td>Sample Text</td>
                          <td>
                            <div className="history-session-actions">
                              <a href="#" className="icon history-log" />
                              <a href="#" className="icon history-record" />
                              <a href="#" className="icon delete" />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>13:04:25 11 Jan 2023</td>
                          <td>1:23:15</td>
                          <td>
                            <a title="BD" className="btn btn-text btn-primary btn-avatar" href="#">
                              WW
                            </a>
                            <span>Willy Wonka</span>
                          </td>
                          <td>1023 B</td>
                          <td>23 B</td>
                          <td>
                            <a href="#">88</a>
                            <div className="hint" style={{ display: 'none' }}>
                              <div className="body">
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>worm.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>user/local/libriary/directory/hosts/blablabla.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                                <div>worm.src</div>
                                <div>Ololo.src</div>
                                <div>Ololo.src</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a href="#">1</a>
                          </td>
                          <td>Sample Text</td>
                          <td>
                            <div className="history-session-actions">
                              <a href="#" className="icon history-log" />
                              <a href="#" className="icon history-record" />
                              <a href="#" className="icon delete" />
                            </div>
                          </td>
                        </tr>
                        <tr className="expanded">
                          <td>13:04:25 11 Jan 2023</td>
                          <td>1:23:15</td>
                          <td>
                            <a title="BD" className="btn btn-text btn-primary btn-avatar" href="#">
                              BD
                            </a>
                            <span>Benedict Dumberbatch</span>
                          </td>
                          <td>1023 B</td>
                          <td>23 B</td>
                          <td>—</td>
                          <td>
                            <a href="#">1</a>
                          </td>
                          <td>Just a Sample Text</td>
                          <td>
                            <div className="history-session-actions">
                              <a href="#" className="icon history-log-active" />
                              <a href="#" className="icon history-record-active" />
                              <a href="#" className="icon delete" />
                            </div>
                          </td>
                        </tr>
                        <tr className="history-item-details">
                          <td colSpan="9">
                            <div className="history-expanded-container">
                              <div className="message-container">
                                <p>Session Recorded January 11, 2023 18:44:31</p>
                                <button type="button" className="btn btn-primary">
                                  Play
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>13:04:25 11 Jan 2023</td>
                          <td>1:23:15</td>
                          <td>
                            <a title="BD" className="btn btn-text btn-primary btn-avatar" href="#">
                              BD
                            </a>
                            <span>Benedict Dumberbatch</span>
                          </td>
                          <td>1023 B</td>
                          <td>23 B</td>
                          <td>—</td>
                          <td>
                            <a href="#">1</a>
                          </td>
                          <td>Sample Text</td>
                          <td>
                            <div className="history-session-actions">
                              <a href="#" className="icon history-log" />
                              <a href="#" className="icon history-record" />
                              <a href="#" className="icon delete" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>

                <div className="graphContainer">
                  <div class="title-container">
                    <div class="title">
                      <div>Graph</div>
                    </div>
                  </div>
                  <div>bla bla bla place here</div>
                </div>
                
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
