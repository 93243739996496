/* eslint-disable */

import React from 'react';

export class Page extends React.Component {
  render() {
    return (
      <div className="grid unpinned">
        <div className="header">
          <div className="headerbar">
            <div className="headerbar-container">
              <a href="home.html" className="icon home" />
              <a href="#" className="warning">
                Instance warning
              </a>
              <a href="#" className="danger">
                Instance danger
              </a>
              <a href="#" className="success">
                Instance success
              </a>
              <a href="#" className="success active">
                Active instance success
              </a>
              <a href="#" className="offline">
                Instance offline
              </a>
              <a href="#" className="button">
                +
              </a>
              <a className="spacer" />
              <a href="#" className="active">
                Organization Name
              </a>
              <a href="models.html" className="icon models active" title="Models" />
              <span className="divider" />
              <a href="#" className="icon notification badge" title="Notifications" />
              <a href="#" className="icon user active" title="User Profile" />
              <a href="login.html" className="icon logout" title="Logout" />
            </div>
          </div>
        </div>

        <div className="sidebar">
          <div className="brand-box">
            <div className="logo" />
            <div className="pin" />
          </div>
          <nav>
            <ul>
              <li>
                <a href="#" className="dashboard">
                  <span>Dashboard</span>
                </a>
              </li>
              <li className="active">
                <a href="#" className="hosts">
                  <span>Hosts</span>
                </a>
              </li>
              <li>
                <a href="#" className="containers">
                  <span>Containers</span>
                </a>
              </li>
              <li>
                <a href="#" className="scripts">
                  <span>Scripts</span>
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="apps.html" className="agents">
                  <span>Agents</span>
                </a>
              </li>
              <li>
                <a href="#" className="devs">
                  <span>Devs Only</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="content">

          <div className="container-box">
            <div className="page-headline">
              <div className="title">
                <span>Organization Name</span>
              </div>
            </div>
            <div className="tabs-container">
              <ul>
                <li>
                  <a href="">Details</a>
                </li>
                <li>
                  <a href="">Billing</a>
                </li>
                <li>
                  <a href="">Users</a>
                </li>
                <li className="active">
                  <a href="">Settings</a>
                </li>
                <li>
                  <a href="">Instances</a>
                </li>
              </ul>
            </div>

            <h4>Access</h4>
            
            <form>
              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <div className="name-container">
                      <div className="name">Authentication via OAuth2</div>
                    </div>
                    <div className="btn-group switcher">
                      <div className="btn-group-item">
                        <input type="checkbox" className="check" name="active1" id="active1" />
                        <label className="btn" htmlFor="active1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <div className="name-container">
                      <div className="name">OAuth2 Only auth</div>
                      <div className="help-text">Restrict authentication via email & password flow.</div>
                    </div>
                    <div className="btn-group switcher">
                      <div className="btn-group-item">
                        <input type="checkbox" className="check" name="active2" id="active2" />
                        <label className="btn" htmlFor="active2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <div className="name-container">
                      <div className="name">OAuth2 wildcard domains</div>
                      <div className="help-text">Allow to self-create user account for users with email addresses from the specified domain(s).</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <input type="text" id="form-field" placeholder="Domain" />
                    <label>Domain</label>
                    <button type="button" className="btn-only-icon minus" />
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <input type="text" id="form-field" placeholder="Domain" />
                    <label>Domain</label>
                    <button type="button" className="btn-only-icon plus" />
                    <button type="button" className="btn-only-icon minus" />
                  </div>
                </div>
              </div>

              <div className="form-box">
                <div className="form-item">
                  <div className="item-container">
                    <div className="name-container">
                      <div className="name">Assign Admin role to new OAuth2 user.</div>
                      <div className="help-text">Whether to assign admin to an unregistered user that logged in via OAuth2 wildcard domain option.</div>
                    </div>
                    <div className="btn-group switcher">
                      <div className="btn-group-item">
                        <input type="checkbox" className="check" name="active3" id="active3" />
                        <label className="btn" htmlFor="active3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>

          </div>

          <div className="page-headline">
            <div className="title">
              <span>Organization Name</span>
            </div>
          </div>
          <div className="container-box">
            <div className="tabs-container">
              <ul>
                <li>
                  <a href="">Details</a>
                </li>
                <li>
                  <a href="">Billing</a>
                </li>
                <li>
                  <a href="">Users</a>
                </li>
                <li>
                  <a href="">Settings</a>
                </li>
                <li className="active">
                  <a href="">Instances</a>
                </li>
              </ul>
            </div>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>Name</div>
                    </th>
                    <th>
                      <div className="sortdown">ID</div>
                    </th>
                    <th>
                      <div>Region</div>
                    </th>
                    <th>
                      <div className="sortdown">Zone</div>
                    </th>
                    <th>
                      <div>Requested by</div>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="entity-status bg-success" title="Running" />
                      Instance Name 01
                    </td>
                    <td>d9f19ce0-40ac-4a8d-a2cc-56afd898846c</td>
                    <td>Ukraine</td>
                    <td>Kharkiv</td>
                    <td>
                      <a href="#">andrey@itlook.com</a>
                    </td>
                    <td>
                      <a href="#" className="icon delete" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="entity-status bg-warning" title="Running" />
                      Instance Name 02
                    </td>
                    <td>d9f19ce0-40ac-4a8d-a2cc-56afd898846c</td>
                    <td>Ukraine</td>
                    <td>Kharkiv</td>
                    <td>
                      <a href="#">andrey@itlook.com</a>
                    </td>
                    <td>
                      <a href="#" className="icon delete" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="entity-status" title="Running" />
                      Instance Name 03
                    </td>
                    <td>d9f19ce0-40ac-4a8d-a2cc-56afd898846c</td>
                    <td>Ukraine</td>
                    <td>Kharkiv</td>
                    <td>
                      <a href="#">andrey@itlook.com</a>
                    </td>
                    <td>
                      <a href="#" className="icon delete" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="entity-status bg-danger" title="Running" />
                      Instance Name 03
                    </td>
                    <td>d9f19ce0-40ac-4a8d-a2cc-56afd898846c</td>
                    <td>Ukraine</td>
                    <td>Kharkiv</td>
                    <td>
                      <a href="#">andrey@itlook.com</a>
                    </td>
                    <td>
                      <a href="#" className="icon delete" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="modal" style={{ display: 'none' }}>
              <div className="modal-box">
                <div className="modal-header">
                  <item>Are your sure?</item>
                  <item className="btn-close" />
                </div>
                <div className="modal-body">
                  <p>This operation is irreversible. Are you sure you want to continue?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn-default">
                    Cancel
                  </button>
                  <button type="button" className="btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="page-headline">
            <div className="title">
              <span>
                <a href="">Organization Name</a>
              </span>
              <span>
                <a href="#">Users</a>
              </span>
              <span>Invite</span>
            </div>
          </div>

          <div className="container-box">
            <form className="form-box">
              <div className="form-item">
                <label>Email</label>
                <input type="email" className="form" />
                <div className="description">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempora eos odit quos ipsa minima officiis
                  eligendi animi, maxime error? Incidunt hic soluta libero nam praesentium adipisci quas eveniet est
                  itaque?
                </div>
              </div>
              <div className="form-item">
                <label>
                  First Name <b />
                </label>
                <input type="email" className="form" />
              </div>
              <div className="form-item">
                <label>Last Name</label>
                <input type="email" className="form" />
              </div>
              <div className="form-item">
                <label>Role</label>
                <div className="btn-group">
                  <input type="checkbox" className="check" id="check1" />
                  <label className="btn" htmlFor="check1">
                    User
                  </label>
                  <input type="checkbox" className="check" id="check2" />
                  <label className="btn" htmlFor="check2">
                    Admin
                  </label>
                </div>
              </div>
              <div className="message-placeholder text-danger">Login failed. Please, try again.</div>
              <div className="form-item">
                <button type="button" className="btn btn-default">
                  Cancel
                </button>
                <button type="button" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="rightbar">
          <div className="rightbar-container">
            <div className="rightbar-item header">
              <div
                className="avatar"
                style={{ background: 'url(https://deadline.com/wp-content/uploads/2021/05/AP_19303730792241.jpeg)' }}
              />
              <div className="name">
                <div>Arnold Schwarzenegger</div>
                <div>admin</div>
              </div>
            </div>
            <div className="rightbar-item">
              <div className="title">Current plan</div>
              <div className="plan">
                <a href="#">ENTERPRISE</a>
              </div>
            </div>
            <div className="rightbar-item">
              <div className="title">Change password</div>
              <form className="form-box">
                <div className="form-item">
                  <label>Current Password</label>
                  <input className="form" type="password" />
                </div>
                <div className="form-item">
                  <label>New Password</label>
                  <input className="form" type="password" />
                </div>
                <div className="form-item">
                  <label>Confirm Password</label>
                  <input className="form" type="password" />
                </div>
                <div className="form-item">
                  <button type="button" className="btn-primary">
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Page;
